/**
 * @flow
 *
 * @format
 */

export const LANDING = '/';

export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const FORGOT_PASSWORD = '/forgot-pass';

export const HOME = '/home';
/*
 * Editor routes
 */

export const EDITOR_DASHBOARD = '/editor/dashboard';

export const SCENARIO_DASHBOARD = '/editor/scenario';

export const SCENARIO_NEW = '/editor/scenario/new';
export const SCENARIO_NEW_ONBOARDING_POSITION = '/editor/scenario/new/position';
export const SCENARIO_NEW_ONBOARDING_NAME = '/editor/scenario/new/name';
export const SCENARIO_NEW_ONBOARDING_DEPARTURE = '/editor/scenario/new/departure';
export const SCENARIO_NEW_ONBOARDING_PITCH = '/editor/scenario/new/pitch';

export const SCENARIO_EDITION_ALL = '/editor/scenario/:scenarioId';
export const SCENARIO_EDITION = '/editor/scenario/:scenarioId/edit';
export const SCENARIO_HEADER = '/editor/scenario/:scenarioId/header';
export const SCENARIO_NPCS = '/editor/scenario/:scenarioId/npcs';

export const SCENARIO_DISCUSSION_EDITION = '/editor/scenario/:scenarioId/discussion/:discussionId';
export const SCENARIO_EXPORTS = '/editor/scenario/:scenarioId/export';
export const SCENARIO_TRADS = '/editor/scenario/:scenarioId/translations';

export const AMS_EDITION = '/editor/ams/edit';
export const AMS_TRADS = '/editor/ams/translations';
export const AMS_EXPORTS = '/editor/ams/export';

export const CITIES_EDITION = '/editor/cities/edit';
export const CITIES_EXPORTS = '/editor/cities/export';

export const SETTINGS = '/editor/settings';
export const RELEASES = '/editor/releases';

export const ADMIN = '/editor/admin';
export const REVIEW = '/editor/review';
export const PROFILE = '/editor/profile';
export const STATS = '/editor/statistics';
