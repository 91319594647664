/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import { HeaderServiceHelper } from 'src/store/scenario/header';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { PreferencesServiceHelper } from 'src/store/preferences';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import { ScenarioServiceHelper } from 'src/store/scenario';
import type { ItemsReducerState } from 'src/store/scenario/items';
import LocalizedFile from 'src/data/LocalizedFile';
import Loader from 'src/pages/components/loader';
import { POIItem, City, Scenario, ItemTypes, BaseItem, missionTypes, type MissionType } from 'src/data';
import { ConfigurationServiceHelper } from 'src/store/configuration';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';
import $ from 'jquery';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  InputString,
  InputBoolean,
  InputSteps,
  RoomMembersView,
  MapEditionWidget,
  InputMultipleSelect,
  InputSelect,
  InputNumber,
  InputJSONArray,
} from 'src/pages/components';
import InputLocalizedFile from 'src/pages/components/inputs/InputLocalizedFile';
// import InputLocalizedFiles from 'src/pages/components/inputs/InputLocalizedFiles';
import { allTags, gameModes, GameModesType, TagsType } from 'src/data/Scenario';
import * as ROUTES from '../../../constants/routes';
import { withCities } from '../../cities/WithCities';
import { SwitchMissionType } from './components';

export type ScenarioHeaderEditionScreenProps = {
  updateHeader: HeaderServiceHelper.updateHeaderType,
  updatePrefPosition: PreferencesServiceHelper.setLastCoordinateType,
  updateEditionPreferences: ConfigurationServiceHelper.loadServerConfigType,
  poiStart: POIItem,
  updateItem: ItemsServiceHelper.updateItemType,
  deleteScenario: ScenarioServiceHelper.deleteScenarioType,
  useDisplayOrder: boolean,
  cities: City[],
  cityIds: string[],
  locale: string,
  scenario: Scenario,
  firebase: Firebase,
  items: ItemsReducerState,
  roomMembers: any[],
  isConfirmedEditor: boolean,
  isAdmin: boolean,
  startItemId: string,
  validClaims: string[],
  history: any,
  t: (key: string) => string,
};

type State = {
  isValid?: boolean,
  isSchool: boolean,
  durationAvg: number,
  distanceAvg: number,
  difficulty: number,
  cityKnowledge: boolean,
  hasChanges: boolean,
  isLoading?: boolean,
  filesToRemove: any[],
  isInit?: boolean,
  subtitle: string,
  pitch: string,
  missionText: string,
  startItemId: string,
  startPointDescr: string,
  scenarioEndLinkUrl: string,
  scenarioEndLinkLabel: string,
  accessWheelchair: boolean,
  needCar: boolean,
  ageMin: number,
  cover?: LocalizedFile,
  isOfficial: boolean,
  missionType: MissionType,
  hasLightCover: boolean,
  playModeRealCoordinate: boolean,
  playModeStreetView: boolean,
  playModeQRCode: boolean,
  gameArea?: any,
  poiStartPos?: any,
  managedLocales: string,
  id: string,
  cityId: string,
  name: string,
  mapSelectedItem?: string,
  displayOrder: number,
  disableLucas: boolean,
  gameMode: GameModesType,
  madeOnGayaWeb: boolean,
  tags: Array<TagsType>,
};

class ScenarioHeaderEditionScreen extends React.PureComponent<ScenarioHeaderEditionScreenProps, State> {
  static defaultProps = {};

  reader = undefined;

  state = {
    isValid: false,
    isSchool: false,
    durationAvg: 15,
    distanceAvg: 0,
    difficulty: 1,
    cityKnowledge: false,
    hasChanges: false,
    filesToRemove: [],
    isLoading: false,
    isInit: false,
    isOfficial: false,
    missionType: missionTypes.isPrivate,
    subtitle: '',
    pitch: '',
    missionText: '',
    startItemId: '',
    startPointDescr: '',
    scenarioEndLinkUrl: '',
    scenarioEndLinkLabel: '',
    accessWheelchair: true,
    needCar: false,
    ageMin: 0,
    gameArea: [],
    managedLocales: '',
    id: '',
    cityId: '',
    name: '',
    cover: undefined,
    partners: undefined,
    hasLightCover: false,
    playModeRealCoordinate: false,
    playModeStreetView: false,
    playModeQRCode: false,
    poiStartPos: undefined,
    mapSelectedItem: undefined,
    displayOrder: 0,
    showDeleteModal: false,
    disableLucas: false,
    gameMode: '',
    madeOnGayaWeb: true,
    tags: [],
  };

  componentDidMount() {
    this.setItemData(this.props);
    const { firebase, updateEditionPreferences } = this.props;
    updateEditionPreferences(firebase);
  }

  componentDidUpdate(oldProps: ScenarioHeaderEditionScreenProps) {
    if (
      !this.state.isInit &&
      (this.props.locale !== oldProps.locale || !oldProps.scenario || oldProps.scenario.id !== this.props.scenario.id)
    ) {
      this.setItemData(this.props);
    }

    if (
      this.props.validClaims.length &&
      !this.props.isConfirmedEditor &&
      this.props.cities.length &&
      this.state.cityId === ''
    ) {
      const city = this.props.cities.find((it) => it.id === 'default');
      if (city) {
        this.handleChange({ target: { value: 'default', id: 'cityId' } });
        // this.setState({ poiStartPos: [city.centerCoordinate.latitude, city.centerCoordinate.longitude] });
      }
    }
  }

  componentWillUnmount() {
    this.updateHeader(false);
  }

  setItemData = (props: ScenarioHeaderEditionScreenProps) => {
    const { locale, scenario } = props;
    const {
      id,
      cityId,
      isSchool,
      isOfficial,
      missionType,
      name,
      cover,
      hasLightCover,
      playModes,
      partners,
      startItemId,
      difficulty,
      durationAvg,
      distanceAvg,
      subtitle,
      pitch,
      missionText,
      startPointDescr,
      scenarioEndLinkUrl,
      scenarioEndLinkLabel,
      accessWheelchair,
      needCar,
      ageMin,
      cityKnowledge,
      gameArea,
      managedLocales,
      displayOrder,
      unlockCondition,
      disableLucas,
      gameMode,
      madeOnGayaWeb,
      tags,
    } = scenario;
    const newState: State = {
      id: id || '',
      cityId: cityId || 'default',
      name: name && name.valueForLocale(locale),
      cover,
      hasLightCover,
      playModeRealCoordinate: playModes?.realCoordinate || false,
      playModeStreetView: playModes?.streetView || false,
      playModeQRCode: playModes?.qrCode || false,
      partners,
      isSchool,
      isOfficial,
      missionType,
      mapSelectedItem: startItemId,
      startItemId: startItemId || '',
      difficulty: difficulty || 1,
      durationAvg: (durationAvg && durationAvg / 60) || 0,
      distanceAvg: (distanceAvg && distanceAvg / 1000) || 0,
      subtitle: subtitle && subtitle.valueForLocale(locale),
      pitch: pitch && pitch.valueForLocale(locale),
      missionText: missionText && missionText.valueForLocale(locale),
      startPointDescr: startPointDescr && startPointDescr.valueForLocale(locale),
      scenarioEndLinkUrl: scenarioEndLinkUrl && scenarioEndLinkUrl.valueForLocale(locale),
      scenarioEndLinkLabel: scenarioEndLinkLabel && scenarioEndLinkLabel.valueForLocale(locale),
      accessWheelchair,
      needCar,
      ageMin: ageMin || 0,
      cityKnowledge: cityKnowledge || false,
      gameArea,
      isInit: true,
      hasChanges: false,
      managedLocales: managedLocales ? JSON.stringify(managedLocales) : '["fr", "en"]',
      filesToRemove: [],
      displayOrder,
      unlockCondition,
      disableLucas: disableLucas || false,
      gameMode,
      madeOnGayaWeb,
      tags: tags.map((tag) => tag.label),
    };
    this.setState(newState);
    this.updateValidity(newState);
  };

  updateValidity(contentJson, loading) {
    this.setState({
      isValid: !loading && !!contentJson,
    });
  }

  handleChange = (event, forceFieldName: ?string) => {
    const { value } = event.target;
    const fieldName = forceFieldName || event.target.id;
    // $FlowFixMe Boolean is only used for bool fields
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
    if (fieldName === 'cityId') {
      const { cities, updatePrefPosition } = this.props;
      const newCity = cities.find((cit) => cit.id === value);
      if (newCity && updatePrefPosition) {
        updatePrefPosition(newCity.centerCoordinate.latitude, newCity.centerCoordinate.longitude, 12);
      }
    }
  };

  handleMissionType = (event, isOfficial: boolean) => {
    this.setState({ isOfficial, hasChanges: true });
    this.handleChange(event, 'missionType');
  };

  addFile = (fieldName) => () => {
    const field = [...this.state[fieldName]];
    const max = field.reduce((acc, cur) => (cur.index >= acc ? cur.index + 1 : acc), 0);
    field.push(new LocalizedFile(this.props.scenario.id, fieldName, { index: max }, true));
    this.setState({ [fieldName]: field, hasChanges: true });
  };

  removeFile = (fieldName, index) => {
    let field = [...this.state[fieldName]];
    const fileToRemove = field.find((it) => it.index === index);
    field = field.filter((it) => it.index !== index);
    this.setState({ [fieldName]: field, hasChanges: true });
    const itemFilesToRemove = fileToRemove.listStorageFiles();
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  updateHeader = async (sendNotif: boolean = true) => {
    const { locale, scenario, updateHeader, firebase, items } = this.props;
    if (this.state.hasChanges && scenario) {
      this.setState({ isLoading: true });
      const {
        id,
        cityId,
        isSchool,
        isOfficial,
        missionType,
        name,
        difficulty,
        durationAvg,
        distanceAvg,
        subtitle,
        pitch,
        cover,
        hasLightCover,
        playModeRealCoordinate,
        playModeStreetView,
        playModeQRCode,
        partners,
        missionText,
        startPointDescr,
        scenarioEndLinkUrl,
        scenarioEndLinkLabel,
        accessWheelchair,
        needCar,
        ageMin,
        cityKnowledge,
        gameArea,
        poiStartPos,
        managedLocales,
        displayOrder,
        unlockCondition,
        disableLucas,
        gameMode,
        madeOnGayaWeb,
        tags,
      } = this.state;
      scenario.id = id;
      scenario.cityId = cityId;
      scenario.name.setValueForLocale(name, locale);
      if (cover) {
        scenario.cover = cover;
      }
      scenario.hasLightCover = hasLightCover;
      scenario.playModes = {
        realCoordinate: playModeRealCoordinate || (!playModeStreetView && !playModeQRCode), // Force this is no mode selected
        streetView: playModeStreetView,
        qrCode: playModeQRCode,
      };
      scenario.partners = partners;
      scenario.isSchool = isSchool;
      scenario.isOfficial = isOfficial;
      scenario.missionType = missionType;
      scenario.difficulty = parseInt(difficulty, 10);
      scenario.durationAvg = Math.round(durationAvg) * 60;
      scenario.distanceAvg = Math.round(distanceAvg * 10) * 100;
      scenario.subtitle.setValueForLocale(subtitle, locale);
      scenario.pitch.setValueForLocale(pitch, locale);
      scenario.missionText.setValueForLocale(missionText, locale);
      if (!isSchool) {
        scenario.startPointDescr.setValueForLocale(startPointDescr, locale);
      } else {
        scenario.startPointDescr.flush();
      }
      scenario.scenarioEndLinkUrl.setValueForLocale(scenarioEndLinkUrl, locale);
      scenario.scenarioEndLinkLabel.setValueForLocale(scenarioEndLinkLabel, locale);
      scenario.accessWheelchair = accessWheelchair;
      scenario.needCar = needCar;
      scenario.ageMin = ageMin;
      scenario.cityKnowledge = cityKnowledge;
      scenario.gameArea = gameArea;
      scenario.managedLocales = JSON.parse(managedLocales);
      scenario.displayOrder = displayOrder;
      scenario.unlockCondition = unlockCondition;
      scenario.disableLucas = disableLucas;
      scenario.gameMode = gameMode;
      scenario.madeOnGayaWeb = madeOnGayaWeb;
      scenario.tags = tags
        .filter((tagId) => allTags.filter((tag) => tag.label === tagId).length)
        .map((tagId) => allTags.filter((tag) => tag.label === tagId)[0]);
      const { filesToRemove } = this.state;

      if (filesToRemove && filesToRemove.length) {
        await FirebaseHelper.removeEditorFilesAsync(scenario.id, filesToRemove, 'scenario', firebase);
      }

      if (updateHeader) {
        try {
          await updateHeader(scenario, firebase, items, sendNotif);
        } catch (error) {
          console.log('Could not update header', error);
        }
        this.setState({ hasChanges: false });
      }

      if (poiStartPos) {
        const { poiStart, updateItem, firebase } = this.props;
        poiStart.coordinate = {
          latitude: poiStartPos[0],
          longitude: poiStartPos[1],
        };
        if (updateItem) {
          await updateItem(scenario.id, poiStart.id, poiStart, firebase, sendNotif);
        }
      }
      this.setState({ isLoading: false });
    }
  };

  showDeleteModal = () => {
    $('#deleteScenarioModal').modal('toggle');
  };

  deleteScenario = async (e, scenarioId) => {
    const { firebase } = this.props;
    this.setState({ isLoading: true });
    this.showDeleteModal();
    const res = await this.props.deleteScenario(scenarioId, firebase);
    this.setState({ isLoading: false });
    if (res) {
      this.props.history.push(ROUTES.SCENARIO_DASHBOARD);
    }
  };

  polygonChanged = (id, polygon) => {
    const { gameArea } = this.state;
    if (polygon && polygon.length) {
      gameArea.area = polygon;
    }
    this.setState({ gameArea, mapSelectedItem: gameArea.id, hasChanges: true });
  };

  markerChanged = () => {};

  onMarkerDragged = () => {};

  itemSelected = (id) => {
    this.setState({ mapSelectedItem: id });
  };

  handleFileSelected = (fieldName: string, locale: string, file: File, index: ?number = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const newFile = new LocalizedFile(this.props.scenario.id, fieldName, oldFile, true);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    if (!newFile.hasLocale(locale)) {
      newFile.addLocale(locale);
    }
    newFile.files[locale].contentToUpload = file;
    newFile.files[locale].name = file.name;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  handleContentChange = (fieldName: string, value: string, index: ?number = undefined) => {
    const { locale } = this.props;
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const newFile = new LocalizedFile(this.props.scenario.id, fieldName, oldFile, true);
    newFile.content.values[locale] = value;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  addFileLocale = (fieldName: string, locale: string) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.scenario.id, fieldName, oldFile, true);
    newFile.addLocale(locale);
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  removeFileLocale = (fieldName: string, locale: string) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.scenario.id, fieldName, oldFile, true);
    const itemFilesToRemove = newFile.listStorageFiles(locale);
    newFile.removeLocale(locale);
    const newField = newFile;

    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  render() {
    const { /* cities, */ locale, items, t, /* isConfirmedEditor, */ isAdmin, useDisplayOrder, scenario } = this.props;
    const {
      isValid,
      id,
      name,
      cover,
      // hasLightCover,
      playModeRealCoordinate,
      playModeStreetView,
      playModeQRCode,
      // subtitle,
      pitch,
      // missionText,

      isSchool,
      // cityId,
      startItemId,
      gameArea,
      // startPointDescr,
      // scenarioEndLinkUrl,
      // scenarioEndLinkLabel,
      mapSelectedItem,
      missionType,
      displayOrder,
      unlockCondition,

      managedLocales,
      durationAvg,
      // distanceAvg,
      difficulty,
      // cityKnowledge,
      // ageMin,
      accessWheelchair,
      needCar,
      // partners,
      poiStartPos,
      hasChanges,
      isLoading,
      disableLucas,
      gameMode,
      madeOnGayaWeb,
      tags,
    } = this.state;

    const saveBtnClass = !hasChanges ? 'btn-outline-secondary' : 'btn-primary';
    return (
      <div className="pageContainer">
        <RoomMembersView roomMembers={this.props.roomMembers} />
        <div className="container-fluid fill component-controller" style={{ overflow: 'scroll' }}>
          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t('screens.headerEdition.identification.sectionTitle')}</h3>
            </div>
            <div className="card-body">
              <InputString
                fieldName="id"
                value={id}
                label={t('screens.headerEdition.identification.idLabel')}
                help={t('screens.headerEdition.identification.idHelp')}
                disabled={true}
              />
              {isAdmin && (
                <InputString
                  fieldName="teamId"
                  value={scenario.teamId}
                  label={t('screens.headerEdition.identification.teamIdLabel')}
                  help={t('screens.headerEdition.identification.teamIdHelp')}
                  disabled={true}
                />
              )}
              {isAdmin && (
                <div className="screenBlock">
                  <label className="font-weight-bold text-capitalize ooredoo">
                    {t('screens.headerEdition.identification.missionTypeLabel')}
                  </label>
                  <SwitchMissionType
                    nameSwitch={missionTypes.isOfficial}
                    missionType={missionType}
                    label={t('screens.headerEdition.identification.isOfficialLabel')}
                    handleChange={(event) => this.handleMissionType(event, true)}
                  />
                  <SwitchMissionType
                    nameSwitch={missionTypes.isCertifiedCreator}
                    missionType={missionType}
                    label={t('screens.headerEdition.identification.isCertifiedCreatorLabel')}
                    handleChange={(event) => this.handleMissionType(event, false)}
                  />
                  <SwitchMissionType
                    nameSwitch={missionTypes.isPrivate}
                    missionType={missionType}
                    label={t('screens.headerEdition.identification.isPrivateLabel')}
                    handleChange={(event) => this.handleMissionType(event, false)}
                  />
                </div>
              )}
              {isAdmin && (
                <InputString
                  fieldName="unlockCondition"
                  value={unlockCondition}
                  label={t('screens.headerEdition.identification.unlockConditionLabel')}
                  help={t('screens.headerEdition.identification.unlockConditionHelp')}
                  handleChange={this.handleChange}
                  multiline
                />
              )}
              {useDisplayOrder && (
                <InputNumber
                  fieldName="displayOrder"
                  value={displayOrder}
                  label={t('screens.headerEdition.geography.displayOrderLabel')}
                  help={t('screens.headerEdition.geography.displayOrderHelp')}
                  handleChange={this.handleChange}
                />
              )}
              <InputString
                fieldName="name"
                value={name}
                label={t('screens.headerEdition.identification.nameLabel')}
                help={t('screens.headerEdition.identification.nameHelp')}
                handleChange={this.handleChange}
              />
              <InputLocalizedFile
                fieldName="cover"
                value={cover}
                label={t('screens.headerEdition.identification.coverLabel')}
                handleChange={this.handleChange}
                handleFileSelected={this.handleFileSelected}
                handleContentChange={this.handleContentChange}
                addFileLocale={this.addFileLocale}
                removeFileLocale={this.removeFileLocale}
                contentLocale={locale}
                sizeWarnLimit={500000}
                sizeErrorLimit={5000000}
              />
              {/* <span className="margin-top-reducer" /> */}
              {/* <InputBoolean
                fieldName="hasLightCover"
                value={hasLightCover}
                label={t('screens.headerEdition.identification.hasLightCoverLabel')}
                help={t('screens.headerEdition.identification.hasLightCoverHelp')}
                handleChange={this.handleChange}
              /> */}
              {/* <InputString
                fieldName="subtitle"
                value={subtitle}
                label={t('screens.headerEdition.identification.subtitleLabel')}
                help={t('screens.headerEdition.identification.subtitleHelp')}
                handleChange={this.handleChange}
              /> */}
              <InputString
                fieldName="pitch"
                value={pitch}
                label={t('screens.headerEdition.identification.pitchLabel')}
                help={t('screens.headerEdition.identification.pitchHelp')}
                multiline={true}
                handleChange={this.handleChange}
              />
              {/* <InputString
                fieldName="missionText"
                value={missionText}
                label={t('screens.headerEdition.identification.missionTextLabel')}
                help={t('screens.headerEdition.identification.missionTextHelp')}
                multiline={true}
                handleChange={this.handleChange}
              /> */}
              <button
                className={`btn mb-3 ${saveBtnClass} float-right`}
                type="button"
                id="updateIdentity"
                onClick={this.updateHeader}
                disabled={!isValid || !hasChanges}
              >
                {t('general.save')}
              </button>
            </div>
          </div>

          {isAdmin && (
            <div className="card bg-light screenBlock mb-3">
              <div className="card-header">
                <h3>{t('screens.headerEdition.playModes.sectionTitle')}</h3>
              </div>
              <div className="card-body">
                <InputBoolean
                  fieldName="playModeRealCoordinate"
                  value={playModeRealCoordinate}
                  label={t('screens.headerEdition.playModes.realCoordinateLabel')}
                  help={t('screens.headerEdition.playModes.realCoordinateHelp')}
                  handleChange={this.handleChange}
                />
                <InputBoolean
                  fieldName="playModeStreetView"
                  value={playModeStreetView}
                  label={t('screens.headerEdition.playModes.streetViewLabel')}
                  help={t('screens.headerEdition.playModes.streetViewHelp')}
                  handleChange={this.handleChange}
                />
                <InputBoolean
                  fieldName="playModeQRCode"
                  value={playModeQRCode}
                  label={t('screens.headerEdition.playModes.qrCodeLabel')}
                  help={t('screens.headerEdition.playModes.qrCodeHelp')}
                  handleChange={this.handleChange}
                />
                <button
                  className={`btn mb-3 ${saveBtnClass} float-right`}
                  type="button"
                  id="updatePlayModes"
                  onClick={this.updateHeader}
                  disabled={!isValid || !hasChanges}
                >
                  {t('general.save')}
                </button>
              </div>
            </div>
          )}

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t('screens.headerEdition.geography.sectionTitle')}</h3>
            </div>
            <div className="card-body">
              <InputBoolean
                fieldName="isSchool"
                value={isSchool}
                label={t('screens.headerEdition.geography.isSchoolLabel')}
                help={t('screens.headerEdition.tags.isSchoolHelp')}
                handleChange={this.handleChange}
              />
              {!isSchool && (
                <div>
                  {/* <InputSelect
                    fieldName="cityId"
                    value={cityId}
                    values={cities}
                    itemToId={(it) => it.id}
                    itemToTitle={(it) => `${it.name.valueForLocale(locale)} (${it.id})`}
                    label={t('screens.headerEdition.geography.cityIdLabel')}
                    help={t('screens.headerEdition.geography.cityIdHelp')}
                    handleChange={this.handleChange}
                    disabled={!isAdmin}
                  /> */}
                  {/* <InputString
                    fieldName="startPointDescr"
                    value={startPointDescr}
                    label={t('screens.headerEdition.geography.startPointDescrLabel')}
                    help={t('screens.headerEdition.geography.startPointDescrHelp')}
                    multiline={true}
                    handleChange={this.handleChange}
                  /> */}
                  <MapEditionWidget
                    itemClicked={this.itemSelected}
                    canDrag={false}
                    canDrawPolygon
                    restrictedPoiEdition={[startItemId]}
                    currentItemId={mapSelectedItem}
                    restrictedPolygonEdition={gameArea && [gameArea.id]}
                    extraEditablePolygons={[gameArea]}
                    polygonChanged={this.polygonChanged}
                    itemsMap={
                      poiStartPos
                        ? {
                            [startItemId]: {
                              id: startItemId,
                              coordinate: { latitude: poiStartPos[0], longitude: poiStartPos[1], type: ItemTypes.POI },
                            },
                          }
                        : items
                    }
                    markerFilter={
                      !poiStartPos ? (it) => it instanceof BaseItem && it.type === ItemTypes.POI : (it) => !!it
                    }
                    polygonFilter={(it) => it instanceof BaseItem && it.type === ItemTypes.GameArea}
                    maxHeight={500}
                  />
                  <small id={'poiStartUpdateHelp'} className="form-text text-muted">
                    {t('screens.headerEdition.geography.poiStartUpdateHelp')}
                  </small>
                </div>
              )}
              <button
                className={`btn mb-3 ${saveBtnClass} float-right`}
                type="button"
                id="updateGeo"
                onClick={this.updateHeader}
                disabled={!isValid || !hasChanges}
              >
                {t('general.save')}
              </button>
            </div>
          </div>

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t('screens.headerEdition.tags.sectionTitle')}</h3>
            </div>
            <div className="card-body">
              <InputSelect
                fieldName="gameMode"
                value={gameMode}
                values={gameModes}
                itemToTitle={(it) => t(`screens.headerEdition.tags.gameMode.${it}`)}
                label={t('screens.headerEdition.tags.gameModeLabel')}
                handleChange={this.handleChange}
                disabled={!isAdmin}
              />
              <InputMultipleSelect
                fieldName="tags"
                multiple={true}
                value={tags}
                values={allTags.map((tag) => tag.label)}
                itemToTitle={(it) => t(`screens.headerEdition.tags.allTags.${it}`)}
                label={t('screens.headerEdition.tags.tagsLabel')}
                handleChange={this.handleChange}
                hidden={false}
                disabledSearch
                maxOptions={3}
                inline
              />
              <InputJSONArray
                className="mb-2"
                fieldName="managedLocales"
                value={managedLocales}
                label={t('screens.headerEdition.identification.managedLocalesLabel')}
                handleChange={this.handleChange}
                prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
              />
              <InputSteps
                fieldName="durationAvg"
                value={durationAvg}
                label={t('screens.headerEdition.tags.durationAvgLabel')}
                help={t('screens.headerEdition.tags.durationAvgHelp')}
                min={0}
                max={360}
                step={5}
                unit={t('screens.headerEdition.tags.minits')}
                handleChange={this.handleChange}
              />
              {/* <InputSteps
                fieldName="distanceAvg"
                value={distanceAvg}
                label={t('screens.headerEdition.tags.distanceAvgLabel')}
                help={t('screens.headerEdition.tags.distanceAvgHelp')}
                min={0}
                max={15}
                step={0.5}
                unit={t('screens.headerEdition.tags.kilometers')}
                handleChange={this.handleChange}
              /> */}
              <InputSteps
                fieldName="difficulty"
                value={difficulty}
                label={t('screens.headerEdition.tags.difficultyLabel')}
                help={t('screens.headerEdition.tags.difficultyHelp')}
                min={1}
                max={5}
                step={1}
                handleChange={this.handleChange}
                values={['facile', 'moyen', 'avancé', 'difficile', 'expert']}
              />
              {/* <InputSteps
                fieldName="ageMin"
                value={ageMin}
                label={t('screens.headerEdition.tags.ageMinLabel')}
                help={t('screens.headerEdition.tags.ageMinHelp')}
                min={0}
                max={18}
                step={1}
                handleChange={this.handleChange}
              />
              <InputBoolean
                fieldName="cityKnowledge"
                value={cityKnowledge}
                label={t('screens.headerEdition.tags.cityKnowledgeLabel')}
                help={t('screens.headerEdition.tags.cityKnowledgeHelp')}
                handleChange={this.handleChange}
              /> */}
              <InputBoolean
                fieldName="accessWheelchair"
                value={accessWheelchair}
                label={t('screens.headerEdition.tags.accessWheelchairLabel')}
                help={t('screens.headerEdition.tags.accessWheelchairHelp')}
                handleChange={this.handleChange}
              />
              <InputBoolean
                fieldName="needCar"
                value={needCar}
                label={t('screens.headerEdition.tags.needCarLabel')}
                help={t('screens.headerEdition.tags.needCarHelp')}
                handleChange={this.handleChange}
              />

              {/* {isConfirmedEditor && (
                <span className="partners-container">
                  <InputLocalizedFiles
                    fieldName="partners"
                    value={partners}
                    label={t('screens.headerEdition.identification.partnersLabel')}
                    handleChange={this.handleChange}
                    handleFileSelected={this.handleFileSelected}
                    handleContentChange={this.handleContentChange}
                    addFileLocale={this.addFileLocale}
                    removeFileLocale={this.removeFileLocale}
                    contentLocale={locale}
                    addFile={this.addFile('partners')}
                    removeFile={this.removeFile}
                    sizeWarnLimit={500000}
                    sizeErrorLimit={5000000}
                  />
                </span>
              )} */}

              {/* {isConfirmedEditor && (
                <InputString
                  fieldName="scenarioEndLinkUrl"
                  value={scenarioEndLinkUrl}
                  label={t('screens.headerEdition.identification.scenarioEndLinkUrlLabel')}
                  help={t('screens.headerEdition.identification.scenarioEndLinkUrlHelp')}
                  handleChange={this.handleChange}
                />
              )} */}

              {/* {isConfirmedEditor && (
                <InputString
                  fieldName="scenarioEndLinkLabel"
                  value={scenarioEndLinkLabel}
                  label={t('screens.headerEdition.identification.scenarioEndLinkLabelLabel')}
                  help={t('screens.headerEdition.identification.scenarioEndLinkLabelHelp')}
                  handleChange={this.handleChange}
                />
              )} */}

              {/* <div className="fresh-air" /> */}

              <button
                className={`btn mb-3 ${saveBtnClass} float-right`}
                type="button"
                id="updateTags"
                onClick={this.updateHeader}
                disabled={!isValid || !hasChanges}
              >
                {t('general.save')}
              </button>
            </div>
          </div>

          {isAdmin && (
            <div className="card bg-light screenBlock mb-3">
              <div className="card-header">
                <h3>{t('screens.headerEdition.other.sectionTitle')}</h3>
              </div>
              <div className="card-body">
                <InputBoolean
                  fieldName="disableLucas"
                  value={disableLucas}
                  label={t('screens.headerEdition.other.disableLucasLabel')}
                  help={t('screens.headerEdition.other.disableLucasHelp')}
                  handleChange={this.handleChange}
                />
                {isAdmin && (
                  <InputBoolean
                    fieldName="madeOnGayaWeb"
                    value={madeOnGayaWeb}
                    label={t('screens.headerEdition.identification.madeOnGayaWeb')}
                    help={t('screens.headerEdition.identification.madeOnGayaWebHelp')}
                    handleChange={this.handleChange}
                  />
                )}
                <button
                  className={`btn mb-3 ${saveBtnClass} float-right`}
                  type="button"
                  id="updateOther"
                  onClick={this.updateHeader}
                  disabled={!isValid || !hasChanges}
                >
                  {t('general.save')}
                </button>
              </div>
            </div>
          )}

          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t('screens.headerEdition.dangerZone.sectionTitle')}</h3>
            </div>
            <div className="card-body">
              <h6 className="text-uppercase font-weight-bold">
                {t('screens.headerEdition.dangerZone.deleteScenario')}
              </h6>
              <p className="text-danger font-weight-bold">{t('screens.headerEdition.dangerZone.warningMessage')}</p>
              <button
                className={`btn mb-3 btn-danger`}
                type="button"
                id="showDeleteScenarioModal"
                onClick={this.showDeleteModal}
                disabled={!isValid}
                data-target="#deleteScenarioModal"
              >
                {t('general.delete')}
              </button>
            </div>
          </div>

          <div className="fresh-air" />

          <div className="card bg-light screenBlock mb-3 hidden">
            <div className="card-header">
              <h3>{t('screens.headerEdition.startPoint.sectionTitle')}</h3>
            </div>
            <div className="card-body">
              {t('screens.headerEdition.startPoint.startItem')}: {startItemId}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}

        <div className="modal fade" id="deleteScenarioModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t('screens.headerEdition.dangerZone.deleteScenario')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="text-danger font-weight-bold">{t('screens.headerEdition.dangerZone.warningMessage')}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" disabled={isLoading}>
                  {t('general.close')}
                </button>
                <button
                  className={`btn btn-danger`}
                  type="button"
                  id="deleteScenario"
                  onClick={(e) => {
                    this.deleteScenario(e, this.state.id);
                  }}
                  disabled={isLoading}
                  data-target="#deleteScenarioModal"
                >
                  {t('general.delete')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  cityIds: state.configuration.availableCities.map((city) => city.id),
  cities: state.configuration.availableCities,
  scenario: state.scenario.header,
  items: state.scenario.items,
  locale: state.preferences.editionLocale,
  poiStart: state.scenario.items[state.scenario.header.startItemId],
  startItemId: state.scenario.header.startItemId,
  useDisplayOrder: state.configuration.scenarioOrderEnabled,
  isConfirmedEditor:
    ownProps.validClaims.includes(Claims.ConfirmedEditor) ||
    ownProps.validClaims.includes(Claims.Admin) ||
    ownProps.validClaims.includes(Claims.Moderator),
  isAdmin: ownProps.validClaims.includes(Claims.Admin),
});

const mapDispatchToProps = {
  updateHeader: HeaderServiceHelper.updateHeader,
  updatePrefPosition: PreferencesServiceHelper.setLastCoordinate,
  updateEditionPreferences: ConfigurationServiceHelper.loadServerConfig,
  updateItem: ItemsServiceHelper.updateItem,
  deleteScenario: ScenarioServiceHelper.deleteScenario,
};

export default compose(
  withCities,
  withUrlProperties,
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.Editor, Claims.ConfirmedEditor, Claims.Admin]),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation('default'),
)(ScenarioHeaderEditionScreen);
