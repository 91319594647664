/**
 * @flow
 *
 * @format
 */
import { ItemTypes, BaseItem } from 'src/data';
import { ItemNodeTypes } from '../ItemNodeModel';

const itemProgressColorator = (item: BaseItem) => {
  let color = '#494949';
  if (item && item.progression && Object.keys(item.progression).length) {
    color = '#5DA9E9';
  }
  return color;
};

const itemMetricsColorator = (item: BaseItem) => {
  let color = '#494949';
  if (item && item.metricEvents && item.metricEvents.length) {
    color = '#5DA9E9';
  }
  return color;
};

const itemCluesColorator = (item: BaseItem) => {
  let color = '#494949';
  if (item && item.clues && !item.clues.isEmpty()) {
    color = '#5DA9E9';
  }
  return color;
};

const itemReleaseErrorColorator = (
  item: BaseItem,
  releaseItemResult?: { isValid: boolean, alerts: { level: string, message: string, extra?: any }[] },
) => {
  let color = '#E8EDF3';
  if (releaseItemResult && releaseItemResult.isValid) {
    if (releaseItemResult.alerts.length) {
      color = '#5DA9E9';
    } else {
      color = '#AE68F7';
    }
  } else if (releaseItemResult) {
    color = 'red';
  }
  return color;
};

export const itemTypeColorator = (item: BaseItem) => {
  let color;
  const itemToUse = item || {};
  switch (itemToUse.type) {
    case ItemTypes.Start:
      color = '#5DA9E9';
      break;
    case ItemTypes.Checkpoint:
      color = '#6D7278';
      break;
    case ItemTypes.SecondaryMission:
      color = '#CE5374';
      break;
    case ItemTypes.Unlockable:
      color = '#1D1E2C';
      break;
    case ItemTypes.Anecdote:
      color = '#3C5073';
      break;
    case ItemTypes.AnecdotePOI:
      color = '#d19fcd';
      break;
    case ItemTypes.Archive:
      color = '#9C528B';
      break;
    case ItemTypes.Discussion:
      color = '#F9C784';
      break;
    case ItemTypes.DiscussionPOI:
      color = '#d19fcd';
      break;
    case ItemTypes.Custom:
      color = '#A71D31';
      break;
    case ItemTypes.Document:
      color = '#5D239D';
      break;
    case ItemTypes.DocumentPOI:
      color = '#d19fcd';
      break;
    case ItemTypes.Video:
      color = '#5D239D';
      break;
    case ItemTypes.Image360:
      color = '#5D239D';
      break;
    case ItemTypes.Openable:
      color = '#FCAF58';
      break;
    case ItemTypes.Timer:
      color = '#3D6E71';
      break;
    case ItemTypes.Tool:
      color = '#9C0D38';
      break;

    case ItemTypes.Success:
      color = '#05DECB';
      break;
    case ItemTypes.Failure:
      color = '#E44E4C';
      break;

    case ItemTypes.TimeTravel:
      color = '#1d61cf';
      break;
    case ItemTypes.GameArea:
      color = '#284B63';
      break;
    case ItemTypes.POI:
      color = '#9A7197';
      break;
    case ItemTypes.AMS:
      color = '#333333';
      break;
    case ItemTypes.Comment:
      color = '#3498db';
      break;
    case ItemTypes.BackgroundMusic:
      color = '#f3e16b';
      break;
    case ItemTypes.BackgroundMusicControls:
      color = '#ffc517';
      break;
    case ItemTypes.SoundEffect:
      color = '#fad859';
      break;

    // Discussions
    case ItemNodeTypes.Message:
      color = '#FCAF58';
      break;
    case ItemNodeTypes.Answer:
      color = '#5DA9E9';
      break;
    case ItemNodeTypes.AnswerOpened:
      color = '#5DA9E9';
      break;
    case ItemNodeTypes.Trigger:
      color = '#f2784b';
      break;
    case ItemNodeTypes.EntryPoint:
      color = '#d2d7d3';
      break;
    default:
      color = 'red';
  }
  return color;
};

export default {
  type: {
    name: 'screens.scenarioEdition.structure.graphColorations.type',
    id: 'type',
    colorator: itemTypeColorator,
  },
  progress: {
    name: 'screens.scenarioEdition.structure.graphColorations.progress',
    id: 'progress',
    colorator: itemProgressColorator,
  },
  clues: {
    name: 'screens.scenarioEdition.structure.graphColorations.clues',
    id: 'clues',
    colorator: itemCluesColorator,
  },
  metrics: {
    name: 'screens.scenarioEdition.structure.graphColorations.metrics',
    id: 'metrics',
    colorator: itemMetricsColorator,
  },
  validity: {
    name: 'screens.scenarioEdition.structure.graphColorations.validity',
    id: 'validity',
    colorator: itemReleaseErrorColorator,
  },
};
