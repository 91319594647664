/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import type { ItemProgressionType } from 'src/data/BaseItem';
import type { screenPlayItemType } from 'src/store/configuration/ConfigurationReducer';

import HelpButton from '../HelpButton';

export interface InputProgressProps {
  fieldName: string;
  value: ItemProgressionType;
  availableStates: screenPlayItemType;
  label: string;
  disabled?: boolean;
  hidden?: boolean;
  helpInfos: any;
  handleChange: (any) => any;
  separatorBefore?: boolean;
  onFocus?: () => any;
  t: (key: string) => string;
}

export default class InputProgress extends React.Component<InputProgressProps> {
  static defaultProps = {
    disabled: false,
    availableStates: [],
    hidden: false,
    helpInfos: undefined,
    separatorBefore: false,
  };

  handleNumberChange = (event: any) => {
    const { id, value: newValue } = event.target;
    const { value, handleChange, fieldName } = this.props;
    const newVal = { ...value };
    if (event.target.value && event.target.value.length) {
      newVal[id] = parseInt(newValue, 10);
    } else {
      delete newVal[id];
    }
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  updateValue = (add: boolean, id: string) => {
    const { value } = this.props;
    const event = {
      target: {
        id,
        // Add or remove one, and make it a string
        value: Math.max(((value && value[id]) || 0) + (add ? 1 : -1), 0).toString(),
      },
    };
    this.handleNumberChange(event);
  };

  render() {
    const {
      fieldName,
      value,
      availableStates,
      label,
      disabled,
      hidden,
      helpInfos,
      separatorBefore,
      onFocus,
      t,
    } = this.props;
    return (
      <div className="form-group" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <label className="mb-0 strong text-capitalize ooredoo" htmlFor={fieldName}>
          {`${label} `}
          {helpInfos &&
            helpInfos.map((helpInfo, index) => (
              <HelpButton
                key={fieldName + index}
                helpStrings={helpInfo.content}
                id={fieldName}
                title={helpInfo.title}
                label={helpInfo.btnLabel}
                display={'inline'}
                className={'ml-auto btn btn-outline-warning ml-2'}
              />
            ))}
        </label>
        {availableStates && (
          <div className="container mt-2">
            <div className="row">
              <div className="col-6">
                <label className="font-weight-bold text-capitalize ooredoo">
                  {t('screens.scenarioEdition.baseItemEdition.progressionStateItem')}
                </label>
              </div>
              <div className="col-6">
                <label className="font-weight-bold text-capitalize ooredoo">
                  {t('screens.scenarioEdition.baseItemEdition.progressionPoints')}
                </label>
              </div>
            </div>
            {availableStates.map((val) => (
              <>
                <hr className="p-0 m-0 mb-1 mt-1" />
                <div className="row" key={val.key}>
                  <div className="col-6">{t(`triggers.${val.key.toLowerCase()}`)}</div>
                  <div className="col-6 text-center justify-content-end">
                    <div className="row w-100 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-sm w-25 border-0"
                        onClick={() => this.updateValue(false, val.key)}
                      >
                        -
                      </button>
                      <input
                        className="text-center border-0"
                        id={val.key}
                        onChange={this.handleNumberChange}
                        value={(value && value[val.key]) || '0'}
                        disabled={disabled}
                        aria-describedby={`${fieldName}Help`}
                        onFocus={onFocus}
                        style={{ backgroundColor: '#f0f5fa', borderRadius: '5px', width: '30%' }}
                      />
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-sm w-25 border-0"
                        onClick={() => this.updateValue(true, val.key)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </div>
    );
  }
}
