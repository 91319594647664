/**
 * @flow
 * @format
 */

const white = '#FFFFFF';
const darkBlue = '#333333';
const lightBlue = '#333333';
const aqua = '#AE68F7';
const purple = '#FC1472';
const greyBg = '#E8EDF3';

const Colors = {
  white,
  whiteAlphaLight: `${white}88`,
  whiteAlphaDark: `${white}DD`,
  black: '#000000',

  greyBg,
  greyBgAlphaLight: `${greyBg}88`,
  greyBgAlphaDark: `${greyBg}DD`,

  aqua,
  aquaTransparent: `${aqua}00`,
  aquaAlphaLight: `${aqua}88`,

  darkBlue,
  darkBlueTransparent: `${darkBlue}00`,
  darkBlueAlphaLightPlus: `${darkBlue}33`,
  darkBlueAlphaLight: `${darkBlue}88`,
  darkBlueAlphaDark: `${darkBlue}DD`,

  lightBlue,

  purple,
  purpleAlphaLight: `${purple}88`,

  mapStartPOI: '#E30300',
  mapNPCPOI: '#FFFFFF',
  mapObjectivePOI: '#FF00C2',
  mapMajorPOI: '#F5A623',
  mapInteractivePOI: '#F8E71C',

  redML: '#EF5350',

  greyDisabled: '#5A5A5A',

  fontGreyLight: '#7A7A7A',
  fontGreyDark: '#494949',
};

export default Colors;
