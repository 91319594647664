/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import './helpButton.css';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

export interface HelpButtonProps {
  helpStrings: { [section: string]: { title: string, content: string | string[] } };
  title: string;
  id: string;
  className?: string;
  display?: string;
  label: string;
  containerClassName?: string;
}

class HelpButton extends React.Component<HelpButtonProps> {
  static defaultProps = {
    label: 'general.help',
  };

  renderCollapseHelp = (parentId: string, id: string, title: string, content: string | string[]) => (
    <div className="card" key={id}>
      <div className="card-header" id={`heading${id}`}>
        <h5 className="mb-0 d-flex">
          <button
            className="btn btn-link collapsed flex-fill"
            type="button"
            data-toggle="collapse"
            data-target={`#collapse${id}`}
            aria-expanded="false"
            aria-controls={`collapse${id}`}
          >
            {title}
          </button>
        </h5>
      </div>
      <div id={`collapse${id}`} className="collapse" aria-labelledby={`heading${id}`} data-parent={parentId}>
        <div className="card-body">
          {typeof content === 'string'
            ? content
            : content.map((it, index) => (
                <div style={{ marginTop: 5 }} key={index}>
                  {`- ${it}`}
                </div>
              ))}
        </div>
      </div>
    </div>
  );

  render() {
    const { helpStrings, title, id, display, containerClassName } = this.props;
    const modalId = `help${id}`;
    const headerId = `helpHeader${id}`;
    const accordionId = `accordion${id}`;
    const contClass = containerClassName || 'ml-auto';
    const containerStyle = display ? { display } : {};
    return (
      <div className={contClass} style={containerStyle}>
        <i
          className="fa icon-question float-right"
          data-toggle="modal"
          data-target={`#${modalId}`}
          style={{ color: '#AE68F7', fontSize: '20px' }}
        >
          &#xf059;
        </i>
        <div className="modal fade" id={modalId} role="dialog" aria-labelledby={`#${headerId}`} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id={headerId}>
                  {title}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="accordion" id={accordionId}>
                  {Object.keys(helpStrings).map((key) =>
                    this.renderCollapseHelp(
                      `#${accordionId}`,
                      id + key,
                      helpStrings[key].title,
                      helpStrings[key].content,
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withTranslation('default'))(HelpButton);
