/**
 * @flow
 *
 * @format
 */

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { Reference, Database } from '@firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import type { ClaimType } from 'src/constants/roles';
import { i18n } from 'src/assets/locales';
import { Claims } from 'src/constants/roles';
import { NotificationTypes } from 'src/store/events';

import type { ScenarioVendingInfo, MissionType } from 'src/data';
import { LocalizedString, Scenario } from 'src/data';
import { asyncForEach } from 'src/utils';

import { Constants } from 'src/assets';
import type { OnboardingOptions } from 'src/data/types/OnboardingOptions';
import { CatalogTypes } from './types';
import type { CatalogType } from './types';

type Code = {
  id?: string,
  scenarioId: string,
  startDate?: Date,
  endDate?: Date,
  currentCount: number,
  maxNumber: number,
  _meta?: {
    info?: string,
  },
};

class Firebase {
  db: Database;

  auth: any;

  storage: any;

  functions: any;

  conn: Reference;

  currentRoom: Reference;

  roomId: ?string;

  userTokenRef: Reference;

  userTokenCallback: ?() => void;

  email: String;

  userId: String;

  sessionStartDate: number;

  tosUrl: string;

  privacyUrl: string;

  constructor(
    conf?: any = {
      apiKey: Constants.firebase.apiKey,
      authDomain: Constants.firebase.authDomain,
      databaseURL: Constants.firebase.databaseURL,
      projectId: Constants.firebase.projectId,
      storageBucket: Constants.firebase.storageBucket,
    },
    name?: string = 'current',
    functionsRegion?: ?string = Constants.firebase.functionsRegion,
  ) {
    const config = conf;
    const app = firebase.initializeApp(config, name);

    this.db = app.database();
    this.auth = app.auth();
    this.storage = app.storage();
    this.functions = app.functions(functionsRegion);
    this.tosUrl = Constants.tosUrl;
    this.privacyUrl = Constants.privacyUrl;

    // We use the local functions emulator
    if (process.env.REACT_APP_USE_EMULATORS) {
      this.functions.useFunctionsEmulator('http://localhost:5001');
    }

    this.auth.onIdTokenChanged(async (user) => {
      if (this.userTokenCallback) {
        this.userTokenRef.off('value', this.userTokenCallback);
        this.userTokenCallback = undefined;
        this.userTokenRef = undefined;
      }
      const { roomId } = this;
      if (roomId) {
        await this.leaveRoom();
        await this.enterRoom(roomId, user);
      }
      if (user) {
        // User is signed in or token was refreshed.
        this.email = user.email || user.providerData[0].email;

        this.userId = user.uid;
        const userClaims = await this.hasClaims(user, [
          Claims.Admin,
          Claims.Editor,
          Claims.ConfirmedEditor,
          Claims.Translator,
          Claims.Moderator,
        ]);
        if (!userClaims.length) {
          await this.becomeEditor();
        }
        this.userTokenCallback = () => {
          user.getIdToken(true);
        };
        this.userTokenRef = this.userToken(user);
        this.userTokenRef.on('value', this.userTokenCallback);
        this.notifyConnected(user);
      } else {
        this.doSignOut();
      }
    });
  }

  // **** Authentication ****

  /*
   * Returns the claims that matched
   */
  hasClaims = async (user: any, claims: ClaimType[]) => {
    const res = [];
    const rights = user && (await user.getIdTokenResult());
    claims.forEach((claim) => {
      if (rights && rights.claims[claim]) {
        res.push(claim);
      }
    });
    return res;
  };

  updateSession = async () => {
    if (this.conn) {
      this.conn.transaction((currentData) => {
        if (currentData) {
          return { ...currentData, sessionRefreshDate: new Date().getTime() };
        }
      });
    }
  };

  notifyConnected = async (user: any) => {
    const connectedDevices = this.connectedDevices();
    if (this.conn) {
      this.conn.onDisconnect().cancel();
      this.conn.remove();
    }
    this.conn = connectedDevices.push();
    this.sessionStartDate = new Date().getTime();
    this.conn.set({
      email: user.email,
      sessionStartDate: this.sessionStartDate,
    });
    this.conn.onDisconnect().remove();
  };

  enterRoom = async (roomId: string, user: any) => {
    if (this.currentRoom) {
      this.currentRoom.onDisconnect().cancel();
      this.currentRoom.remove();
    }
    const myRoom = this.room(roomId);
    this.currentRoom = myRoom.push();
    this.currentRoom.onDisconnect().remove();
    const date = new Date().getTime();
    const data = {
      email: user.email,
      roomStartDate: date,
      lastUpdate: date,
      sessionStartDate: this.sessionStartDate || new Date().getTime(),
    };
    this.currentRoom.set(data);
    this.roomId = roomId;
    return this.currentRoom;
  };

  leaveRoom = async () => {
    if (this.currentRoom) {
      this.currentRoom.onDisconnect().cancel();
      this.currentRoom.remove();
      this.currentRoom = undefined;
    }
    this.roomId = undefined;
  };

  getAuthError = (errorCode: string) => {
    let finalError;
    switch (errorCode) {
      case 'auth/account-exists-with-different-credential':
        finalError = 'E_LOGIN_EXISTS_BAD_PROVIDER';
        break;
      case 'auth/email-already-in-use':
        finalError = 'E_LOGIN_EMAIL_USED';
        break;
      case 'auth/invalid-credential':
      case 'auth/invalid-email':
        finalError = 'E_LOGIN_INVALID_EMAIL';
        break;
      case 'auth/weak-password':
        finalError = 'E_LOGIN_WEAK_PASSWORD';
        break;
      case 'auth/user-disabled':
        finalError = 'E_LOGIN_USER_DISABLED';
        break;
      case 'auth/too-many-requests':
        finalError = 'E_LOGIN_TOO_MANY';
        break;
      case 'auth/user-not-found':
        finalError = 'E_LOGIN_NOT_FOUND';
        break;
      case 'auth/wrong-password':
        finalError = 'E_LOGIN_BAD_PASSWORD';
        break;
      case 'auth/cancelled-popup-request':
      case 'auth/popup-closed-by-user':
        finalError = 'E_LOGIN_CANCELED';
        break;
      case 'auth/popup-blocked':
        finalError = 'E_POPUP_BLOCKED';
        break;
      default:
        finalError = 'E_LOGIN_UNKNOWN';
    }
    return finalError;
  };

  signUpEditor = async (
    email: string,
    password: string,
    editorTosAgreed: boolean,
    editorConfidentialityAgreed: boolean,
  ) => {
    const result = await this.functions.httpsCallable('signUpEditor')({
      language: i18n.language,
      email,
      password,
      editorTosAgreed,
      editorConfidentialityAgreed,
    });
    if (result.data.uid) {
      return result.data;
    }
    throw new Error(result.data.error);
  };

  doSignInWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    this.auth.useDeviceLanguage();
    provider.setCustomParameters({
      display: 'popup',
    });
    try {
      const result = await this.auth.signInWithPopup(provider);

      // The signed-in user info.
      return this.user(result.user.uid);
    } catch (error) {
      // Handle Errors here.
      throw new Error(this.getAuthError(error.code));
    }
  };

  doSignInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    this.auth.useDeviceLanguage();
    provider.setCustomParameters({
      display: 'popup',
    });
    try {
      const result = await this.auth.signInWithPopup(provider);
      // The signed-in user info.
      return this.user(result.user.uid);
    } catch (error) {
      // Handle Errors here.
      throw new Error(this.getAuthError(error.code));
    }
  };

  doSignInWithApple = async () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    this.auth.useDeviceLanguage();
    provider.setCustomParameters({
      display: 'popup',
    });
    try {
      const result = await this.auth.signInWithPopup(provider);

      // The signed-in user info.
      return this.user(result.user.uid);
    } catch (error) {
      throw new Error(this.getAuthError(error.code));
    }
  };

  doSignInWithEmailAndPassword = async (email: string, password: string) => {
    try {
      const result = await this.auth.signInWithEmailAndPassword(email, password);
      return this.user(result.user.uid);
    } catch (error) {
      throw new Error(this.getAuthError(error.code));
    }
  };

  doSignOut = () => {
    this.auth.signOut();
    if (this.userTokenCallback) {
      this.userTokenRef.off('value', this.userTokenCallback);
      this.userTokenCallback = undefined;
      this.userTokenRef = undefined;
    }
  };

  // *** User API ***

  userToken = (user: any): Reference => this.db.ref(`usersToken/${user.uid}/refreshTime`);

  connectedDevices = (): Reference => this.db.ref('editor/connections/users');

  rooms = (): Reference => this.db.ref('editor/connections/rooms');

  room = (roomId: string): Reference => this.rooms().child(roomId);

  user = (uid: string): Reference => this.db.ref(`users/${uid}`);

  users = (): Reference => this.db.ref('users');

  // **** Scenarios *****

  // Editor databases
  listAuthorizedScenarios = async (uid: string) => {
    const teamsSnap = await this.userEditingTeams(uid).once('value');
    const teamIdsMap = teamsSnap.exists() ? teamsSnap.val() : {};
    const scenarios = [];
    await asyncForEach(Object.keys(teamIdsMap), async (teamId) => {
      const teamScenariosSnap = await this.teamScenarios(teamId).once('value');

      const teamScenarios = teamScenariosSnap.exists() ? teamScenariosSnap.val() : [];
      await asyncForEach(teamScenarios, async (id) => {
        const scenarioSnap = await this.scenario(id).once('value');
        scenarios.push(new Scenario(scenarioSnap.val().header));
      });
    });
    return scenarios;
  };

  scenarios = (filter?: string, startAt?: any, endAt?: any): Reference => {
    if (!filter) {
      return this.db.ref('editor/scenarios').orderByKey();
    }
    let res = this.db.ref('editor/scenarios').orderByChild(filter);
    if (startAt !== undefined) {
      res = res.startAt(startAt);
    }
    if (endAt !== undefined) {
      res = res.endAt(endAt);
    }
    return res;
  };

  scenario = (scenarioId: string): Reference => this.db.ref(`editor/scenarios/${scenarioId}`);

  scenarioEditorMaintenance = (scenarioId: string): Reference => this.scenario(scenarioId).child('maintenance');

  scenarioEditorHeader = (scenarioId: string, version?: string): Reference => {
    if (!version) {
      return this.scenario(scenarioId).child('header');
    }
    return this.scenarioEditorVersionBackup(scenarioId, version).child('header');
  };

  scenarioEditorItemsData = (scenarioId: string, version?: string): Reference => {
    if (!version) {
      return this.scenario(scenarioId).child('itemsData');
    }
    return this.scenarioEditorVersionBackup(scenarioId, version).child('itemsData');
  };

  scenarioEditorChanges = (scenarioId: string, version: string): Reference =>
    this.scenario(scenarioId).child(`changes/${version}`);

  scenarioEditorBackups = (scenarioId: string): Reference => this.scenario(scenarioId).child(`backups`);

  scenarioEditorTempBackup = (scenarioId: string): Reference => this.scenarioEditorBackups(scenarioId).child(`temp`);

  scenarioEditorVersionBackup = (scenarioId: string, version: string): Reference =>
    this.scenarioEditorBackups(scenarioId).child(version);

  scenarioEditorNPCs = (scenarioId: string, version?: string): Reference => {
    if (!version) {
      return this.scenario(scenarioId).child('npcs');
    }
    return this.scenarioEditorVersionBackup(scenarioId, version).child('npcs');
  };

  // Editor storage
  scenarioEditorStorage = (scenarioId: string): Reference => this.storage.ref(`editor/scenarios/${scenarioId}`);

  // App databases
  schoolScenarios = (catalog: CatalogType = CatalogTypes.prod): Reference =>
    this.db.ref('releases/scenarios/schoolList').child(`catalog/${catalog}`);

  scenariosHeader = (catalog: CatalogType = CatalogTypes.prod): Reference =>
    this.db.ref('releases/scenarios').child(`catalog/${catalog}`);

  scenarioHeader = (scenarioId: string, catalog: CatalogType = CatalogTypes.prod): Reference =>
    this.scenariosHeader(catalog).child(`${scenarioId}`);

  scenarioVendingInfo = (scenarioId: string, catalog: CatalogType = CatalogTypes.prod) =>
    this.scenarioHeader(scenarioId, catalog).child('vendingInfo');

  scenariosData = (): Reference => this.db.ref('releases/scenarios').child('data');

  scenarioData = (scenarioId: string): Reference => this.scenariosData().child(`${scenarioId}`);

  scenarioDataVersion = (scenarioId: string, version: string): Reference =>
    this.scenarioData(scenarioId).child(`${version}`);

  // App storage
  scenarioStorage = (scenarioId: string): Reference => this.storage.ref(`releases/scenarios/${scenarioId}`);

  // ***** AMS *****

  amss = (): Reference => this.db.ref('editor/ams').orderByKey();

  ams = (amsId: string): Reference => this.db.ref(`editor/ams/${amsId}`);

  amsEditorStorage = (amsId: string): Reference => this.storage.ref(`editor/ams/${amsId}`);

  amssData = (): Reference => this.db.ref('releases/ams/data');

  amsData = (amsId: string): Reference => this.amssData().child(`${amsId}`);

  amsDataVersion = (amsId: string, version: string): Reference => this.amsData(amsId).child(version);

  amssIndex = (catalogType: CatalogType = CatalogTypes.prod): Reference =>
    this.db.ref(`releases/ams/catalog/${catalogType}`);

  amsIndex = (amsId: string, catalogType: CatalogType = CatalogTypes.prod): Reference =>
    this.amssIndex(catalogType).child(`${amsId}`);

  amsStorage = (amsId: string): Reference => this.storage.ref(`releases/ams/${amsId}`);

  // ****** AMS per scenario ******

  amsPerScenarios = (catalogType: CatalogType = CatalogTypes.prod): Reference =>
    this.db.ref(`releases/amsPerScenario/catalog/${catalogType}`);

  amsPerScenario = (scenarioId: string, catalogType: CatalogType): Reference =>
    this.amsPerScenarios(catalogType).child(`${scenarioId}`);

  // ***** Cities *****

  cities = (): Reference => this.db.ref('editor/cities');

  prodCities = (): Reference => this.db.ref('releases/cities/catalog/prod');

  city = (cityId: string): Reference => this.db.ref(`editor/cities/${cityId}`);

  cityRelease = (cityId: string, catalogType: CatalogType = CatalogTypes.prod): Reference =>
    this.db.ref(`releases/cities/catalog/${catalogType}/${cityId}`);

  cityEditorStorage = (cityId: string): Reference => this.storage.ref(`editor/cities/${cityId}`);

  cityStorage = (cityId: string): Reference => this.storage.ref(`releases/cities/${cityId}`);

  // **** Teams ****

  teams = (): Reference => this.db.ref(`teams`);

  createTeamAsync = async (
    teamName?: string,
    administratorId?: string,
    type?: string = 'STANDARD',
    creatorName?: string,
    webCreatorURL?: string,
  ) => {
    const result = await this.functions.httpsCallable('createTeam')({
      name: teamName,
      administratorId,
      type,
      creatorName,
      webCreatorURL,
    });
    if (result.data.uid) {
      return result.data;
    }
    throw new Error(result.data.message);
  };

  /**
   * Update the given team with these new information
   * @param {string} id the id of the team
   * @param {string} teamName the new name
   * @param {string} administratorId the user should exist
   * @param {string} type the type of the team (should be a valid one)
   * @param {string} newTeamId the team to where we want to send the scenario
   * @param {string} scenarioId the scenario to move
   * @param {string} creatorName
   * @param {string} webCreatorURL
   * @returns team updated
   */
  updateTeamAsync = async (
    id: string,
    teamName?: string,
    administratorId?: string,
    type?: string,
    newTeamId?: string,
    scenarioId?: string,
    creatorName?: string,
    webCreatorURL?: string,
  ) => {
    const result = await this.functions.httpsCallable('updateTeam')({
      teamId: id,
      name: teamName,
      administratorId,
      type,
      newTeamId,
      scenarioId,
      creatorName,
      webCreatorURL,
    });
    if (result.data.success) {
      if (newTeamId) {
        // Update scenario, we need both of the updated teams
        return {
          oldTeam: result.data.oldTeam,
          newTeam: result.data.newTeam,
        };
      }
      return result.data.team;
    }
    throw new Error(result.data.message);
  };

  becomeEditor = async () => {
    const result = await this.functions.httpsCallable('becomeEditor')({
      language: i18n.language,
    });
    return result.data;
  };

  updateUserConsent = async (
    editorTosAgreed?: boolean,
    editorConfidentialityAgreed?: boolean,
    tosAccepted?: boolean,
  ) => {
    const result = await this.functions.httpsCallable('updateUserConsent')({
      editorTosAgreed,
      editorConfidentialityAgreed,
      tosAccepted,
    });
    return result.data;
  };

  createUsersAsync = async (emails?: string, type?: string = 'STANDARD', language?: string) => {
    const result = await this.functions.httpsCallable('bulkCreateEditorUsers')({
      emails,
      type,
      language,
    });
    return result.data;
  };

  sendForgotPasswordEmail = async (email?: string) => {
    const result = await this.functions.httpsCallable('askResetPassword')({
      email,
    });
    return result.data;
  };

  createScenarioAsync = async (scenarioId?: string, teamId?: string, onboarding?: OnboardingOptions) => {
    const result = await this.functions.httpsCallable('createScenario')({
      scenarioId,
      teamId,
      onboarding,
    });
    if (result.data.success) {
      return result.data;
    }
    throw new Error(result.data.message);
  };

  // User
  getUserByEmailAsync = async (email: string) => {
    const result = await this.functions.httpsCallable('getUserByEmail')({ email });
    if (result.data) {
      return result.data.user;
    }
    throw new Error('Cannot get User');
  };

  getUserEditingTeams = async (userId?: string) => {
    const result = await this.functions.httpsCallable('getUserEditingTeams')({});

    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot update team testers');
  };

  getUserAdministratedTeams = async (userId?: string) => {
    const result = await this.functions.httpsCallable('getUserAdministratedTeams')({});

    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot update team testers');
  };

  /**
   * Retrieves all the teams (administrated/editing/testing) of a user
   * @param {string} userId
   * @returns all the teams in different arrays
   */
  getUserAllTeams = async (userId: string) => {
    const result = await this.functions.httpsCallable('getUserAllTeams')({ userId });
    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot retrieve all teams of user');
  };

  updateTeamAppTesters = async (teamId?: string, matriculeToAdd?: string, userToRemove?: string) => {
    const result = await this.functions.httpsCallable('updateTeamAppTesters')({ matriculeToAdd, userToRemove, teamId });
    if (result.data.success) {
      return result.data.team;
    }
    throw new Error(result.data.error.message);
  };

  updateTeamStudioEditors = async (teamId?: string, emailToAdd?: string, userToRemove?: string) => {
    const result = await this.functions.httpsCallable('updateTeamStudioEditors')({ emailToAdd, userToRemove, teamId });
    if (result.data.success) {
      return result.data.team;
    }
    throw new Error(result.data.error.message);
  };

  /**
   * Search the teams with the given id or name
   * @param {string?} searchString the value to use
   * @param {string} typeSearch indicates if we search with the id or name of the team
   * @returns list of the teams answering the query
   */
  getTeamData = async (searchString?: string, typeSearch: string) => {
    let teamId: ?string;
    let teamName: ?string;
    switch (typeSearch) {
      case 'id':
        teamId = searchString;
        break;
      case 'name':
        teamName = searchString;
        break;
      default:
    }
    const result = await this.functions.httpsCallable('getTeam')({ teamId, teamName });
    if (result.data) {
      return result.data;
    }
    return [];
  };

  /**
   * POTENTIALLY DEPRECATED
   * Used once by a method never called ??
   * Method used now => createUsersAsync
   */
  bulkCreateEditorUsers = async (emails: string) => {
    const result = await this.functions.httpsCallable('bulkCreateEditorUsers')({ emails });
    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot update team testers');
  };

  updateTeamStudioMembers = async (emailToAdd: string, userToRemove: string) => {
    const result = await this.functions.httpsCallable('updateTeamStudioMembers')({ emailToAdd, userToRemove });
    if (result.data.success) {
      return result.data.team;
    }
    throw new Error('Cannot update team members');
  };

  /**
   * Retrieve the user by using one of the following criteria (uid,email or collarNumber)
   * @param {string} searchString the query
   * @param {string} typeSearch the type of query
   * @returns a user
   */
  searchUser = async (searchString: string, typeSearch: string) => {
    let idUser: string;
    let mailUser: string;
    let collarNumber: string;
    switch (typeSearch) {
      case 'id':
        idUser = searchString;
        break;
      case 'mail':
        mailUser = searchString;
        break;
      case 'collarNumber':
        collarNumber = searchString.toLowerCase();
        break;
      default:
    }
    const result = await this.functions.httpsCallable('searchUser')({ idUser, mailUser, collarNumber });
    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot retrieve user');
  };

  userEditingTeams = (uid: string): Reference => this.db.ref(`users/${uid}/editingTeams`);

  teamScenarios = (uid: string): Reference => this.db.ref(`teams/${uid}/scenarioIds`);

  setUserRightsAsync = async (
    email: string,
    uid: string,
    claims: { admin: boolean, moderator: boolean, editor: boolean, confirmedEditor: boolean, translator: boolean },
  ) => {
    await this.functions.httpsCallable('setCustomClaims')({ emails: [email], claims });
  };

  migrateUsersAsync = async (
    userIds: string[],
    dryRun: boolean,
    removeOldData: boolean,
    catalog: CatalogType = CatalogTypes.prod,
  ) => {
    const result = await this.functions.httpsCallable('migrations-usersData')({
      userIds,
      catalog,
      dryRun,
      removeOldData,
    });
    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot migrate Users');
  };

  migrateCEsAsync = async (dryRun: boolean, removeOldData: boolean) => {
    const result = await this.functions.httpsCallable('migrations-ceSkus')({ dryRun, removeOldData });
    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot migrate CEs');
  };

  migrateCodesAsync = async (dryRun: boolean, removeOldData: boolean) => {
    const result = await this.functions.httpsCallable('migrations-codeSkus')({ dryRun, removeOldData });
    if (result.data) {
      return result.data;
    }
    throw new Error('Cannot migrate Codes');
  };

  // Catalog

  getPreconfiguredProducts = async () => {
    const allProductsSnap = await this.db.ref('products/items').once('value');
    const allProducts = allProductsSnap.val();
    const products = [];
    if (allProducts) {
      Object.keys(allProducts).forEach((it) => {
        const product = allProducts[it];
        if (product.expendable) {
          products.push({ ...product, id: it });
        }
      });
    }
    return products;
  };

  releaseScenario = async (scenarioId: string, teamId: string, dryRun?: boolean = false) => {
    try {
      const result = await this.functions.httpsCallable('releaseScenario')({
        scenarioId,
        teamId,
        dryRun,
      });
      return result.data;
    } catch (error) {
      return {
        notifications: [
          {
            message: error.message,
            type: NotificationTypes.ERROR,
          },
        ],
      };
    }
  };

  getScenariosToDeployAsync = async () => {
    const result = await this.functions.httpsCallable('getScenariosToDeploy')({});

    const newScenarios = result.data.new;
    Object.keys(newScenarios).forEach((scenarioId) => {
      const scenarioInfo = newScenarios[scenarioId];

      scenarioInfo.name = new LocalizedString('name', scenarioInfo.name);
      scenarioInfo.subtitle = new LocalizedString('subtitle', scenarioInfo.subtitle);
    });
    const currentScenarios = result.data.current;
    Object.keys(currentScenarios).forEach((scenarioId) => {
      const scenarioInfo = currentScenarios[scenarioId];

      scenarioInfo.name = new LocalizedString('name', scenarioInfo.name);
      scenarioInfo.subtitle = new LocalizedString('subtitle', scenarioInfo.subtitle);
    });

    return { newScenarios, currentScenarios };
  };

  checkScenarioVersionAsync = (scenarioId: string, versionToCheck?: string) =>
    this.functions.httpsCallable('checkScenarioProdIntegrity')({ scenarioId, versionToCheck });

  updateVendingInfoAsync = (
    scenarioId: string,
    vendingInfo: ScenarioVendingInfo,
    isOfficial: boolean,
    missionType: MissionType,
  ) =>
    this.functions.httpsCallable('updateVendingInfo')({
      scenarioId,
      vendingInfo,
      isOfficial,
      missionType,
      dryRun: false,
    });

  dupplicateScenarioAsync = (destScenarioId: string, sourceScenarioId: string, destCityId: string) =>
    this.functions.httpsCallable('dupplicateScenario')({
      sourceScenarioId,
      destScenarioId,
      destCityId,
      dryRun: false,
    });

  // AMS
  getAMSsToDeployAsync = async () => {
    const result = await this.functions.httpsCallable('getAMSsToDeploy')({});

    return { newAMSs: result.data.new, currentAMSs: result.data.current };
  };

  deployAMSsAsync = (amsIds: string[], dryRun?: boolean) =>
    this.functions.httpsCallable('deployAMSs')({ amsIds, dryRun });

  // Codes
  codes = () => this.db.ref('codes');

  codeConfigurations = () => this.db.ref('codesConfigurations');

  generateSecretCode = (
    template: string,
    scenarioId: string,
    startDate?: number,
    endDate?: number,
    maxNumber?: number,
    metadata?: any,
  ) =>
    this.functions.httpsCallable('generateSecretCode')({
      template,
      scenarioId,
      startDate,
      endDate,
      maxNumber,
      metadata,
    });

  bulkGenerateSecretCodes = (
    template: string,
    scenarioId: string,
    startDate?: number,
    endDate?: number,
    maxNumber?: number,
    codeCount: Number,
    metadata?: any,
  ) =>
    this.functions.httpsCallable('bulkGenerateSecretCodes')({
      template,
      scenarioId,
      startDate,
      endDate,
      maxNumber,
      codeCount,
      metadata,
    });

  sendSecretCodesLabels = (codes: Code[], configurationId: string, recipients: string[], expirationDate: number) =>
    this.functions.httpsCallable('sendSecretCodesLabels')({
      codes,
      configurationId,
      recipients,
      expirationDate,
    });

  // CE
  ces = () => this.db.ref('CE');

  // **** Configuration ****

  editorConfig = (): Reference => this.db.ref('editor/config');

  mapSubmissionErrors = (): Reference => this.editorConfig().child('submissionErrors/map');

  wordingSubmissionErrors = (): Reference => this.editorConfig().child('submissionErrors/wording');

  assetsSubmissionErrors = (): Reference => this.editorConfig().child('submissionErrors/assets');

  editorMaintenance = (): Reference => this.db.ref('editor/maintenance');

  editorTours = (): Reference => this.db.ref('editor/tours');

  watchedTour = (userId: string, tourId: string): Reference => this.db.ref(`editor/watchedTours/${userId}/${tourId}`);

  editorDeployedVersion = (): Reference => this.db.ref('editor/deployedVersion');

  applicationVersions = (): Reference => this.db.ref('releases/appVersions');

  codeTypes = (): Reference => this.editorConfig().child('codeTypes');

  nextCustoms = (): Reference => this.editorConfig().child('nextCustoms');

  itemStatesMapping = (): Reference => this.editorConfig().child('itemStatesMapping');

  mapStyles = (): Reference => this.editorConfig().child('mapStyles');

  fontStyles = (): Reference => this.editorConfig().child('fontStyles');

  serverConfig = (): Reference => this.editorConfig().child('serverConfig');

  assetsBackgroundStorage = (): Reference => this.storage.ref('editor/imageEditor');

  assetBackgroundStorage = (assetId: string): Reference => this.assetsBackgroundStorage().child(assetId);

  imageUrl = (imagePath: string) => this.storage.ref(imagePath);

  getUserTeamReleasesState = (teamId?: string) => {
    const filter: any = {};
    if (teamId) {
      filter.teamId = teamId;
    }
    return this.functions.httpsCallable('userTeamReleasesState')(filter);
  };

  // submissions
  submissions = (): Reference => this.db.ref('releases/scenarios/submissions');

  pendingSubmissions = (): Reference => this.submissions().child('pending');

  closedSubmissions = (): Reference => this.submissions().child('closed');

  submission = (scenarioId: string, submissionId: string, isPending: boolean) => {
    if (isPending) {
      return this.pendingSubmissions()
        .child(scenarioId)
        .child(submissionId);
    }
    return this.closedSubmissions()
      .child(scenarioId)
      .child(submissionId);
  };

  submitRelease = (teamId: string, scenarioId: string, version: string, message?: string, isForced?: boolean) => {
    const filter: any = {};
    if (teamId) {
      filter.teamId = teamId;
    }
    return this.functions.httpsCallable('submitRelease')({
      teamId,
      scenarioId,
      version,
      message,
      isForced,
    });
  };

  cancelSubmission = (scenarioId: string, submissionId: string) => {
    return this.functions.httpsCallable('cancelSubmission')({
      scenarioId,
      submissionId,
    });
  };

  validateSubmission = (
    scenarioId: string,
    submissionId: string,
    decision: string,
    errors: { key?: string, message: string, pathToItem?: string }[],
  ) => {
    return this.functions.httpsCallable('validateSubmission')({
      scenarioId,
      submissionId,
      decision,
      feed: errors,
    });
  };

  // Statistics
  getScenarioSalesStatistics = (scenarioId: string, startDate?: string, endDate?: string, period?: string) => {
    const param = { scenarioId };
    if (startDate) {
      param.startDate = startDate;
    }
    if (endDate) {
      param.endDate = endDate;
    }
    if (period) {
      param.period = period;
    }
    return this.functions.httpsCallable('getScenarioSalesStatistics')(param);
  };

  getScenarioSuccessStatistics = (scenarioId: string, startDate?: string, endDate?: string, period?: string) =>
    this.functions.httpsCallable('getScenarioSuccessStatistics')({
      scenarioId,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      period: period || undefined,
    });

  getCitySalesStatistics = (cityId: string, startDate?: string, endDate?: string, period?: string) =>
    this.functions.httpsCallable('getCitySalesStatistics')({
      cityId,
      startDate,
      endDate,
      period,
    });

  translateString = (text: string, sourceLocale: string, destLocale: string) =>
    this.functions.httpsCallable('translateString')({
      text,
      sourceLocale,
      destLocale,
    });

  deleteScenarioAsync = async (scenarioId: string) => {
    const result = await this.functions.httpsCallable('deleteScenario')({ scenarioId });
    if (result.data.success) {
      return result.data.success;
    }
    return false;
  };

  getARVAccessStatus = async () => {
    const result = await this.functions.httpsCallable('getARVAccessStatus')();
    let arvStatus: boolean = false;
    if (result.data) {
      arvStatus = result.data.enableARVAccess;
    }
    return arvStatus;
  };

  setARVAccessStatus = async (enableARVAccess: boolean) => {
    const result = await this.functions.httpsCallable('setARVAccessStatus')({
      enableARVAccess,
    });
    let status: boolean = false;
    if (result.data.success) {
      status = true;
    }
    return status;
  };

  getWelcomeOfferStatus = async () => {
    const result = await this.functions.httpsCallable('getWelcomeOfferStatus')();
    let welcomeOfferStatus: boolean = false;
    if (result.data) {
      welcomeOfferStatus = result.data.enableWelcomeOffer;
    }
    return welcomeOfferStatus;
  };

  setWelcomeOffer = async (enableWelcomeOffer: boolean) => {
    const result = await this.functions.httpsCallable('setWelcomeOffer')({
      enableWelcomeOffer,
    });
    let status: boolean = false;
    if (result.data.success) {
      status = true;
    }
    return status;
  };

  notifyEditorError = async (userId: string, scenarioId: string, error: any) => {
    const result = await this.functions.httpsCallable('notifyEditorError')({
      userId,
      scenarioId,
      error,
    });
    return result;
  };
}

export default Firebase;

const Singleton: Firebase = new Firebase();

export { Singleton };
