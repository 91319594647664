/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import Countdown from 'react-countdown-now';
import Lottie from 'react-lottie-player';
import lottieLoader from 'src/assets/lottie/gemstone.json';

export interface LoaderProps {
  message?: string;
  endTime?: number;
}

export default class Loader extends React.Component<LoaderProps> {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const { endTime, message } = this.props;
    return (
      <div className="loaderContainer">
        <Lottie loop animationData={lottieLoader} play style={{ height: '10vh' }} />
        {endTime && (
          <div className="loaderTimer">
            <span>{message}</span>
            <Countdown date={endTime} />
          </div>
        )}
      </div>
    );
  }
}
