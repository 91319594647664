/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { DiamondNodeWidgetProps, DiagramEngine } from 'storm-react-diagrams';
import { ItemsServiceHelper } from 'src/store/scenario/items';

export interface BaseItemWidgetProps extends DiamondNodeWidgetProps {
  itemId: string;
  nodeId: string;
  outOfRedux: boolean;
  item: any;
  deleteTrigger: ItemsServiceHelper.updateTriggeredItemType;
  diagramEngine: DiagramEngine;
  itemTypeClass: string;
}

type Props = {
  size: number,
  fillColor: string,
  borderColor: string,
};

const SVGNode = ({ size, fillColor, borderColor }: Props) => {
  return (
    <svg
      style={{ zIndex: 1 }}
      width={size}
      height={size}
      dangerouslySetInnerHTML={{
        __html: `
           <g id="Layer_1">
           </g>
           <g id="Layer_2">
             <polygon fill="${fillColor || '#b38db0'}" stroke="${borderColor ||
          '#333333'}" stroke-width="3" stroke-miterlimit="10" points="10,${size / 2} ${size / 2},10 ${size -
          10},${size / 2} ${size / 2},${size - 10} "/>
           </g>
         `,
      }}
    />
  );
};

export default SVGNode;
