/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Firebase, { withFirebase } from 'src/services/Firebase';
import * as TeamServiceHelper from 'src/store/admin/TeamServiceHelper';

import type { Team } from 'src/data/types/TeamType';

import { InputSelect } from 'src/pages/components';

type TeamSearchType = {
  firebase: Firebase,
  getTeamData: TeamServiceHelper.getTeamDataType,
  setTeamDisplayed: (key?: Team) => void,
  handleChange: (event: Event) => void,
  searchTeamInput: string,
  searchTeamSelect: string,
  setSearchLoading: (loading: boolean) => void,
  searchTeamTypes: Object,
  teamDisplayed?: Team,
  otherTeamToUpdate: ?Team,
  t: (key: string) => string,
};

const TeamSearch = ({
  t,
  firebase,
  getTeamData,
  setSearchLoading,
  searchTeamInput,
  searchTeamSelect,
  setTeamDisplayed,
  teamDisplayed,
  handleChange,
  searchTeamTypes,
  otherTeamToUpdate,
}: TeamSearchType) => {
  const [teamsQueried, setTeamsQueried] = React.useState<Array<Team>>([]);

  /**
   * Used to update the teams displayed, in case one is updated by an admin
   */
  React.useEffect(() => {
    if (teamsQueried && teamDisplayed) {
      setTeamsQueried(
        teamsQueried.map((team) => {
          if (team.uid === teamDisplayed.uid) {
            return teamDisplayed;
          }
          return team;
        }),
      );
    }
  }, [teamDisplayed]);

  /**
   * Used to update the teams displayed, in case one has its scenario changed
   */
  React.useEffect(() => {
    if (teamsQueried && otherTeamToUpdate) {
      setTeamsQueried(
        teamsQueried.map((team) => {
          if (team.uid === otherTeamToUpdate.uid) {
            return otherTeamToUpdate;
          }
          return team;
        }),
      );
    }
  }, [otherTeamToUpdate]);

  /**
   * Executes fb function to retrieve teams with the corresponding criteria
   */
  const searchTeamAction = async () => {
    setSearchLoading(true);
    if (searchTeamInput && searchTeamInput.length) {
      const teamsRetrieved = await getTeamData(searchTeamInput, searchTeamSelect, firebase);
      setTeamsQueried(teamsRetrieved);
    }
    setSearchLoading(false);
  };

  return (
    <div className="row flex-fill mt-5">
      <div className="col-8">
        <label className="text-uppercase font-weight-bold ooredoo" htmlFor="searchTeamInput">
          {t('screens.admin.teams.searchTeamLabel')}
        </label>
        <div className="input-group">
          <input
            id="searchTeamInput"
            type="text"
            value={searchTeamInput}
            className="form-control"
            aria-describedby="button-addon2"
            onChange={handleChange}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={searchTeamAction}>
              {t('screens.admin.teams.buttonSearchTeam')}
            </button>
          </div>
        </div>
        <small className="form-text text-muted mb-4">{t('screens.admin.teams.caseSensitiveHelp')}</small>
      </div>
      <div className="col-4">
        <InputSelect
          fieldName="searchTeamSelect"
          value={searchTeamSelect}
          handleChange={handleChange}
          values={searchTeamTypes}
          itemToId={(it) => it.id}
          itemToTitle={(it) => it.name}
          label={t('screens.admin.teams.searchTypeTeamLabel')}
          disableEmptyOption={true}
        />
      </div>
      {teamsQueried.length > 0 ? (
        teamsQueried.map((team) => (
          <button
            id="clickTeam"
            key={team.uid}
            className="list-group-item list-group-item mb-3 list-group-item-action align-items-start"
            onClick={() => setTeamDisplayed(team)}
          >
            <div className="d-flex justify-content-between" key={team.uid}>
              <h5 className="mb-1">{team.name || team.uid}</h5>
              <small className="text-muted">{team.type}</small>
            </div>
          </button>
        ))
      ) : (
        <p>{t('screens.admin.teams.nothingToDisplay')}</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  getTeamData: TeamServiceHelper.getTeamData,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(TeamSearch);
