/**
 * @flow
 *
 * @format
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import TranslationsScreen from 'src/pages/components/translation/TranslationsScreen';
import { FirebaseSingleton } from 'src/services/Firebase';
import type { ScenarioReducerState } from 'src/store/scenario';

type Props = {
  onClick: (info: any) => any,
  info: any,
  locale: string,
  isActive: boolean,
  addError: (errors?: any[], item?: string) => any,
  items: ScenarioReducerState,
};

const WordingReview = (props: Props) => {
  const [currentItem, setCurrentItem] = useState(undefined);
  const [configuredErrors, setConfiguredErrors] = useState('');
  const { locale, t } = props;

  const { items } = props;

  const getPreconfiguredErrors = async () => {
    try {
      const configuredErrorsSnap = await FirebaseSingleton.wordingSubmissionErrors().once('value');
      const errors = configuredErrorsSnap.val();
      setConfiguredErrors(Object.entries(errors).map(([key, value]) => ({ key, value })));
    } catch (error) {
      // nothing
    }
  };
  useEffect(() => {
    getPreconfiguredErrors();
  }, []);

  const onSelectItem = (id?: string) => {
    setCurrentItem(id);
    props.addError(configuredErrors, id);
  };

  const addError = () => {
    props.addError(configuredErrors, currentItem);
  };

  return (
    <div className="card mt-2">
      <div className="card-header">
        <h5 className="card-title">{t('screens.review.wordingReview')}</h5>
      </div>
      <div className="card-body">
        <div className="container-fluid fill background-primary" id="graphContainer">
          <button className="btn btn-outline-warning ml-2" type="button" id="button-deploy" onClick={addError}>
            {t('screens.review.configureError')}
          </button>
          {props.header?.id && props.items && props.npcs && (
            <TranslationsScreen
              stringTranslationInfos={[
                { id: props.header.id, item: props.header, part: 'header' },
                ...Object.values(props.items).map((item) => ({ id: item.id, item, part: 'items' })),
                ...props.npcs.map((item) => ({ id: item.id, item, part: 'npcs' })),
              ]}
              section="scenario"
              translationId={props.header.id}
              readOnly
              extraActions={[
                {
                  label: <FontAwesomeIcon icon={['fad', 'bullseye-pointer']} color="red" />,
                  trigger: onSelectItem,
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(WordingReview);
