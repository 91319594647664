/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import Lottie from 'react-lottie-player';
import lottieLoader from 'src/assets/lottie/gemstone.json';
import { withTranslation } from 'react-i18next';
import { unregister } from 'src/registerServiceWorker';
// import '../../../assets/styles/app.scss';

export interface LoaderProps {
  startTime?: number;
  maintainers?: string[];
  requiredVersion?: string;
  t: (key: string) => string;
}

class MaintenanceLoader extends React.Component<LoaderProps> {
  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    unregister();
    if (!this.props.maintainers?.length) {
      window.location.reload(false);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="maintenanceLoaderContainer loaderContainer">
        <Lottie loop animationData={lottieLoader} play style={{ height: '10vh' }} />
        {this.props.maintainers ? (
          <p className="maintenanceData">
            {t('general.maintenancePlaceholder')}
            <br />
            {`${t('general.maintainers')}: ${this.props.maintainers.join(' - ')}`}
            <br />
            {`${t('general.since')}: ${new Date(this.props.startTime).toString()}`}
            <br />
          </p>
        ) : (
          <p className="maintenanceData">
            {t('general.updateTitle')}
            <br />
            {t('general.updateMessage')}
            <br />
            {t('general.updateTip')}
            <br />
            <br />
            {`${t('general.requiredVersionPlaceholder')}: ${this.props.requiredVersion}`}
            <br />
          </p>
        )}
      </div>
    );
  }
}
export default withTranslation('default')(MaintenanceLoader);
