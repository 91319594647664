/**
 * @flow
 * @format
 */
import React from 'react';

import { InputString, InputBoolean, InputNumber, InputDate, InputSelect } from 'src/pages/components';
import type { ProductItem } from 'src/data/types/ProductItemType';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { missionTypes, type MissionType } from 'src/data';
import { SwitchMissionType } from '../../scenario/header/components';

type Props = {
  comingSoon: boolean,
  externalSeller: boolean,
  visible: boolean,
  isFree: boolean,
  expendable: boolean,
  price: ?number,
  iapSku: ?string,
  promoExpirationDate: ?number,
  preconfiguredProducts: ProductItem[],
  preconfiguredProductId?: string,
  scenarioId: string,
  cardStyle: ?boolean,
  missionType: MissionType,
  handleChange: (event: { target: { id: string, value: boolean } }) => void,
  t: (key: string) => string,
};

const VisibilityAndVendingChanger = ({
  comingSoon,
  externalSeller,
  visible,
  isFree,
  expendable,
  price,
  iapSku,
  promoExpirationDate,
  preconfiguredProducts,
  preconfiguredProductId,
  scenarioId,
  cardStyle,
  missionType,
  handleChange,
  t,
}: Props) => {
  const handleMissionType = (event, isOfficial: boolean) => {
    const updateOfficial = {
      target: {
        id: 'isOfficial',
        value: isOfficial,
      },
    };
    handleChange(updateOfficial);
    if (event) {
      const updateMissionType = {
        target: {
          id: 'missionType',
          value: event.target.value,
        },
      };
      handleChange(updateMissionType);
    }
  };

  return (
    <div className={`${cardStyle ? '' : 'pr-2 pl-2'}`}>
      <div className={`${cardStyle ? 'card' : ''} p-2 mb-2`} style={{ display: 'block' }}>
        {!cardStyle ? (
          <label className="font-weight-bold ooredoo">{t('screens.admin.releaseInfo.scenarioVisibility')}</label>
        ) : (
          <h5 className="ooredoo">{t('screens.admin.releaseInfo.scenarioVisibility')}</h5>
        )}
        <InputBoolean
          fieldName="comingSoon"
          value={comingSoon}
          label={t('screens.admin.releaseInfo.comingSoonLabel')}
          help={t('screens.admin.releaseInfo.comingSoonHelp')}
          handleChange={handleChange}
        />
        <InputBoolean
          fieldName="externalSeller"
          value={externalSeller}
          label={t('screens.admin.releaseInfo.externalSellerLabel')}
          help={t('screens.admin.releaseInfo.externalSellerHelp')}
          handleChange={handleChange}
        />
        <InputBoolean
          fieldName="visible"
          value={visible}
          label={t('screens.admin.releaseInfo.visibleLabel')}
          help={t('screens.admin.releaseInfo.visibleHelp')}
          handleChange={handleChange}
        />
      </div>
      <div className={`${cardStyle ? 'card' : ''} p-2 mb-2`} style={{ display: 'block' }}>
        {!cardStyle ? (
          <label className="font-weight-bold ooredoo">
            {t('screens.headerEdition.identification.missionTypeLabel').toUpperCase()}
          </label>
        ) : (
          <h5 className="ooredoo">{t('screens.headerEdition.identification.missionTypeLabel').toUpperCase()}</h5>
        )}
        <SwitchMissionType
          nameSwitch={missionTypes.isOfficial}
          missionType={missionType}
          label={t('screens.headerEdition.identification.isOfficialLabel')}
          handleChange={(event) => handleMissionType(event, true)}
        />
        <SwitchMissionType
          nameSwitch={missionTypes.isCertifiedCreator}
          missionType={missionType}
          label={t('screens.headerEdition.identification.isCertifiedCreatorLabel')}
          handleChange={(event) => handleMissionType(event, false)}
        />
        <SwitchMissionType
          nameSwitch={missionTypes.isPrivate}
          missionType={missionType}
          label={t('screens.headerEdition.identification.isPrivateLabel')}
          handleChange={(event) => handleMissionType(event, false)}
        />
      </div>
      <div className={`${cardStyle ? 'card' : ''} p-2 mb-2 flex-fill`} style={{ display: 'block' }}>
        {!cardStyle ? (
          <label className="font-weight-bold ooredoo">{t('screens.admin.releaseInfo.scenarioPricing')}</label>
        ) : (
          <h5 className="ooredoo">{t('screens.admin.releaseInfo.scenarioPricing')}</h5>
        )}
        <InputBoolean
          fieldName="isFree"
          value={isFree}
          label={t('screens.admin.releaseInfo.isFreeLabel')}
          help={t('screens.admin.releaseInfo.isFreeHelp')}
          handleChange={handleChange}
        />
        <InputBoolean
          fieldName="expendable"
          value={expendable}
          label={t('screens.admin.releaseInfo.expendableLabel')}
          help={t('screens.admin.releaseInfo.expendableHelp')}
          handleChange={handleChange}
          hidden={isFree}
        />
        <InputSelect
          fieldName="preconfiguredProductId"
          value={preconfiguredProductId || ''}
          values={preconfiguredProducts}
          itemToId={(it) => it.id}
          itemToTitle={(it) => it.name}
          label={t('screens.admin.releaseInfo.preconfiguredProductIdLabel')}
          handleChange={handleChange}
          hidden={isFree || !expendable}
        />
        <InputString
          fieldName="iapSku"
          value={isFree ? '' : iapSku || `com.atlantide.gayaplayer.${scenarioId}`}
          label={t('screens.admin.releaseInfo.iapSkuLabel')}
          help={expendable ? undefined : t('screens.admin.releaseInfo.iapSkuHelp')}
          multiline={false}
          disabled={expendable}
          handleChange={handleChange}
          hidden={isFree}
        />
        <InputNumber
          fieldName="price"
          value={isFree || !price ? '' : price}
          label={t('screens.admin.releaseInfo.priceLabel')}
          help={t('screens.admin.releaseInfo.priceHelp')}
          disabled={expendable}
          handleChange={handleChange}
        />

        <InputDate
          fieldName="promoExpirationDate"
          label={t('screens.admin.releaseInfo.promoExpirationDateLabel')}
          help={t('screens.admin.releaseInfo.promoExpirationDateHelp')}
          value={isFree || !promoExpirationDate ? undefined : new Date(promoExpirationDate)}
          handleChangeTime={handleChange}
          disabled={isFree}
        />
      </div>
    </div>
  );
};

export default compose(withTranslation('default'))(VisibilityAndVendingChanger);
