/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'src/pages/components';
import { AmsServiceHelper } from 'src/store/ams';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { ConfigurationServiceHelper } from 'src/store/configuration';

const WithAmssContext: Object = React.createContext(null);

export default WithAmssContext;
const mapStateToProps = () => ({});

const mapDispatchToProps = {
  loadAmsItems: AmsServiceHelper.loadAMSFromFirebase,
  loadCities: ConfigurationServiceHelper.loadCitiesFromFirebase,
  umpdateAms: AmsServiceHelper.updateAms,
  deleteAms: AmsServiceHelper.removeAms,
};

export type WithAmssProps = {
  loadAmsItems: AmsServiceHelper.loadAMSFromFirebaseType,
  loadCities: ConfigurationServiceHelper.loadCitiesFromFirebaseType,
  umpdateAms: AmsServiceHelper.updateAmsType,
  deleteAms: AmsServiceHelper.removeAmsType,
  match: { params: { [key: string]: string } },
  firebase: Firebase,
};

export type WithAmssState = {
  isLoading: boolean,
};

const withAmss = (Component: React.AbstractComponent<any>) => {
  class WithAmss extends React.Component<WithAmssProps, WithAmssState> {
    state = {
      isLoading: false,
    };

    componentDidMount() {
      this.setState({ isLoading: true });
      const { loadAmsItems, loadCities, firebase } = this.props;
      loadAmsItems(firebase)
        .then(() => {
          loadCities(firebase).then(() => {
            this.setState({ isLoading: false });
          });
        })
        .catch((error) => {
          console.error('BOUM', error);
        });
    }

    render() {
      return (
        <WithAmssContext.Provider value={this.props.match}>
          <Component {...this.props} />
          {this.state.isLoading && <Loader />}
        </WithAmssContext.Provider>
      );
    }
  }

  return withFirebase(connect(mapStateToProps, mapDispatchToProps)(WithAmss));
};

export { withAmss };
