/**
 * @flow
 *
 * @format
 */
// eslint-disable-next-line max-classes-per-file
import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import VisibilitySensor from 'react-visibility-sensor';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import type { CE } from 'src/services/Firebase/FirebaseHelper/Admin';

import { TabContent } from '../components';
import ListEditor from '../components/ListEditor';
import EditCeView from './EditCeView';

type Props = {
  firebase: Firebase,
  updateCe: FirebaseHelper.updateCeType,
  t: (key: string) => string,
};

type State = {
  ces: CE[],
  isLoading: boolean,
};

class CEListEditor extends ListEditor<CE> {}

class CeTab extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    ces: [],
    isLoading: false,
  };

  onVisibilityChanged = (visible: boolean) => {
    if (visible) {
      this._reloadCes();
    }
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value });
  };

  _reloadCes = async () => {
    this.setState({ isLoading: true });
    try {
      const ces = await FirebaseHelper.reloadCEs();
      this.setState({ ces, isLoading: false });
    } catch (error) {
      console.warn('Cannot retrieve ces', error);
      this.setState({ isLoading: false });
    }
  };

  renderEdition = (itemId: string) => {
    const item = this.state.ces.find((it) => it.id === itemId);
    return <EditCeView item={item} />;
  };

  renderItem = (item: CE) => (
    <div className="d-flex justify-content-between">
      <h5 className="mb-1">{item.id}</h5>
      <small className="text-muted">{item.domains[0]}</small>
    </div>
  );

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { t } = this.props;
    return (
      <TabContent name="ce">
        <React.Fragment>
          <VisibilitySensor onChange={this.onVisibilityChanged} partialVisibility>
            <div className="card-header">
              <h3>{t('screens.admin.ce.sectionTitle')}</h3>
            </div>
          </VisibilitySensor>
          <CEListEditor
            renderItem={this.renderItem}
            renderEdition={this.renderEdition}
            refresh={this._reloadCes}
            items={this.state.ces}
            newLabel={t('general.new')}
          />
        </React.Fragment>
      </TabContent>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  updateCe: FirebaseHelper.updateCe,
};

export default compose(withFirebase, connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(CeTab);
