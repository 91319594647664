/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Claims } from 'src/constants/roles';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { withTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nodeStyle.css';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { itemTypeColorator } from './baseItem/BaseItemColorations';

export interface AddItemWidgetProps {
  type: string;
  label?: string;
  defaultContent?: any;
  disabled?: boolean;
  help?: string;
  draggable: boolean;
  t: (key: string[]) => string;
  isConfirmedEditor: boolean;
  isSidebarOpened: boolean;
  onClick?: (data: any) => any;
}

export interface AddItemWidgetState {
  model: any;
}

class AddItemWidget extends React.Component<AddItemWidgetProps, AddItemWidgetState> {
  static defaultProps = {
    draggable: true,
    isSidebarOpened: true,
  };

  constructor(props: AddItemWidgetProps) {
    super(props);
    const model = {
      type: this.props.type,
    };
    this.state = { model };
  }

  getId = () => {
    const res = `addItem${this.props.type || this.props.label}`;
    return res.replace(' ', '');
  };

  componentDidMount() {
    $('.trigger').tooltip({ position: 'center right', opacity: 0.7 });
  }

  showTootip = () => {
    $(`#${this.getId()}`).show(); // ('show');
  };

  hideTootip = () => {
    $(`#${this.getId()}`).tooltip('hide');
  };

  onClick = (event) => {
    event.preventDefault();
    if (!this.props.disabled) {
      if (this.props.onClick) {
        this.props.onClick(JSON.stringify(this.props.defaultContent || this.state.model));
      } else {
        const { t } = this.props;
        toast.warn(t('default:screens.scenarioEdition.graph.dragItemOnGraph'));
      }
    }
  };

  onDrag = (event) => {
    event.dataTransfer.setData('storm-diagram-node', JSON.stringify(this.props.defaultContent || this.state.model));
  };

  componentWillUnmount = () => {
    this.hideTootip();
  };

  render() {
    const { t, isConfirmedEditor, requireConfirmedEdition, isSidebarOpened } = this.props;
    const help = t(`helpStrings:scenario.itemTypes.${this.props.label || this.props.type}`, { returnObjects: true });
    if (requireConfirmedEdition && !isConfirmedEditor) {
      return null;
    }
    return (
      <div
        className={`${this.props.disabled ? 'widget-disabled' : ''} widget-button noselect w-100`}
        draggable={!this.props.disabled && this.props.draggable}
        disabled={this.props.disabled}
        id={this.getId()}
        onDragStart={this.props.draggable ? this.onDrag : undefined}
        data-toggle="tooltip"
        data-html={true}
        data-placement="top"
        title={
          help
            ? help.content
            : '' /* `<p><b>${this.props.label || this.props.type}</b><br/>${help ? help.content : ''}</p>` */
        }
        onMouseEnter={this.showTootip}
        onMouseLeave={this.hideTootip}
        onMouseOut={this.props.disabled && this.hideTootip}
        onClick={this.onClick}
        style={{ backgroundColor: this.props.itemColor, padding: isSidebarOpened && '10px', borderRadius: '5px' }}
      >
        <span
          className={`${this.props.type}_graph_title icon`}
          style={{ width: isSidebarOpened ? '20%' : '100%', textAlign: 'center' }}
        />
        {isSidebarOpened && <label className="ooredoo">{this.props.label || this.props.type}</label>}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const isConfirmedEditor =
    ownProps.validClaims.includes(Claims.Admin) || ownProps.validClaims.includes(Claims.Moderator);
  const requireConfirmedEdition = state.configuration.restrictedItems.includes(ownProps.type);
  const itemColor = itemTypeColorator(ownProps);

  return {
    isConfirmedEditor,
    requireConfirmedEdition,
    itemColor,
  };
};

const mapDispatchToProps = {};

export default compose(
  withTranslation('helpStrings'),
  withAuthorization(AuthenticatedCondition, [Claims.Editor, Claims.Moderator, Claims.Admin]),
  connect(mapStateToProps, mapDispatchToProps),
)(AddItemWidget);
