/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { InputJSONArray, InputNumber, InputString } from 'src/pages/components';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import type { CE } from 'src/services/Firebase/FirebaseHelper/Admin';

type Props = {
  firebase: Firebase,
  updateCe: FirebaseHelper.updateCeType,
  item: CE,
  t: (key: string) => string,
};

type State = {
  isLoading: boolean,
  isValid: boolean,
  discount: number,
  domains: string,
  id: string,
  isNew: boolean,
};

class EditCEView extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    isLoading: false,
    isValid: false,
    discount: 0,
    domains: '[]',
    id: '',
    isNew: false,
  };

  componentDidMount = () => {
    this.setItem(this.props.item);
  };

  componentDidUpdate = (oldProps) => {
    if (this.props.item !== oldProps.item) this.setItem(this.props.item);
  };

  setItem = (item: CE) => {
    this.setState(
      {
        domains: JSON.stringify(item.domains),
        discount: item.discount,
        id: item.id,
      },
      () => {
        this.updateValidity(this.state);
      },
    );
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value }, () => this.updateValidity(this.state));
  };

  updateValidity = (newVal: State) => {
    // eslint-disable-next-line no-unused-vars
    let isValid: boolean =
      !!newVal.discount && newVal.discount > 0 && newVal.discount <= 1 && !!newVal.domains && !!newVal.id;
    try {
      const arr = JSON.parse(newVal.domains);
      if (!(Array.isArray(arr) && arr.length)) {
        isValid = false;
      }
    } catch (error) {
      console.debug(error);
      isValid = false;
    }
    this.setState({ isValid: false }); // TODO: Enable once update method would be implemented
  };

  _updateCe = async () => {
    const { id, domains, discount } = this.state;
    const { firebase, updateCe } = this.props;
    if (id) {
      await updateCe(id, JSON.parse(domains), discount, firebase);
      this.setState({
        id: '',
        domains: '[]',
        discount: 0,
      });
    }
  };

  render() {
    const { discount, domains, id, isValid, isNew } = this.state;
    const { t } = this.props;
    return (
      <div className="card p-2 mb-2" style={{ display: 'block' }}>
        <InputString
          fieldName="id"
          value={id}
          label={t('screens.admin.ce.idLabel')}
          handleChange={this.handleChange}
          disabled={!isNew}
        />
        <InputJSONArray
          className="pb-2"
          fieldName="domains"
          value={domains}
          label={t('screens.admin.ce.domainLabel')}
          handleChange={this.handleChange}
          prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
        />
        <InputNumber
          fieldName="discount"
          value={discount}
          label={t('screens.admin.ce.discountLabel')}
          handleChange={this.handleChange}
        />
        <div style={{ minHeight: 30 }}>
          <button
            className="btn btn-outline-secondary"
            type="button"
            id="button-save"
            onClick={this._updateCe}
            disabled={!isValid}
          >
            {t('general.save')}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  updateCe: FirebaseHelper.updateCe,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(EditCEView);
