/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { DiscussionServiceHelper } from 'src/store/scenario/items';
import { Message, Answer, AnswerOpened } from 'src/data/discussions';
import LocalizedString from 'src/data/LocalizedString';
import errors from 'src/assets/errors';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';

import Loader from 'src/pages/components/loader';
import InputString from 'src/pages/components/inputs/InputString';
import './DiscussionGraphView.css';
import InputLocalizedFile from 'src/pages/components/inputs/InputLocalizedFile';
import LocalizedFile from 'src/data/LocalizedFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { itemTypeColorator } from 'src/pages/scenario/components/graph/baseItem/BaseItemColorations';
import AddItemWidget from '../../../components/graph/AddItemWidget';
import { InputSelect } from '../../../../components/inputs';
import { ItemNodeTypes } from '../../../components/graph';
import { ItemTypes } from '../../../../../data/BaseItem';

export type MessageInputProps = {
  nodeId: string,
  npcs: NPC[],
  messageId: string,
  discussionId: string,
  prefix: string,
  message: Message,
  canChangeId: boolean,
  contentTypes: string[],
  answerModes: string[],
  nextModes: string[],
  items: string[],
  updateMessage: DiscussionServiceHelper.updateMessageType,
  locale: string,
  firebase: Firebase,
  scenarioId: string,
  numberOfMessages: number,
  acceptMessages: boolean,
  acceptAnswers: boolean,
  acceptAnswersOp: boolean,
  addNotif: EventsServiceHelper.addNotifType,
  startEditing: () => any,
  isEditingItem: boolean,
  onAddBellow: (data: any, type: string, avoidDx: boolean) => any,
  t: (key: string) => string,
  itemColor: string,
  setDisplayModal: (displayModal: boolean) => void,
};

type State = {
  isValid?: boolean,
  npcId: ?string,
  idSuffix: string,
  content: ?string,
  answers: Answer[],
  answersOpened: AnswerOpened[],
  contentType: string,
  answerMode?: string,
  hasChanges: boolean,
  isSaved?: boolean,
  nextMode?: string,
  contentImage: ?LocalizedFile,
  contentItemId: ?string,
  filesToRemove: any[],
  isLoading: boolean,
  preventAutoSaveUnmount: boolean,
};

const SortableItem = SortableElement(({ value, locale, contentKey, transform }) => {
  const displayValue = transform ? transform(value) : value[contentKey].valueForLocale(locale, true);
  return (
    <div
      className={`d-flex justify-content-between d-flex list-group-item list-group-item-action ${
        value.isDefault ? 'text-muted' : ''
      }`}
    >
      <label className="mb-1">{displayValue}</label>
    </div>
  );
});

const SortableList = SortableContainer(({ items, locale, contentKey, transform }) => (
  <div className="list-group d-flex container-fluid">
    {items.map((answer, index) => (
      <SortableItem
        key={`item-${index}`}
        index={index}
        value={answer}
        contentKey={contentKey}
        transform={transform}
        locale={locale}
        disabled={answer.isDefault}
      />
    ))}
  </div>
));

class MessageInput extends React.PureComponent<MessageInputProps, State> {
  static defaultProps = {
    canChangeId: true,
    contentTypes: [],
    message: {},
  };

  state = {
    npcId: undefined,
    isValid: false,
    isSaved: true,
    idSuffix: '',
    content: '',
    contentImage: undefined,
    contentItemId: undefined,
    answers: [],
    answersOpened: [],
    contentType: 'Text',
    answerMode: undefined,
    hasChanges: false,
    filesToRemove: [],
    isLoading: false,
    nextMode: undefined,
    preventAutoSaveUnmount: false,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.nodeId !== this.props.nodeId) {
      this.warnSaveIfNeeded(oldProps, oldState);
    }
    try {
      if (oldProps.messageId !== this.props.messageId || oldProps.nodeId !== this.props.nodeId) {
        this.setItemData(this.props);
      }
      if (oldState.answersOpened !== this.state.answersOpened && this.state.answersOpened.length) {
        // We need to have the default value at the end
        this.setState({
          answersOpened: this.state.answersOpened.sort((a, b) => Number(a.isDefault || 0) - Number(b.isDefault || 0)),
        });
      }
    } catch (error) {
      console.debug(error);
    }
  }

  componentWillUnmount() {
    if (!this.state.preventAutoSaveUnmount) {
      this.warnSaveIfNeeded();
    }
  }

  warnSaveIfNeeded = async (
    oldProps?: MessageInputProps = this.props,
    state: State = this.state,
    needNextMessageId?: boolean = false,
  ) => {
    if (this.state.hasChanges && this.props.scenarioId) {
      const updateData = this.getDataToSave(oldProps, state);
      const { isValid } = state;
      if (isValid && oldProps.scenarioId) {
        if (needNextMessageId) {
          const splittedId = this.props.message.id.split('_');
          splittedId[splittedId.length - 1] = this.props.numberOfMessages + 1;
          const nextId = splittedId.join('_');
          updateData.newMessage.nextMessageId = nextId;
        }
        await this.updateWithData(updateData, false, false);
      } else {
        this.props.addNotif(
          NotificationTypes.ERROR,
          !isValid ? 'E_UNSAVED_ITEM_INVALID' : 'E_UNSAVED_ITEM',
          undefined,
          0,
        );
      }
    }
  };

  setItemData = (props: MessageInputProps) => {
    const { message, prefix, locale, contentTypes } = props;
    if (message) {
      const {
        id,
        npcId,
        contentType,
        content,
        contentImage,
        contentItemId,
        answerMode,
        answers,
        nextMode,
        answersOpened,
      } = message;
      const idSuffix = id ? id.slice(prefix.length) : '';
      const newState: State = {
        idSuffix: idSuffix || '',
        npcId: npcId || '',
        contentType: contentType || (contentTypes && contentTypes[0]) || 'Text',
        content: content && content.valueForLocale(locale),
        contentImage,
        contentItemId: contentItemId || '',
        answerMode: answerMode || '',
        answers: answers || [],
        answersOpened: answersOpened || [],
        nextMode: nextMode || '',
        hasChanges: false,
        filesToRemove: [],
        isLoading: false,
      };
      this.setState(newState);
      this.updateValidity(newState);
    }
  };

  onSortAnswersOpened = ({ oldIndex, newIndex }) => {
    this.setState({
      answersOpened: arrayMove(this.state.answersOpened, oldIndex, newIndex),
      hasChanges: true,
    });
  };

  onSortAnswers = ({ oldIndex, newIndex }) => {
    this.setState({
      answers: arrayMove(this.state.answers, oldIndex, newIndex),
      hasChanges: true,
    });
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateValidity = (newVal: State) => {
    let isValid: boolean = true;
    const { idSuffix, contentType, content, nextMode, npcId } = newVal;
    isValid = !!idSuffix && !!idSuffix.length && !!contentType && !!content && !!npcId && npcId.length;

    const { nextMessageId } = this.props.message;

    switch (nextMode) {
      case 'Continue':
      case undefined:
      case 'Retry':
        isValid = isValid && !!nextMessageId;
        break;
      case 'Exit':
      case 'Complete':
        isValid = isValid && !nextMessageId;
        break;
      default:
        break;
    }
    this.setState({ isValid });
  };

  getDataToSave = (props?: MessageInputProps = this.props, state?: State = this.state) => {
    const {
      idSuffix,
      npcId,
      contentType,
      content,
      contentImage,
      contentItemId,
      answerMode,
      answers,
      answersOpened,
      nextMode,
    } = state;

    const { updateMessage, message, prefix, discussionId, messageId, locale, scenarioId, firebase } = props;
    const id = prefix + idSuffix;
    const contentTranslated = message.content && new LocalizedString(message.content.id, message.content);
    if (contentTranslated && content) {
      contentTranslated.setValueForLocale(content, locale);
    }
    const newMessage = new Message(message);
    newMessage.id = id;
    newMessage.contentType = contentType;
    newMessage.content = contentTranslated;
    newMessage.npcId = npcId;
    if (newMessage.content && contentType !== 'Text') {
      newMessage.content.flush();
      this.setState({ content: '' });
    }
    newMessage.contentImage = contentImage;
    newMessage.contentItemId = contentType === 'Item' ? contentItemId : undefined;
    newMessage.answerMode = answerMode;
    newMessage.answers = answers;
    newMessage.nextMode = nextMode;
    newMessage.answersOpened = answersOpened;

    let { filesToRemove } = state;

    if (contentType !== 'Image' && newMessage.contentImage) {
      filesToRemove = [...filesToRemove, ...newMessage.contentImage.listStorageFiles()];
      newMessage.contentImage.flush();
    }
    return {
      newMessage,
      updateMessage,
      discussionId,
      messageId,
      scenarioId,
      id,
      firebase,
      filesToRemove,
    };
  };

  updateWithData = async (updateData, notifyUi: boolean = false, sendNotif?: boolean = true) => {
    const { updateMessage, discussionId, messageId, scenarioId, id, firebase, newMessage, filesToRemove } = updateData;
    if (updateMessage) {
      if (filesToRemove && filesToRemove.length) {
        await FirebaseHelper.removeEditorFilesAsync(scenarioId, filesToRemove, 'scenario', firebase);
      }
      if (notifyUi) {
        this.setState({ isLoading: true });
      }
      await updateMessage(discussionId, messageId || id, newMessage, scenarioId, firebase, sendNotif);
      if (notifyUi) {
        this.setState({ hasChanges: false, isLoading: false });
      }
    }
  };

  updateMessage = () => {
    const updateData = this.getDataToSave();
    this.updateWithData(updateData, true);
  };

  getErrors = () => {
    const { answersOpened, isSaved } = this.state;
    const res = [];
    if (answersOpened.length) {
      let defaultCount = 0;
      answersOpened.forEach((ans) => {
        if (defaultCount > 0) {
          res.push(errors.message_answer_default_order);
        }
        if (ans.isDefault) {
          defaultCount += 1;
        }
      });
      if (defaultCount === 0) {
        res.push(errors.message_answer_default_missing);
      } else if (defaultCount > 1) {
        res.push(errors.message_answer_default_duplicated);
      }
    }
    if (!isSaved) {
      // res.push(errors.item_not_saved);
    }
    return res;
  };

  handleFileSelected = (fieldName, locale, file) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.message.id, undefined, oldFile);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    if (!newFile.hasLocale(locale)) {
      newFile.addLocale(locale);
    }
    newFile.files[locale].contentToUpload = file;

    newFile.files[locale].name = file.name;
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  handleContentChange = (fieldName: string, value: string) => {
    const { locale } = this.props;
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.message.id, undefined, oldFile);
    newFile.content.values[locale] = value;
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  addFileLocale = (fieldName, locale) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.message.id, undefined, oldFile);
    newFile.addLocale(locale);
    const newField = newFile;
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  removeFileLocale = (fieldName, locale) => {
    const field = this.state[fieldName];
    const oldFile = field;
    const newFile = new LocalizedFile(this.props.message.id, undefined, oldFile);
    const itemFilesToRemove = newFile.listStorageFiles(locale);
    newFile.removeLocale(locale);
    const newField = newFile;

    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  addBellow = async (dataStr: string) => {
    this.setState({ preventAutoSaveUnmount: true });
    const data = JSON.parse(dataStr);
    const addNewMessage = data.type === 'message';
    await this.warnSaveIfNeeded(this.props, this.state, addNewMessage);
    this.props.onAddBellow(this.props.message, data.type, addNewMessage);
  };

  render() {
    const { contentTypes, answerModes, message, locale, items, nextModes, t, npcs } = this.props;
    const {
      idSuffix,
      contentType,
      content,
      contentImage,
      contentItemId,
      answerMode,
      npcId,
      isValid,
      answers,
      answersOpened,
      nextMode,
      isLoading,
    } = this.state;
    const errorMessages = this.getErrors();

    return (
      <div
        className="card bg-light screenBlock pb-2"
        style={{ height: '100%', overflow: 'hidden', borderRadius: '10px', backgroundColor: 'white', border: 'none' }}
      >
        <div
          className="card-header"
          ref={(node) => {
            if (node) {
              node.style.setProperty('background-color', this.props.itemColor, 'important');
            }
          }}
          style={{ border: 'none', height: '65px', alignItems: 'center' }}
        >
          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={this.updateMessage}
            disabled={!isValid}
            style={{ float: 'left', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'save']} />
          </div>

          <h3
            className="message_graph_title text-capitalize padding-before h-100 d-flex align-items-center"
            style={{ display: 'inline-block', maxWidth: '80%' }}
          >
            {t('screens.discussionEdition.messageEdition.sectionTitle')}
          </h3>

          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={() => this.props.setDisplayModal(false)}
            disabled={!isValid}
            style={{ float: 'right', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
        </div>
        {errorMessages.length ? (
          <div>
            {errorMessages.map((mess) => (
              <div className={`alert mb-0 ${mess.class}`} key={mess.key} role="alert">
                {t(mess.text)}
              </div>
            ))}
          </div>
        ) : (
          undefined
        )}
        {message && message instanceof Message && (
          <div
            className="pr-3 pl-3 pt-3 pb-3"
            style={{ height: '100%', overflowY: 'scroll', backgroundColor: 'white' }}
          >
            <InputString
              fieldName="idSuffix"
              onFocus={this.onFieldFocus}
              value={idSuffix}
              label={t('screens.discussionEdition.messageEdition.idLabel')}
              help={t('screens.discussionEdition.messageEdition.idPlaceholder')}
              handleChange={this.handleChange}
              disabled={!!message.id}
              inputStyle={{ border: 'none' }}
            />
            <InputSelect
              fieldName="npcId"
              onFocus={this.onFieldFocus}
              value={npcId}
              values={npcs}
              itemToId={(it) => it.id}
              itemToTitle={(it) => it.name.valueForLocale(locale)}
              label={t('screens.discussionEdition.messageEdition.npcIdLabel')}
              handleChange={this.handleChange}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputSelect
              fieldName="contentType"
              onFocus={this.onFieldFocus}
              value={contentType}
              values={contentTypes}
              itemToId={(it) => it}
              itemToTitle={(it) => it}
              label={t('screens.discussionEdition.messageEdition.contentTypeLabel')}
              handleChange={this.handleChange}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputString
              fieldName="content"
              onFocus={this.onFieldFocus}
              value={content || ''}
              label={t('screens.discussionEdition.messageEdition.contentLabel')}
              handleChange={this.handleChange}
              multiline={true}
              hidden={contentType !== 'Text'}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputLocalizedFile
              fieldName="contentImage"
              onFocus={this.onFieldFocus}
              value={contentImage}
              label={t('screens.discussionEdition.messageEdition.contentLabel')}
              handleChange={this.handleChange}
              hidden={contentType !== 'Image'}
              handleFileSelected={this.handleFileSelected}
              handleContentChange={this.handleContentChange}
              addFileLocale={this.addFileLocale}
              removeFileLocale={this.removeFileLocale}
              contentLocale={locale}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputSelect
              fieldName="contentItemId"
              onFocus={this.onFieldFocus}
              value={contentItemId}
              values={items}
              itemToId={(it) => it}
              itemToTitle={(it) => it}
              label={t('screens.discussionEdition.messageEdition.nextCustomLabel')}
              handleChange={this.handleChange}
              hidden={contentType !== 'Item'}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputSelect
              fieldName="answerMode"
              onFocus={this.onFieldFocus}
              value={answerMode}
              values={answerModes}
              itemToId={(it) => it}
              itemToTitle={(it) => {
                return t(`screens.discussionEdition.messageEdition.answerMode.${it.toLowerCase()}`);
              }}
              label={t('screens.discussionEdition.messageEdition.answerModeLabel')}
              handleChange={this.handleChange}
              hidden={!message || message.nextMessageId}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputSelect
              fieldName="nextMode"
              onFocus={this.onFieldFocus}
              value={nextMode}
              values={nextModes}
              itemToId={(it) => it}
              itemToTitle={(it) => {
                return t(`screens.discussionEdition.messageEdition.nextmode.${it.toLowerCase()}`);
              }}
              label={t('screens.discussionEdition.messageEdition.nextModeLabel')}
              handleChange={this.handleChange}
              help={t('screens.discussionEdition.messageEdition.nextModeHelp')}
              hidden={answersOpened.length !== 0 || answers.length !== 0}
              helpInfos={[
                {
                  content: t('helpStrings:scenario.discussion.input.messageNextModes', { returnObjects: true }),
                  title: 'nextMode',
                },
              ]}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />

            <div className="mb-3" hidden={answers.length === 0}>
              <h5 className="mb-0 ooredoo">{t('screens.discussionEdition.messageEdition.answers')}</h5>
              <span className="blockquote-footer mb1">{t('screens.discussionEdition.messageEdition.dragToOrder')}</span>
              <SortableList items={answers} locale={locale} onSortEnd={this.onSortAnswers} contentKey={'content'} />
            </div>
            <div className="mb-3" hidden={answersOpened.length === 0}>
              <h5 className="mb-0 ooredoo">{t('screens.discussionEdition.messageEdition.answersOpened')}</h5>
              <span className="blockquote-footer mb1">
                {t('screens.discussionEdition.messageEdition.dragToOrderOpened')}
              </span>
              <SortableList
                items={answersOpened}
                locale={locale}
                onSortEnd={this.onSortAnswersOpened}
                contentKey={'contentsRecognized'}
                transform={(item) => {
                  if (item.isDefault) {
                    return ['default', 'helpStrings'];
                  }
                  const val = item.contentsRecognized.valueForLocale(locale, true);
                  return val ? val.join(', ') : 'Undefined';
                }}
              />
            </div>
            <div className="form-group">
              <label className="strong ooredoo">{t('screens.discussionEdition.messageEdition.fastNext')}</label>
              <span className={`${!this.props.acceptMessages ? 'fast-action-disabled' : ''} fast-action bg-white m-0`}>
                <AddItemWidget
                  type={ItemNodeTypes.Message}
                  draggable={false}
                  onClick={this.addBellow}
                  disabled={!this.props.acceptMessages}
                  label={this.props.t('screens.discussionEdition.menu.npc_message')}
                />
              </span>
              <span className={`${!this.props.acceptAnswers ? 'fast-action-disabled' : ''} fast-action bg-white m-0`}>
                <AddItemWidget
                  type={ItemNodeTypes.Answer}
                  draggable={false}
                  onClick={this.addBellow}
                  disabled={!this.props.acceptAnswers}
                  label={this.props.t('screens.discussionEdition.menu.player_answer')}
                />
              </span>
              <span className={`${!this.props.acceptAnswersOp ? 'fast-action-disabled' : ''} fast-action bg-white m-0`}>
                <AddItemWidget
                  type={ItemNodeTypes.AnswerOpened}
                  draggable={false}
                  onClick={this.addBellow}
                  disabled={!this.props.acceptAnswersOp}
                  label={this.props.t('screens.discussionEdition.menu.player_open_answer')}
                />
              </span>
              <span className="fast-action bg-white m-0">
                <AddItemWidget
                  type={ItemNodeTypes.Trigger}
                  draggable={false}
                  onClick={this.addBellow}
                  label={this.props.t('screens.discussionEdition.menu.trigger')}
                />
              </span>
            </div>
          </div>
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId, messageId, nodeId } = ownProps;
  const discussion = state.scenario.items[discussionId];
  let message;
  let numberOfMessages;
  if (discussion) {
    numberOfMessages = Object.values(discussion.messages).length;
    if (!messageId) {
      message = discussion.__detachedNodes.messages.find((mess) => mess.nodeId === nodeId);
      if (!message) {
        // $FlowFixMe Object.values
        message = Object.values(discussion.messages).find((it) => it.nodeId === nodeId);
      }
    } else {
      message = discussion.messages[messageId];
    }
  }
  const hasAnswers = message && message.answers && message.answers.length;
  const hasAnswersOp = message && message.answersOpened && message.answersOpened.length;
  const acceptMessages = message && !hasAnswers && !hasAnswersOp && !message.nextMessageId;
  const acceptAnswers = message && !hasAnswersOp && !message.nextMessageId;
  const acceptAnswersOp = message && !hasAnswers && !message.nextMessageId;

  const allNpcs = [...state.scenario.npcs.npcs, ...state.configuration.globalNpcs];
  const validItemTypeKeys = Object.values(state.scenario.items)
    .filter((val) => val.id && val.type === ItemTypes.Document)
    .map((it) => it.id);

  const itemColor = itemTypeColorator(message);

  return {
    scenarioId: state.scenario.header.id,
    locale: state.preferences.editionLocale,
    contentTypes: state.configuration.contentTypes,
    answerModes: state.configuration.answerModes,
    nextModes: state.configuration.nextModes,
    acceptMessages,
    acceptAnswers,
    acceptAnswersOp,
    message,
    items: validItemTypeKeys,
    prefix: `${discussionId}_`,
    npcs: allNpcs.filter((it) => discussion.npcIds && discussion.npcIds.includes(it.id)),
    itemColor,
    numberOfMessages,
  };
};

const mapDispatchToProps = {
  updateMessage: DiscussionServiceHelper.updateMessageAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['default', 'helpStrings']),
)(MessageInput);
