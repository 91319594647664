/**
 * @flow
 * @format
 */
import * as React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import InputJSONArray from './InputJSONArray';
import HelpButton from '../HelpButton';

type InputMultipleJSONArrayProps = {
  className: string,
  style?: any,
  fieldName: string,
  value?: ?string,
  label?: string,
  help?: ?string,
  separatorBefore?: boolean,
  disabled?: boolean,
  hidden?: boolean,
  handleChange?: (any) => any,
  helpInfos: any,
  labelSmall: boolean,
  suffix?: ?string,
  inlineTitle?: boolean,
  className?: string,
  onFocus?: () => any,
  onClick?: () => any,
  withoutBrackets: boolean,
  isCoordinates: boolean,
  isNumber: boolean,
  t: (key: string) => string,
  inputStyle?: ?any,
};

const InputMultipleJSONArray = ({
  className,
  style,
  fieldName,
  value,
  label,
  help,
  separatorBefore = false,
  disabled = false,
  hidden = false,
  handleChange,
  helpInfos = undefined,
  inlineTitle,
  onFocus,
  onClick,
  isCoordinates = false,
  isNumber = false,
  t,
  inputStyle = {},
}: InputMultipleJSONArrayProps) => {
  const [formattedValue, setFormattedValue] = React.useState<Array<Array<string>>>([[]]);

  /**
   * Format the event for the parent to handle
   */
  const eventForParent = (id: string, value: string) => {
    return {
      target: {
        id,
        value,
      },
    };
  };

  const handleChangeFormat = (event, index) => {
    const { value } = event.target;
    const newFormattedValue = [...formattedValue];
    try {
      newFormattedValue[index] = JSON.parse(value);
    } catch (err) {
      newFormattedValue[index] = [''];
    }
    setFormattedValue(newFormattedValue);
    handleChange(eventForParent(fieldName, JSON.stringify(newFormattedValue)));
  };

  const deleteValue = (index) => {
    const newFormattedValue = [...formattedValue];
    if (newFormattedValue.length === 1) {
      newFormattedValue[0] = [''];
    } else {
      newFormattedValue.splice(index, 1);
    }
    setFormattedValue(newFormattedValue);
    handleChange(eventForParent(fieldName, JSON.stringify(newFormattedValue)));
  };

  const addNewValue = () => {
    const newFormattedValue = [...formattedValue];
    newFormattedValue.push(['']);
    setFormattedValue(newFormattedValue);
    handleChange(eventForParent(fieldName, JSON.stringify(newFormattedValue)));
  };

  const changeOrder = (index: number, goUp: boolean) => {
    const otherIndex = index + (goUp ? 1 : -1);
    const newFormattedValue = [...formattedValue];
    const switchSave = newFormattedValue[index];
    newFormattedValue[index] = newFormattedValue[otherIndex];
    newFormattedValue[otherIndex] = switchSave;
    setFormattedValue(newFormattedValue);
    handleChange(eventForParent(fieldName, JSON.stringify(newFormattedValue)));
  };

  const formatValueToStates = (jsonString: string) => {
    try {
      const arrayedValue = JSON.parse(jsonString);
      const { length } = arrayedValue;
      if (length === 0) {
        arrayedValue.push(['']);
      }
      setFormattedValue(arrayedValue);
    } catch (err) {
      setFormattedValue([['']]);
    }
  };

  React.useEffect(() => {
    formatValueToStates(value);
  }, [value]);

  return (
    <div className={`w-100 ${className || ''} pb-2`} key={fieldName} hidden={hidden} style={style} onClick={onClick}>
      {separatorBefore && <hr />}
      {(label || helpInfos) && (
        <div className="form-group">
          <label
            className={inlineTitle ? 'input-group-prepend mr-2 ooredoo' : 'strong text-capitalize ooredoo'}
            htmlFor={fieldName}
          >
            {`${label} `}
            {helpInfos &&
              Array.isArray(helpInfos) &&
              helpInfos.map((helpInfo, index) => (
                <HelpButton
                  key={fieldName + index}
                  helpStrings={helpInfo.content}
                  id={fieldName}
                  title={helpInfo.title}
                  label={helpInfo.btnLabel}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning ml-2'}
                />
              ))}
          </label>
        </div>
      )}
      {formattedValue.map((valueFormatted, index) => (
        <div className="card pt-0 mb-2" key={`${fieldName}multiple-json${index}`}>
          <div className="card-body mt-0">
            <div className="row justify-content-end">
              <button
                type="button"
                className="btn btn-outline-secondary col-3 block btn-sm mr-1"
                onClick={() => changeOrder(index, false)}
                disabled={index === 0}
              >
                &uarr;
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary col-3 block btn-sm"
                onClick={() => changeOrder(index, true)}
                disabled={index === formattedValue.length - 1}
              >
                &darr;
              </button>
            </div>
            <InputJSONArray
              fieldName={`coord-multiple-json-${index}`}
              onFocus={onFocus}
              disabled={disabled}
              hidden={hidden}
              value={JSON.stringify(valueFormatted)}
              label={`${t('screens.scenarioEdition.baseItemEdition.coordLabel')} ${index + 1}`}
              handleChange={(event) => handleChangeFormat(event, index)}
              isCoordinates={isCoordinates}
              isNumber={isNumber}
              inputStyle={inputStyle}
              prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
            />
            <button type="button" className="btn btn-primary delete" onClick={() => deleteValue(index)}>
              {t('general.delete')}
            </button>
          </div>
        </div>
      ))}
      <button className="btn btn-outline-secondary btn-block" type="button" onClick={() => addNewValue()}>
        {t('general.add')}
      </button>
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );
};

export default compose(withTranslation('default'))(InputMultipleJSONArray);
