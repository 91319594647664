/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import VisibilitySensor from 'react-visibility-sensor';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { ConfigurationServiceHelper } from 'src/store/configuration';
import Loader from 'src/pages/components/loader';

import { TabContent } from '../components';
import CheckConfig from './CheckConfig';

type Props = {
  firebase: Firebase,
  getARVAccessStatus: ConfigurationServiceHelper.getARVAccessStatusType,
  setARVAccessStatus: ConfigurationServiceHelper.setARVAccessStatusType,
  getWelcomeOfferStatus: ConfigurationServiceHelper.getWelcomeOfferStatusType,
  setWelcomeOffer: ConfigurationServiceHelper.setWelcomeOfferType,
  t: (key: string) => string,
};

const AppConfigTab = ({
  firebase,
  getARVAccessStatus,
  setARVAccessStatus,
  getWelcomeOfferStatus,
  setWelcomeOffer,
  t,
}: Props) => {
  const [arvEnableAccess, setArvEnableAccess] = React.useState<boolean>(false);
  const [welcomeEnableAccess, setWelcomeEnableAccess] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // Retrieve the ARV/welcome status
  const refreshAccessStatus = async () => {
    setIsLoading(true);
    const enableARVAccess = await getARVAccessStatus(firebase);
    const enableWelcomeOffer = await getWelcomeOfferStatus(firebase);
    setArvEnableAccess(!!enableARVAccess);
    setWelcomeEnableAccess(!!enableWelcomeOffer);
    setIsLoading(false);
  };

  React.useEffect(() => {
    refreshAccessStatus();
  }, []);

  return (
    <TabContent name="appConfig">
      <VisibilitySensor partialVisibility>
        <div className="card-header">
          <h3>{t('screens.admin.appConfig.sectionTitle')}</h3>
        </div>
      </VisibilitySensor>
      <div className="card bg-light screenBlock pl-5 pr-5 pt-2 mb-2">
        <CheckConfig
          value={arvEnableAccess}
          idCheck="arv"
          valueTitle={t('screens.admin.appConfig.arv.accessARVTitle')}
          labelDisabled={t('screens.admin.appConfig.arv.accessDisabled')}
          labelEnabled={t('screens.admin.appConfig.arv.accessEnabled')}
          setValue={setArvEnableAccess}
          setFirebaseValue={setARVAccessStatus}
          setIsLoading={setIsLoading}
        />
        <CheckConfig
          value={welcomeEnableAccess}
          idCheck="welcome"
          valueTitle={t('screens.admin.appConfig.welcome.accessWelcomeTitle')}
          labelDisabled={t('screens.admin.appConfig.welcome.accessDisabled')}
          labelEnabled={t('screens.admin.appConfig.welcome.accessEnabled')}
          setValue={setWelcomeEnableAccess}
          setFirebaseValue={setWelcomeOffer}
          setIsLoading={setIsLoading}
        />
      </div>
      {isLoading && <Loader />}
    </TabContent>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getARVAccessStatus: ConfigurationServiceHelper.getARVAccessStatus,
  setARVAccessStatus: ConfigurationServiceHelper.setARVAccessStatus,
  getWelcomeOfferStatus: ConfigurationServiceHelper.getWelcomeOfferStatus,
  setWelcomeOffer: ConfigurationServiceHelper.setWelcomeOffer,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(AppConfigTab);
