/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import MultiSelect from 'react-multi-select-component';
import HelpButton from '../HelpButton';

export interface InputMultipleSelectProps {
  fieldName: string;
  value: string;
  values: string[];
  label: string;
  help?: ?string;
  disabled?: boolean;
  disabledSearch?: boolean;
  hidden?: boolean;
  separatorBefore?: boolean;
  itemToId?: (any) => string;
  itemToTitle?: (any) => string;
  handleChange: (any) => any;
  helpInfos: any;
  labelSmall: boolean;
  onFocus?: () => any;
  inline?: boolean;
  maxOptions?: number;
}

export default class InputMultipleSelect extends React.Component<InputMultipleSelectProps> {
  static defaultProps = {
    disabled: false,
    disabledSearch: false,
    hidden: false,
    helpInfos: undefined,
    labelSmall: false,
    separatorBefore: false,
    itemToId: (it: string) => it,
    itemToTitle: (it: string) => it,
    inline: false,
  };

  onChange = (event) => {
    const { value } = this.props;
    const fieldName = event.target.id;

    let newVal = [...value];
    if (newVal.includes(fieldName)) {
      newVal = newVal.filter((it) => it !== fieldName);
    } else {
      newVal.push(fieldName);
    }
    this.props.handleChange({
      target: {
        id: this.props.fieldName,
        value: newVal,
      },
    });
  };

  setSelected = (value: any) => {
    this.props.handleChange({
      target: {
        id: this.props.fieldName,
        value: value.map((it) => it.value),
      },
    });
  };

  render() {
    const {
      fieldName,
      value,
      values,
      label,
      help,
      disabled,
      hidden,
      helpInfos,
      separatorBefore,
      itemToId,
      itemToTitle,
      labelSmall,
      inline,
      disabledSearch,
      maxOptions,
    } = this.props;
    return (
      <div className="form-group" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        {(label || helpInfos) && (
          <label htmlFor={fieldName} className={inline && 'd-inline-block mr-2 strong text-capitalize ooredoo '}>
            {labelSmall ? <small>{label}</small> : label}{' '}
            {helpInfos &&
              helpInfos.map((helpInfo, index) => (
                <HelpButton
                  key={fieldName + index}
                  helpStrings={helpInfo.content}
                  id={fieldName}
                  title={helpInfo.title}
                  label={helpInfo.btnLabel}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning ml-2'}
                />
              ))}
          </label>
        )}

        <MultiSelect
          options={values.map((val) => ({
            value: itemToId(val),
            label: itemToTitle(val),
            disabled: maxOptions && value.length >= maxOptions && !value.includes(val),
          }))}
          focusSearchOnOpen={false}
          hasSelectAll={false}
          value={
            values &&
            (value || []).map((val) => {
              const item = values.find((it) => itemToId(it) === val);
              return {
                value: itemToId(item),
                label: itemToTitle(item),
              };
            })
          }
          onChange={this.setSelected}
          disabled={disabled}
          labelledBy={''}
          className="gzt-multi-select"
          disableSearch={disabledSearch}
        />
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
