/**
 * @flow
 *
 * @format
 */
import type { ReduxDispatch } from 'redux';

import { Scenario, type MissionType } from 'src/data';
import type { ScenarioEngineVersion, ScenarioVersion, ScenarioVendingInfo } from 'src/data/Scenario';
import type { CatalogType } from 'src/services/Firebase';
import type { LocaleType } from 'src/store/scenario/header/ScenarioLocaleManager';
import Firebase, { FirebaseHelper, CatalogTypes, FirebaseSingleton } from 'src/services/Firebase';
import * as Globals from 'src/constants/globals';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { asyncForEach, uniq } from 'src/utils';
import type { OnboardingOptions } from 'src/data/types/OnboardingOptions';
import { i18n } from 'src/assets/locales';
import { availableLanguagesEditor } from 'src/assets/locales/languages';
import { HeaderServiceHelper } from './header';
import { setEditionLocale } from '../preferences/PreferencesServiceHelper';
import { LOCALES, Singleton as LocaleManager } from './header/ScenarioLocaleManager';
import { NPCServiceHelper } from './npcs';
import { ItemsServiceHelper } from './items';
import type { ScenarioReducerState } from './ScenarioReducer';
import type { ItemsReducerState } from './items/ItemsReducer';
import NPC from '../../data/NPC';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# ScenarioServiceHelper / ${title}`, args);
  }
};

export const serializeItemsForFirebase = (items, forApp = false) => {
  const { __detachedNodes, ...others } = items;
  const res = {};
  Object.keys(others).forEach((key) => {
    const cur = others[key];
    if (cur.id) {
      const value = cur.serializeForFirebase(forApp);
      res[key] = value;
    }
  });
  if (__detachedNodes && __detachedNodes.items.length) {
    res.__detachedNodes = {
      items: __detachedNodes.items.map((it) => it.serializeForFirebase(forApp)),
    };
  }
  return res;
};

const serialiazeNpcsForFirebase = (npcs: NPC[], forApp: boolean) => {
  const res = {};
  npcs.forEach((npc) => {
    const serialized = forApp ? npc.serializeForApp() : npc.serialize();
    Object.keys(serialized).forEach((key) => serialized[key] === undefined && delete serialized[key]);
    res[npc.id] = serialized;
  });
  return res;
};
export const serialiazeHeaderForFirebase = (scenario: Scenario, items: ItemsReducerState, forApp: boolean = false) => {
  const res = forApp ? scenario.serializeForApp(items) : scenario.serialize(items);
  Object.keys(res).forEach((key) => res[key] === undefined && delete res[key]);
  // $FlowFixMe
  Object.keys(res.vendingInfo).forEach((key) => res.vendingInfo[key] === undefined && delete res.vendingInfo[key]);
  return res;
};

export const saveScenarioInFirebase = async (
  scenarioId: string,
  header: Scenario,
  items: ItemsReducerState,
  npcs: NPC[],
  firebase: Firebase,
) => {
  const serialized = {
    header: serialiazeHeaderForFirebase(header, items),
    itemsData: serializeItemsForFirebase(items),
    npcs: undefined,
  };
  if (npcs && npcs.length) {
    serialized.npcs = serialiazeNpcsForFirebase(npcs);
  } else {
    delete serialized.npcs;
  }
  await firebase.scenarioEditorHeader(scenarioId).set(serialized.header);
  if (serialized.itemsData) {
    await firebase.scenarioEditorItemsData(scenarioId).set(serialized.itemsData);
  }
  if (serialized.npcs) {
    await firebase.scenarioEditorNPCs(scenarioId).set(serialized.npcs);
  }
};

export type saveScenarioInFirebaseType = (
  scenarioId: string,
  state: any,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<void>;
export const exportScenarioInFirebase: saveScenarioInFirebaseType = (scenarioId, state, firebase) => async (
  dispatch,
) => {
  logHelperCall('exportScenarioInFirebase', { scenarioId, state });
  if (scenarioId && scenarioId.length) {
    try {
      await saveScenarioInFirebase(scenarioId, state.header, state.items, state.npcs.npcs, firebase);
      EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_SCENARIO_SAVED_IN_DB')(dispatch);
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_SAVE_FAILED', error.message)(dispatch);
    }
  } else {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SAVE_NO_ID')(dispatch);
    throw new Error('Scenario id is required');
  }
};

export type createScenarioType = (
  scenarioId?: string,
  teamId?: string,
  firebase: Firebase,
  engineVersion: number,
  skipOnboarding: boolean,
  onboarding: OnboardingOptions,
) => (ReduxDispatch) => Promise<boolean>;
export const createScenario: createScenarioType = (
  scenarioId,
  teamId,
  firebase,
  engineVersion,
  skipOnboarding,
  onboarding,
) => async (dispatch) => {
  logHelperCall('createScenario', { scenarioId, teamId });
  try {
    const onboardingToUse = skipOnboarding ? undefined : onboarding;
    if (onboardingToUse) {
      onboardingToUse.language = i18n.language.split('-')[0] || availableLanguagesEditor[0];
      onboardingToUse.yearInt = Number(onboardingToUse.year);
    }
    const { scenarioId: finalScenarioId } = await firebase.createScenarioAsync(scenarioId, teamId, onboardingToUse);
    // eslint-disable-next-line no-use-before-define
    await loadScenarioAsync(finalScenarioId, firebase, engineVersion)(dispatch);
    return true;
  } catch (error) {
    console.log('Could not load scenario', error);
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return false;
  }
};

export type TranslationObjType = { string: { [locale: string]: string } };
export type TranslationItemsType = { [itemId: string]: TranslationObjType };
export type applyTranslationsType = (
  scenario: ScenarioReducerState,
  translations: { items: TranslationItemsType, npcs: TranslationItemsType, header: TranslationObjType },
) => (ReduxDispatch) => Promise<void>;
export const applyTranslations: applyTranslationsType = (scenario, translations) => async (dispatch) => {
  await ItemsServiceHelper.applyTranslations(scenario.items, translations.items)(dispatch);
  await HeaderServiceHelper.applyTranslations(
    scenario.header,
    translations.header[scenario.header.id],
    scenario.items,
  )(dispatch);
  await NPCServiceHelper.applyTranslations(scenario.header.id, scenario.npcs, translations.npcs)(dispatch);
  EventsServiceHelper.addNotif(
    NotificationTypes.SUCCESS,
    'S_SCENARIO_TRANSLATION_APPLIED',
    scenario.header.id,
  )(dispatch);
};

export const formatTranslation = (translationJson: any[]) => {
  const translationObj = { npcs: {}, items: {}, header: {} };
  if (translationJson) {
    translationJson.forEach((line) => {
      const { path, ...trads } = line;
      const pathSteps = path.split('.');
      const scenarioPart = pathSteps.shift();
      let current = translationObj[scenarioPart];
      const id = pathSteps.shift();
      if (!current[id]) {
        current[id] = {};
      }
      current = current[id];
      const internalPath = pathSteps.join('.');
      current[internalPath] = trads;
    });
  }
  return translationObj;
};

// IMPORT
// *********************

export type importScenarioDataType = (
  content: any,
  header?: any,
  saveInFirebase?: boolean,
  editorEngineVersion: number,
) => (ReduxDispatch) => Promise<string>;
export const importScenarioData: importScenarioDataType = (
  content,
  header,
  saveInFirebase = false,
  editorEngineVersion,
) => async (dispatch) => {
  logHelperCall('importScenarioData', { content, header });
  // First check that the scenario is compatible
  let lastEngine;
  lastEngine = header && header.lastEngineVersion;
  if (!lastEngine && header.versionPerEngine) {
    lastEngine = 2;
  }
  if (lastEngine && lastEngine > editorEngineVersion) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_REQUIRE_NEW_EDITOR')(dispatch);
    throw new Error('E_SCENARIO_REQUIRE_NEW_EDITOR');
  } else if (lastEngine && lastEngine < editorEngineVersion) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_NEED_MIGRATION')(dispatch);
    throw new Error('E_SCENARIO_NEED_MIGRATION');
  } else {
    // Then setup
    const scenarioId = header ? header.id : content.id;
    await NPCServiceHelper.importNPCS(scenarioId, content.npcs, saveInFirebase)(dispatch);
    await ItemsServiceHelper.importItems(content.items)(dispatch);
    if (header && Object.values(header).length) {
      await HeaderServiceHelper.loadHeader(header, content.items, saveInFirebase)(dispatch);
    } else {
      HeaderServiceHelper.importFromContent(content)(dispatch);
    }
    return header ? header.id : 'unknwonScenario';
  }
};

export type loadScenarioFromFirebaseType = (
  scenarioId: string,
  firebase: Firebase,
  engineVersion: number,
  editionLocale?: string,
) => (ReduxDispatch) => Promise<string>;
export const loadScenarioAsync: loadScenarioFromFirebaseType = (
  scenarioId,
  firebase,
  engineVersion,
  editionLocale,
) => async (dispatch) => {
  logHelperCall('loadScenarioAsync', scenarioId);
  const header = await firebase.scenarioEditorHeader(scenarioId).once('value');
  const itemsData = await firebase.scenarioEditorItemsData(scenarioId).once('value');
  const npcs = await firebase.scenarioEditorNPCs(scenarioId).once('value');

  const headerVal = header.val();
  const itemsDataVal = itemsData.val();
  const npcsVal = npcs.val();
  const content = { npcs: npcsVal, items: itemsDataVal };
  const version = await FirebaseHelper.getScenarioNextVersionAsync(scenarioId, firebase);
  headerVal.lastVersion = version;
  await importScenarioData(content, headerVal, false, engineVersion)(dispatch);
  let defaultLng: LocaleType;
  let availableLocales: Array<LocaleType>;
  // Reset the editing language if needed
  if (headerVal && headerVal.managedLocales) {
    defaultLng = headerVal.defaultLng ? headerVal.defaultLng : headerVal.managedLocales[0];
    availableLocales = headerVal.managedLocales;
    if (!headerVal.managedLocales.includes(editionLocale)) {
      // eslint-disable-next-line prefer-destructuring
      // The edition locale used does not exist for this scenario, we take its default one
      await setEditionLocale(defaultLng)(dispatch);
    }
  } else {
    // No value, we take the locale default
    await setEditionLocale(Object.keys(LOCALES)[0])(dispatch);
  }
  LocaleManager.configure(defaultLng, availableLocales);
  return scenarioId;
};

// EXPORT
// *********************

export const exportScenarioTranlations: exportScenarioType = (state) => () => {
  const npcs = NPCServiceHelper.exportTranslations(state.npcs);
  const items = ItemsServiceHelper.exportTranslations(state.items);
  const head = HeaderServiceHelper.exportTranslations(state.header);
  const lines = [...npcs, ...items, ...head];
  const availableLocales = state.header.managedLocales;
  const headers = [{ key: 'path', label: 'technicalPath' }];
  availableLocales.forEach((locale) => {
    headers.push({ key: locale, label: locale });
  });
  return { headers, lines };
};

// RELEASING
// **********************
export const listChangesAsync = async (scenarioId: string, versions: string[], firebase: Firebase) => {
  const versionNumbers = versions.map((it) => Number.parseInt(it.substr(1), 10));
  let changes = [];
  if (versionNumbers.length) {
    let min = Math.min(...versionNumbers);
    const max = Math.max(...versionNumbers);
    if (min !== max) {
      min += 1;
    }
    let cpt = min;
    while (cpt <= max) {
      const changesRef = firebase.scenarioEditorChanges(scenarioId, `v${cpt}`);
      const changesSnap = await changesRef.once('value');
      const currentChanges = changesSnap.exists() ? changesSnap.val() : {};
      changes = [...changes, ...Object.values(currentChanges)];
      cpt += 1;
    }
  }
  const editors = [];
  const itemIds = [];
  const sections = [];
  changes.forEach((change) => {
    if (!editors.includes(change.editor)) {
      editors.push(change.editor);
    }
    if (!sections.includes(change.section)) {
      sections.push(change.section);
    }
    if (change.section === 'items' && !itemIds.includes(change.itemId)) {
      itemIds.push(change.itemId);
    }
  });
  return {
    changes,
    editors,
    itemIds,
    sections,
  };
};

const updateReleasedSchoolScenariosList = async (
  scenarioId: string,
  isSchool: boolean,
  isVisible: boolean,
  catalog: CatalogType,
  firebase: Firebase,
  dryRun: boolean,
) => {
  const schoolScenariosSnapshot = await firebase.schoolScenarios(catalog).once('value');
  let schoolScenarios = [];
  if (schoolScenariosSnapshot.exists()) {
    schoolScenarios = schoolScenariosSnapshot.val();
  }

  const shouldBeShoolListed = isSchool && isVisible;
  if (schoolScenarios.includes(scenarioId) !== shouldBeShoolListed) {
    // Update required
    if (shouldBeShoolListed) {
      schoolScenarios.push(scenarioId);
    } else {
      schoolScenarios = schoolScenarios.filter((it) => it !== scenarioId);
    }
    if (!dryRun) {
      await firebase.schoolScenarios(catalog).set(schoolScenarios);
    }
  }
};

const cleanupUnusedData = (
  scenarioId: string,
  engineVersions: ScenarioEngineVersion[],
  firebase: Firebase,
  dryRun: boolean,
) => async (dispatch) => {
  let versionsTokeep: ScenarioVersion[] = [];
  engineVersions.forEach((it) => {
    // $FlowFixMe: Arrays are string arrays
    versionsTokeep = versionsTokeep.concat(Object.values(it));
  });

  let allFiles: string[] = [];
  let filesToKeep: string[] = [];

  const versionsTokeepNbrs = versionsTokeep.map((it) => Number.parseInt(it.scenarioVersion.substr(1), 10));
  const max = Math.max(...versionsTokeepNbrs);
  const cleanRes = { files: [], indexVersions: [] };
  for (let i = 1; i <= max; i += 1) {
    const isVersionToKeep = versionsTokeepNbrs.includes(i);
    try {
      // eslint-disable-next-line no-await-in-loop
      const versionIndexSnapshot = await firebase
        .scenarioDataVersion(scenarioId, `v${i}`)
        .child('index')
        .once('value');
      if (versionIndexSnapshot.exists()) {
        const index = versionIndexSnapshot.val();
        // eslint-disable-next-line no-loop-func
        Object.keys(index).forEach((locale) => {
          allFiles = [...allFiles, ...index[locale]];
        });
        if (isVersionToKeep) {
          // eslint-disable-next-line no-loop-func
          Object.keys(index).forEach((locale) => {
            filesToKeep = [...filesToKeep, ...index[locale]];
          });
        }
      }
    } catch (error) {
      EventsServiceHelper.addNotif(
        NotificationTypes.WARN,
        'W_SCENARIO_VERSIONS_CLEANING_FAILED',
        error.message,
      )(dispatch);
    }
    if (!isVersionToKeep) {
      cleanRes.indexVersions.push(`v${i}`);
      if (!dryRun) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await firebase.scenarioDataVersion(scenarioId, `v${i}`).remove();
        } catch (error) {
          EventsServiceHelper.addNotif(
            NotificationTypes.WARN,
            'W_SCENARIO_VERSIONS_CLEANING_FAILED',
            error.message,
          )(dispatch);
        }
      }
    }
  }

  // Remove files that are not targetted anymore
  filesToKeep = uniq(filesToKeep);
  allFiles = uniq(allFiles);

  const filesToRemove = allFiles.filter((file) => !filesToKeep.includes(file));
  if (!dryRun) {
    asyncForEach(filesToRemove, async (filename) => {
      await firebase
        .scenarioStorage(scenarioId)
        .child(`assets/${filename}`)
        .delete();
    });
  }
  cleanRes.files = filesToRemove;
  return cleanRes;
};

export const handleReleaseNotifications = (notifications: any, translator: (string) => string) => (dispatch) => {
  notifications.forEach((notif) => {
    if (['E_SCENARIO_RELEASE_WARNS', 'E_SCENARIO_RELEASE_ERRORS'].includes(notif.message)) {
      const parsedExtra = notif.extra.reduce((acc, cur) => {
        if (!acc[cur.message]) {
          acc[cur.message] = {
            [translator(`notifications.error`)]: translator(`notifications.${cur.message}`),
            [translator(`notifications.impactedItems`)]: [cur.item],
            [translator(`notifications.level`)]: translator(`notifications.levels.${cur.level}`),
            keys: [cur.key],
            details: { [cur.item]: cur.details },
          };
        } else {
          acc[cur.message].keys.push(cur.key);
          acc[cur.message][translator(`notifications.impactedItems`)].push(cur.item);
          acc[cur.message].details[cur.item] = cur.details;
        }
        return acc;
      }, {});

      EventsServiceHelper.addNotif(
        notif.type,
        notif.message,
        Object.values(parsedExtra),
        notif.timeToStay,
        notif.action,
      )(dispatch);
    } else {
      EventsServiceHelper.addNotif(notif.type, notif.message, notif.extra, notif.timeToStay, notif.action)(dispatch);
    }
  });
};

export type generateReleaseType = (
  scenarioId: string,
  scenarioState: ScenarioReducerState,
  engineVersion: number,
  removeAllPreviousReleases: boolean,
  translator: (string) => string,
  firebase: Firebase,
  dryRun: boolean,
) => (ReduxDispatch) => Promise<void>;
export const generateRelease: generateReleaseType = (
  scenarioId,
  scenarioState,
  engineVersion,
  removeAllPreviousReleases,
  translator,
  firebase,
  dryRun,
) => async (dispatch) => {
  // Ensure everything is pushed (global save)
  await saveScenarioInFirebase(
    scenarioId,
    scenarioState.header,
    scenarioState.items,
    scenarioState.npcs.npcs,
    firebase,
  );

  // Call server generateRelease function
  const { notifications, crash } = await firebase.releaseScenario(scenarioId, scenarioState.header.teamId, dryRun);
  if (crash) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, crash)(dispatch);
  }
  handleReleaseNotifications(notifications, translator)(dispatch);
};
export type cancelPendingSubmissionAsyncType = (
  scenarioId: string,
  submissionId: string,
) => (ReduxDispatch) => Promise<any>;
export const cancelPendingSubmissionAsync: cancelPendingSubmissionAsyncType = (
  scenarioId,
  submissionId,
) => async () => {
  const { data } = await FirebaseSingleton.cancelSubmission(scenarioId, submissionId);

  return data;
};

export type submitScenarioAsyncType = (
  teamId: string,
  scenarioId: string,
  version: string,
  message: string,
  isForced: boolean,
  translator: (string) => string,
) => (dispatch: ReduxDispatch) => Promise<{ errors: any[], warns: any[], submission?: any }>;
export const submitScenarioAsync: submitScenarioAsyncType = (
  teamId,
  scenarioId,
  version,
  message,
  isForced,
  translator,
) => async (dispatch) => {
  const { data } = await FirebaseSingleton.submitRelease(teamId, scenarioId, version, message, isForced);

  const { notifications, submission } = data;
  if (notifications?.length) {
    handleReleaseNotifications(notifications, translator)(dispatch);
  }
  const hasError = notifications?.find((it) => it.type === 'ERROR');
  const hasWarn = notifications?.find((it) => it.type === 'WARN');
  return { hasWarn, hasError, submission };
};

const updateCityToInternalIfNeededAsync = async (
  cityId: string,
  wasVisible: boolean,
  isVisible: boolean,
  firebase: Firebase,
  dispatch: ReduxDispatch,
  dryRun: boolean,
) => {
  const cityDevSnapshot = await firebase.cityRelease(cityId, CatalogTypes.dev).once('value');
  const cityProdSnapshot = await firebase.cityRelease(cityId, CatalogTypes.prod).once('value');

  if (!cityProdSnapshot.exists()) {
    if (!cityDevSnapshot.exists()) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_CITY_NOT_FOUND', cityId)(dispatch);
      throw new Error('E_CITY_NOT_FOUND');
    }

    const devCity = cityDevSnapshot.val();
    devCity.visibleScenariosCount = isVisible ? 1 : 0;
    if (!dryRun) {
      await firebase.cityRelease(cityId, CatalogTypes.prod).set(devCity);
    }
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_CITY_DEPLOYED_PPR_PROD', cityId)(dispatch);
  } else if (wasVisible !== isVisible) {
    const increment = 0 - (wasVisible ? 1 : 0) + (isVisible ? 1 : 0);
    const oldCount = cityProdSnapshot.val().visibleScenariosCount;
    if (!dryRun) {
      await firebase
        .cityRelease(cityId, CatalogTypes.prod)
        .child('visibleScenariosCount')
        .set(oldCount + increment);
    }
  }
};

export type deployToInternalAsyncType = (
  scenarioId: string,
  vendingInfo: ScenarioVendingInfo,
  isOfficial: boolean,
  missionType: MissionType,
  removeOldReleasesAccess: boolean,
  deployPreviousVersions: boolean,
  firebase: Firebase,
  dryRun: boolean,
) => (ReduxDispatch) => Promise<void>;

export const deployToInternalAsync: deployToInternalAsyncType = (
  scenarioId,
  vendingInfo,
  isOfficial,
  missionType,
  removeOldReleasesAccess,
  deployPreviousVersions,
  firebase,
  dryRun,
) => async (dispatch) => {
  const snapshot = await firebase.scenarioHeader(scenarioId, CatalogTypes.dev).once('value');
  const currentDevVersion = snapshot.exists() && snapshot.val();

  const prodSnapshot = await firebase.scenarioHeader(scenarioId, CatalogTypes.prod).once('value');
  const currentProdVersion = prodSnapshot.exists() && prodSnapshot.val();

  const version = currentDevVersion.lastVersion;
  let oldVendingInfo;
  let amss = [];
  if (currentDevVersion && version) {
    if (currentProdVersion && currentProdVersion.lastVersion === version) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_RELEASE_ALREADY_DEPLOYED', version)(dispatch);
      throw new Error('E_SCENARIO_RELEASE_ALREADY_DEPLOYED');
    }
    // Dupplicate index from dev to prod
    try {
      const vendingInfoSnapshot = await firebase.scenarioVendingInfo(scenarioId, CatalogTypes.prod).once('value');
      if (vendingInfoSnapshot.exists()) {
        oldVendingInfo = vendingInfoSnapshot.val();
      }
      currentDevVersion.vendingInfo = vendingInfo;
      const versionPerEngine = {};
      Object.keys(currentDevVersion.versionPerEngine).forEach((engine) => {
        const devVersion = currentDevVersion.versionPerEngine[engine];
        if (deployPreviousVersions || devVersion.scenarioVersion === version) {
          versionPerEngine[engine] = devVersion;
        }
      });
      if (currentProdVersion) {
        if (!removeOldReleasesAccess) {
          Object.keys(currentProdVersion.versionPerEngine).forEach((engine) => {
            const prodVersion = currentProdVersion.versionPerEngine[engine];
            if (!versionPerEngine[engine]) {
              versionPerEngine[engine] = prodVersion;
            }
          });
        }
      }
      currentDevVersion.isOfficial = isOfficial;
      currentDevVersion.missionType = missionType;
      currentDevVersion.versionPerEngine = versionPerEngine;
      if (!dryRun) {
        await firebase.scenarioHeader(scenarioId, CatalogTypes.prod).set(currentDevVersion);
        // Also update the dev version to have coherent values
        await firebase.scenarioHeader(scenarioId, CatalogTypes.dev).set(currentDevVersion);
        if (isOfficial !== undefined && missionType !== undefined) {
          await firebase
            .scenarioEditorHeader(scenarioId)
            .child('isOfficial')
            .set(isOfficial);
          await firebase
            .scenarioEditorHeader(scenarioId)
            .child('missionType')
            .set(missionType);
        }
      }
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_SCENARIO_DEPLOY_DATA_FAILED', error.message)(dispatch);
      EventsServiceHelper.addNotif(
        NotificationTypes.ERROR,
        'E_SCENARIO_DEPLOY_PPR_PROD_FAILED',
        `${scenarioId}`,
      )(dispatch);
      throw new Error('E_SCENARIO_DEPLOY_DATA_FAILED');
    }
    // Update Production AMS for scenario
    try {
      amss = await FirebaseHelper.updateAmsForScenarioAsync(
        currentDevVersion,
        version,
        CatalogTypes.prod,
        firebase,
        dryRun,
      );
    } catch (error) {
      EventsServiceHelper.addNotif(
        NotificationTypes.WARN,
        'E_SCENARIO_RELEASE_HEADER_AMS_FAILED',
        error.message,
      )(dispatch);
    }

    await updateCityToInternalIfNeededAsync(
      currentDevVersion.cityId,
      !!oldVendingInfo && oldVendingInfo.visible,
      vendingInfo.visible,
      firebase,
      dispatch,
      dryRun,
    );

    await updateReleasedSchoolScenariosList(
      currentDevVersion.id,
      currentDevVersion.isSchool,
      vendingInfo.visible,
      'prod',
      firebase,
      dryRun,
    );

    // Cleanup unused data
    const cleanRes = await cleanupUnusedData(
      scenarioId,
      [currentDevVersion.versionPerEngine],
      firebase,
      dryRun,
    )(dispatch);
  } else {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_NOTHING_TO_DEPLOY', scenarioId)(dispatch);
    throw new Error('E_NOTHING_TO_DEPLOY');
  }

  EventsServiceHelper.addNotif(
    dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS,
    'S_SCENARIO_RELEASED_PROD',
    `${scenarioId} version ${version}. Included folowing Ams: ${amss.join(', ')}.}`,
    0,
  )(dispatch);
};

export type deleteScenarioType = (scenarioId?: string, firebase: Firebase) => (ReduxDispatch) => Promise<boolean>;
export const deleteScenario: deleteScenarioType = (scenarioId, firebase) => async (dispatch) => {
  logHelperCall('deleteScenario', { scenarioId });
  try {
    const res = await firebase.deleteScenarioAsync(scenarioId);
    return res;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return false;
  }
};

export const prepareVendingInfoToDeploy = (
  comingSoon,
  visible,
  isFree,
  price,
  iapSku,
  promoExpirationDate,
  externalSeller,
  expendable,
) => {
  const newVendingInfo: any = {
    comingSoon,
    externalSeller,
    expendable: !isFree && expendable,
    visible,
    price: isFree || !price ? 0 : parseInt(price, 10),
  };
  if (!isFree && iapSku) {
    newVendingInfo.iapSku = iapSku;
  }
  if (promoExpirationDate) {
    newVendingInfo.promoExpirationDate = promoExpirationDate;
  }
  return newVendingInfo;
};

// CLEANUP
// *********************
export type cleanupType = () => (ReduxDispatch) => void;
export const cleanup: cleanupType = () => (dispatch) => {
  logHelperCall('cleanup');
  HeaderServiceHelper.cleanup()(dispatch);
  NPCServiceHelper.cleanup()(dispatch);
  ItemsServiceHelper.cleanup()(dispatch);
};
