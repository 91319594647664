/**
 * @flow
 *
 * @format
 */
/* global google */
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withProps } from 'recompose';
import * as markers from 'fontawesome-markers';
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle, Polygon, Polyline } from 'react-google-maps';
import { ItemTypes } from 'src/data';
import { Constants } from 'src/assets';
import { PreferencesServiceHelper } from 'src/store/preferences';
import HelpButton from 'src/pages/components/HelpButton';
import { toast } from 'react-toastify';

import { withTranslation } from 'react-i18next';
import { getAltitude } from 'src/utils';

const colors = {
  currentItem: '#00E0CC',
  gameAreas: '#333333',
  polylines: '#FC1472',
  POI: '#FC1472',
  AMS: '#333333',
  CITY: '#FC1472',
  disabled: '#E8EDF3',
};

const markersTypes = {
  Start: 'HOME',
  Objective: 'DOT_CIRCLE_O',
  Major: 'EXCLAMATION_CIRCLE',
  Interactive: 'UNLOCK',
  NPC: 'USER_SECRET',
  hidden: 'LOCK',
  AMS: 'CAMERA_RETRO',
  CITY: 'BUILDING',
};

const MyMapComponent = compose(
  withProps((props: any) => ({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${Constants.mapsApiKey}&v=3.exp&libraries=geometry,drawing,places,elevation`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '500px', marginBottom: '50px' }} />,
    mapElement: <div style={{ height: 'calc(100vh - 160px)', maxHeight: props.maxHeight }} />,
  })),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const newLat = nextProps.prefLat;
      const newLon = nextProps.prefLon;
      const curLat = this.props.prefLat;
      const curLon = this.props.prefLon;
      if (newLat !== curLat || newLon !== curLon) {
        this.setState({
          position: {
            lat: newLat,
            lng: newLon,
          },
        });
      }
    },
    componentWillMount() {
      const { prefLat, prefLon, prefZoom, currentPolygon, currentPolyline } = this.props;
      const refs = {};
      this.setState({
        position: {
          lat: prefLat,
          lng: prefLon,
        },
        lastZoom: prefZoom,
        polygon: currentPolygon || [],
        polyline: currentPolyline || [],
        overlay: [],
        defaultPath:
          currentPolygon && currentPolygon.length
            ? currentPolygon.map((val) => {
                const value = { lat: val[0], lng: val[1] };
                return value;
              })
            : [],
        defaultVisible: !!currentPolygon,

        onMarkerMounted: (ref) => {
          refs.marker = ref;
        },
        onMarkerComplete: (marker) => {
          const { updatePrefPosition, lastZoom, markerAdded, requireAlt } = this.props;
          if (this.state.marker) {
            this.state.onDelete();
          }
          const pos = marker.getPosition();
          this.setState({ position: pos });
          const map = marker.getMap();
          // Should never be called
          if (requireAlt) {
            // DEPRECATED: always return 0
            getAltitude(pos.lat(), pos.lng())
              .then((res) => {
                this.notifyMarker(res);
              })
              .catch(() => {
                this.notifyMarker([pos.lat(), pos.lng()]);
              });
          } else {
            this.notifyMarker([pos.lat(), pos.lng()]);
          }
          const zoom = map ? map.getZoom() : lastZoom;

          updatePrefPosition(pos.lat(), pos.lng(), zoom);
          this.setState({ marker: pos });
          if (markerAdded) {
            markerAdded(pos);
          }
        },
        onPolylineComplete: (values) => {
          const { updatePrefPosition } = this.props;
          let sumLat = 0;
          let sumLon = 0;
          let count = 0;
          const array = values
            .getPath()
            .getArray()
            .map((val) => {
              const lat = val.lat();
              const lon = val.lng();
              sumLat += lat;
              sumLon += lon;
              count += 1;
              return [lat, lon];
            });
          if (this.state.polyline.length !== 0) {
            this.state.onDelete();
          }
          updatePrefPosition(sumLat / count, sumLon / count, values.getMap().getZoom());
          this.setState({ polyline: array });
          this.notifyPolyline(array);
        },
        onPolygonComplete: (values) => {
          const { updatePrefPosition } = this.props;
          let sumLat = 0;
          let sumLon = 0;
          let count = 0;
          const array = values
            .getPath()
            .getArray()
            .map((val) => {
              const lat = val.lat();
              const lon = val.lng();
              sumLat += lat;
              sumLon += lon;
              count += 1;
              return [lat, lon];
            });
          if (this.state.polygon.length !== 0) {
            this.state.onDelete();
          }
          updatePrefPosition(sumLat / count, sumLon / count, values.getMap().getZoom());
          this.setState({ polygon: array });
          this.notifyPolygon(array);
        },
        onOverlayComplete: (value) => {
          this.setState({ overlay: [...this.state.overlay, value] });
        },
        onDelete: () => {
          const length = this.state.overlay.length;
          if (length !== 1) {
            this.state.overlay.map((val, key) => {
              if (key !== length - 1) {
                val.overlay.setMap(null);
              }
              return true;
            });
          }
          this.setState({
            polygon: [],
            polyline: [],
            marker: undefined,
            defaultVisible: false,
            defaultPath: [],
          });
          this.notifyPolygon([]);
          this.notifyPolyline([]);
        },
        clickDelete: () => {
          this.state.overlay.map((val) => val.overlay.setMap(null));
          this.setState({
            polygon: [],
            polyline: [],
            marker: undefined,
            defaultVisible: false,
            defaultPath: [],
          });
          this.notifyPolygon([]);
          this.notifyPolyline([]);
        },
        printMarkers: () => {
          const { currentItem, poiItems, showCurrent, showPois, restrictedPoiEdition, canDrag } = this.props;
          if (poiItems) {
            const markersRes = poiItems.map((it) => {
              const isCurrent = !!currentItem && currentItem.id === it.id;
              let pos = {
                lat: it.coordinate.latitude,
                lng: it.coordinate.longitude,
              };
              if (isCurrent && this.props.currentMarker && this.props.currentMarker.length) {
                pos = {
                  lat: this.props.currentMarker[0],
                  lng: this.props.currentMarker[1],
                };
              }
              if ((isCurrent && showCurrent) || showPois) {
                const iconType = it.type === ItemTypes.POI ? it.poiType || Object.values(it.poiTypes)[0] : it.type;
                let color = isCurrent ? colors.currentItem : colors[it.type];
                let clickable = true;
                if (restrictedPoiEdition && !restrictedPoiEdition.find((el) => el === it.id)) {
                  color = colors.disabled;
                  clickable = false;
                }
                return (
                  <Marker
                    position={pos}
                    key={it.id}
                    icon={{
                      path: markers.default[markersTypes[iconType]],
                      scale: 0.5,
                      fillColor: color,
                      fillOpacity: 1,
                      strokeColor: '',
                      strokeWeight: 0,
                      // $FlowFixMe Google
                      anchor: new google.maps.Point(25, 0),
                    }}
                    options={{
                      clickable,
                      editable: isCurrent && clickable && canDrag,
                      draggable: isCurrent && clickable && canDrag,
                      title: it.id,
                    }}
                    onClick={() => this.onMarkerClicked(it.id, it)}
                    onMouseDown={!canDrag ? this.props.warnDrag : undefined}
                    onDragEnd={(evt) => this.onMarkerDragged(it.id, evt)}
                  />
                );
              }
              return undefined;
            });
            const circles = poiItems.map((it) => {
              const pos = {
                lat: it.coordinate.latitude,
                lng: it.coordinate.longitude,
              };
              const isCurrent = !!currentItem && currentItem.id === it.id;
              if ((isCurrent && showCurrent) || showPois) {
                let color = isCurrent ? colors.currentItem : colors[it.type];
                if (restrictedPoiEdition && !restrictedPoiEdition.find((el) => el === it.id)) {
                  color = colors.disabled;
                }
                return (
                  <Circle
                    key={`${it.id}_circle`}
                    center={pos}
                    radius={it.distanceMinToTrigger}
                    options={{
                      strokeColor: color,
                      strokeOpacity: 0.8,
                      fillColor: color,
                      fillOpacity: 0.4,
                    }}
                  />
                );
              }
              return undefined;
            });
            return [...circles, ...markersRes];
          }
          return undefined;
        },
        printPolyline: () => {
          const { currentItem, polylines, showPolylines, showCurrent, restrictedPolylineEdition } = this.props;
          if (polylines) {
            return polylines.map((it) => {
              const path = it.patrolCoordinates.map((val) => {
                const value = { lat: val.latitude, lng: val.longitude };
                return value;
              });
              const isCurrent = !!currentItem && currentItem.id === it.id;
              if ((isCurrent && showCurrent) || showPolylines) {
                let color = isCurrent ? colors.currentItem : colors.polylines;
                let clickable = true;
                if (restrictedPolylineEdition && !restrictedPolylineEdition.find((el) => el === it.id)) {
                  color = colors.disabled;
                  clickable = false;
                }
                return (
                  <Polyline
                    path={path}
                    key={it.id}
                    options={{
                      geodesic: true,
                      title: it.id,
                      strokeColor: color,
                      strokeOpacity: 1.5,
                      clickable,
                      editable: false,
                      draggable: false,
                      zIndex: 1,
                    }}
                    onClick={() => this.onPolygonClicked(it.id)}
                    onDragEnd={(evt) => this.onPolylineDragged(it.id, evt)}
                  />
                );
              }
              return undefined;
            });
          }
          return undefined;
        },
        printPolygon: () => {
          const { currentItem, gameAreas, showPolygons, showCurrent, restrictedPolygonEdition } = this.props;
          if (gameAreas) {
            return gameAreas.map((it) => {
              const path = it.area.map((val) => {
                const value = { lat: val[0], lng: val[1] };
                return value;
              });
              const isCurrent = !!currentItem && currentItem.id === it.id;
              if ((isCurrent && showCurrent) || showPolygons) {
                let color = isCurrent ? colors.currentItem : colors.gameAreas;
                let clickable = true;
                if (restrictedPolygonEdition && !restrictedPolygonEdition.find((el) => el === it.id)) {
                  color = colors.disabled;
                  clickable = false;
                }
                return (
                  <Polygon
                    path={path}
                    key={it.id}
                    options={{
                      title: it.id,
                      fillColor: color,
                      fillOpacity: 0.2,
                      strokeWeight: 1.5,
                      clickable,
                      editable: false,
                      draggable: false,
                      zIndex: 1,
                    }}
                    onClick={() => this.onPolygonClicked(it.id)}
                    onDragEnd={(evt) => this.onPolygonDragged(it.id, evt)}
                  />
                );
              }
              return undefined;
            });
          }
          return undefined;
        },
      });
    },
    onMarkerDragged(id, event) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      const { onMarkerDragged, requireAlt } = this.props;
      this.notifyMarker([lat, lng]);
      this.setState({ currentMarker: [lat, lng] });
      // Should never be called
      if (requireAlt) {
        // DEPRECATED: always return 0
        getAltitude(lat, lng)
          .then((res) => {
            onMarkerDragged(id, lat, lng, res[2]);
          })
          .catch(() => {
            onMarkerDragged(id, lat, lng);
          });
      } else {
        onMarkerDragged(id, lat, lng);
      }
      if (onMarkerDragged) {
        onMarkerDragged(id, lat, lng);
      }
    },
    onPolygonDragged() {
      // const { onPolygonDragged, notifyPolygon } = this.props;
    },
    onPolylineDragged() {
      // const { onPolygonDragged, notifyPolygon } = this.props;
    },
    onPolygonClicked(id) {
      const { onMarkerClick } = this.props;
      if (onMarkerClick) {
        onMarkerClick(id);
      }
    },
    onPolylineClicked(id) {
      const { onMarkerClick } = this.props;
      if (onMarkerClick) {
        onMarkerClick(id);
      }
    },
    onMarkerClicked(id, item) {
      const { onMarkerClick } = this.props;
      if (onMarkerClick && item) {
        onMarkerClick(id, item && item.nodeId, item && item.type);
      }
    },
    notifyPolyline(polyline) {
      const { arraykey, requestId, handlePolyline } = this.props;
      const event = {
        polyline,
        requestId,
        dataset: { arraykey },
      };
      if (handlePolyline) {
        handlePolyline(event);
      }
    },
    notifyPolygon(polygon) {
      const { arraykey, requestId, handlePolygon } = this.props;
      const event = {
        polygon,
        requestId,
        dataset: { arraykey },
      };
      if (handlePolygon) {
        handlePolygon(event);
      }
    },

    notifyMarker(position) {
      const { arraykey, requestId, handleMarker } = this.props;
      const event = {
        position,
        requestId,
        dataset: { arraykey },
      };
      if (handleMarker) {
        handleMarker(event);
      }
    },
  }),
  withScriptjs,
  withGoogleMap,
)((props) => (
  <div hidden={props.hidden}>
    {props.fieldValue}
    <GoogleMap
      mapTypeId={'hybrid'}
      defaultZoom={props.lastZoom}
      defaultCenter={props.position}
      center={props.position}
      options={{ streetViewControl: false }}
    >
      <DrawingManager
        defaultOptions={{
          drawingControl: props.canDrag || props.canDrawPolygon,
          drawingControlOptions: {
            drawingModes: props.drawingModes,
          },
          polygonOptions: {
            fillColor: '#FFFFFF88',
            fillOpacity: 0.4,
            strokeWeight: 2.5,
            clickable: true,
            editable: false,
            draggable: false,
            zIndex: 1,
          },
          polylineOptions: {
            strokeColor: '#FFFFFF88',
            strokeOpacity: 0.4,
            strokeWeight: 2.5,
            clickable: true,
            editable: false,
            draggable: false,
            zIndex: 1,
          },
        }}
        onPolygonComplete={(value) => props.onPolygonComplete(value)}
        onPolylineComplete={(value) => props.onPolylineComplete(value)}
        onOverlayComplete={(value) => props.onOverlayComplete(value)}
        onMarkerComplete={(value) => props.onMarkerComplete(value)}
      />
      <Polygon
        paths={props.defaultPath}
        visible={props.defaultVisible}
        options={{
          fillColor: '#000',
          fillOpacity: 0.4,
          strokeWeight: 2.5,
          clickable: true,
          editable: false,
          draggable: false,
          zIndex: 1,
        }}
      />
      <Polyline
        paths={props.defaultPolyline}
        visible={props.defaultVisible}
        options={{
          strokeColor: '#FFFFFF88',
          strokeOpacity: 0.4,
          strokeWeight: 2.5,
          clickable: true,
          editable: false,
          draggable: false,
          zIndex: 1,
        }}
      />
      {props.printPolygon()}
      {props.printPolyline()}
      {props.printMarkers()}

      <div className="d-flex btn-group rounded-0 buttonsBg">
        <button className="btn btn-delete" onClick={() => props.clickDelete()} disabled={props.polygon.length === 0}>
          {props.t('screens.scenarioEdition.mapEdition.erasePolygon')}
        </button>
        <button className="btn btn-delete" onClick={() => props.clickDelete()} disabled={props.polyline.length === 0}>
          {props.t('screens.scenarioEdition.mapEdition.erasePolyline')}
        </button>
        <HelpButton
          helpStrings={props.helpStrings}
          id="scenarioMap"
          title={props.t('screens.scenarioEdition.mapEdition.mapHelp')}
        />
      </div>
    </GoogleMap>
  </div>
));

type Props = {
  useAlt: boolean,
  polygonChanged: (requestId: string, polygon: number[][]) => any,
  polylineChanged: (requestId: string, polyline: number[][]) => any,
  markerChanged: (requestId: string, point: number[]) => any,
  onMarkerDragged: (id: string, lat: number, lng: number, alt?: number) => any,
  markerAdded: (coords: { x: number, y: number }) => any,
  currentItem: any,
  poiItems: any[],
  gameAreas: any[],
  polylines: any[],
  itemClicked: (string) => any,
  updatePrefPosition: (lat: number, lon: number, zoom: number) => any,
  prefLat: number,
  prefLon: number,
  prefZoom: number,
  restrictedPolylineEdition: string[],
  restrictedPolygonEdition: string[],
  restrictedPoiEdition: string[],
  requireAlt: boolean,
  maxHeight?: number,
  t: (key: string[], params: any) => string,
  canDrag?: boolean,
  canDrawPolygon?: boolean,
  canDrawPolyline?: boolean,
};

type State = {
  isMarkerShown: boolean,
  currentPolygon: number[][],
  currentPolyline: number[][],
  currentMarker: number[],
};

class MapEditionWidget extends React.PureComponent<Props, State> {
  static defaultProps = {
    extraEditablePolygons: [],
    extraEditablePolylines: [],
    showPolygoncoordinate: true,
    showPolylinecoordinate: true,
    canDrag: true,
  };

  state = {
    isMarkerShown: false,
    currentPolygon: [],
    currentPolyline: [],
    currentMarker: [0, 0],
  };

  componentDidUpdate = (newProps) => {
    if (this.props.currentItem !== newProps.currentItem) {
      this.setState({ currentMarker: [], currentPolygon: [], currentPolyline: [] });
    }
  };

  handleMapPolygon = (event) => {
    this.setState({ currentPolygon: event.polygon });
    const { polygonChanged } = this.props;
    if (polygonChanged) {
      polygonChanged(event.requestId, event.polygon);
    }
  };

  handleMapPolyline = (event) => {
    this.setState({ currentPolyline: event.polyline });
    const { polylineChanged } = this.props;
    if (polylineChanged) {
      polylineChanged(event.requestId, event.polyline);
    }
  };

  handleMapMarker = (event) => {
    const { markerChanged } = this.props;
    this.setState({ currentMarker: event.position });
    if (markerChanged) {
      markerChanged(event.requestId, event.position);
    }
  };

  warnDrag = () => {
    toast.warn(this.props.t('default:screens.headerEdition.cantDrag'));
  };

  render() {
    const {
      currentItem,
      poiItems,
      gameAreas,
      polylines,
      itemClicked,
      maxHeight,
      t,
      canDrag,
      canDrawPolygon,
      canDrawPolyline,
    } = this.props;
    const drawingModes = [];
    if (canDrag) {
      drawingModes.push('marker');
    }
    if (canDrawPolyline) {
      drawingModes.push('polyline');
    }
    if (canDrag || canDrawPolygon) {
      drawingModes.push('polygon');
    }
    return (
      <div>
        <MyMapComponent
          t={t}
          maxHeight={maxHeight}
          isMarkerShown={this.state.isMarkerShown}
          warnDrag={this.warnDrag}
          onMarkerClick={itemClicked}
          onPolygonClicked={itemClicked}
          onPolylineClicked={itemClicked}
          handlePolygon={this.handleMapPolygon}
          handlePolyline={this.handleMapPolyline}
          handleMarker={this.handleMapMarker}
          markerAdded={this.props.markerAdded}
          updatePrefPosition={this.props.updatePrefPosition}
          prefLat={this.props.prefLat}
          prefLon={this.props.prefLon}
          prefZoom={this.props.prefZoom}
          currentItem={currentItem}
          poiItems={poiItems}
          gameAreas={gameAreas}
          polylines={polylines}
          showPolygons={true}
          showPolylines={true}
          showCurrent={true}
          showPois={true}
          onMarkerDragged={this.props.onMarkerDragged}
          restrictedPolygonEdition={this.props.restrictedPolygonEdition}
          restrictedPolylineEdition={this.props.restrictedPolylineEdition}
          restrictedPoiEdition={this.props.restrictedPoiEdition}
          helpStrings={t('helpStrings:scenario.dashboard.map', { returnObjects: true })}
          requireAlt={this.props.requireAlt}
          currentMarker={this.state.currentMarker}
          canDrag={this.props.canDrag}
          canDrawPolygon={this.props.canDrawPolygon}
          canDrawPolyline={this.props.canDrawPolyline}
          drawingModes={drawingModes}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    currentItemId,
    extraEditablePolygons,
    itemsMap,
    markerFilter,
    polygonFilter,
    extraEditablePolylines,
    polylineFilter,
  } = ownProps;
  let item = currentItemId && itemsMap[currentItemId];
  if (!item && currentItemId && extraEditablePolygons) {
    item = extraEditablePolygons.find((it) => it.id === currentItemId);
  }

  if (!item && currentItemId && extraEditablePolylines) {
    item = extraEditablePolylines.find((it) => it.id === currentItemId);
  }
  const poiItems = itemsMap && markerFilter ? Object.values(itemsMap).filter(markerFilter) : [];
  const gameAreas = itemsMap && polygonFilter ? Object.values(itemsMap).filter(polygonFilter) : [];
  let polylines = itemsMap && polylineFilter ? Object.values(itemsMap).filter(polylineFilter) : [];

  let areas;
  if (extraEditablePolygons) {
    areas = [...gameAreas, ...extraEditablePolygons];
  } else {
    areas = gameAreas;
  }
  if (extraEditablePolylines) {
    polylines = [...extraEditablePolylines, ...polylines];
  }

  // Defines the center where we look
  let coordinatesToUse;
  if (!(item && item.coordinate)) {
    // We use the start POI coordinate
    const startPOI = itemsMap.start_poi;
    if (
      startPOI &&
      startPOI.coordinate &&
      (startPOI.coordinate.latitude !== 0 || startPOI.coordinate.longitude !== 0)
    ) {
      coordinatesToUse = startPOI.coordinate;
    }
  } else if (item.coordinate.latitude !== 0 || item.coordinate.longitude !== 0) {
    coordinatesToUse = item.coordinate;
  }
  return {
    prefLat: coordinatesToUse ? coordinatesToUse.latitude : state.preferences.lastLat,
    prefLon: coordinatesToUse ? coordinatesToUse.longitude : state.preferences.lastLong,
    prefZoom: ownProps.prefZoom || state.preferences.lastZoom,
    currentItem: item,
    poiItems,
    gameAreas: areas,
    polylines,
  };
};
const mapDispatchToProps = {
  updatePrefPosition: PreferencesServiceHelper.setLastCoordinate,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['default', 'helpStrings']),
)(MapEditionWidget);
