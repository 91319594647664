/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import type { AtlEditorState } from 'src/store';
import { AMSItem, AtlObject } from 'src/data';
import type { AMSReducerState } from 'src/store/ams';
import { AmsServiceHelper } from 'src/store/ams';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';
import Firebase, { withFirebase } from 'src/services/Firebase';
import TranslationsScreen from 'src/pages/components/translation/TranslationsScreen';
import type { TranslationsType } from 'src/pages/components/translation/TranslationsScreen';

export type AMSTranslationScreenProps = {
  exportTranslations: AmsServiceHelper.exportAmsTranslationType,
  applyTranslations: AmsServiceHelper.applyTranslationsType,
  updateAms: AmsServiceHelper.updateAmsType,
  locale: string,
  amss: AMSReducerState,
  amssArray: AMSItem[],
  roomMembers: any[],
  availableLocales: string[],
  t: (key: string[]) => string,
  firebase: Firebase,
};

type State = {};

class AMSTranslationScreen extends React.PureComponent<AMSTranslationScreenProps, State> {
  static defaultProps = {};

  state = {};

  updateItem = async (sectionId: string, part?: string, itemId: string, item: AtlObject<any>) => {
    const { updateAms, firebase } = this.props;
    await updateAms(itemId, item, firebase, true);
  };

  applyTranslations = async (translations: TranslationsType) => {
    const { amss, applyTranslations, firebase } = this.props;
    if (applyTranslations) {
      try {
        await applyTranslations(amss, translations, firebase);
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleCsvLoaded = async (json: any) => {
    const res = AmsServiceHelper.formatTranslation(json);
    return res;
  };

  render() {
    const { amssArray, amss, exportTranslations, availableLocales } = this.props;
    const { headers, lines } = exportTranslations(amss, availableLocales);
    return (
      <TranslationsScreen
        roomMembers={this.props.roomMembers}
        handleCsvLoaded={this.handleCsvLoaded}
        applyTranslations={this.applyTranslations}
        stringTranslationInfos={amssArray.map((item) => ({ id: item.id, item, part: 'ams' }))}
        fileTranslationInfos={amssArray.map((item) => ({ id: item.id, item, part: 'ams' }))}
        updateItem={this.updateItem}
        csvData={{ headers, lines }}
        section="scenario"
        translationId="ams"
      />
    );
  }
}

const mapStateToProps = (state: AtlEditorState) => {
  const amsRedux = state.ams;
  const { _persist, ...others } = amsRedux;

  return {
    editionLocale: state.preferences.editionLocale,
    availableLocales: ['fr', 'en'], // TODO
    amss: amsRedux,
    amssArray: Object.values(others),
  };
};

const mapDispatchToProps = {
  exportTranslations: AmsServiceHelper.exportAmsTranlations,
  updateAms: AmsServiceHelper.updateAms,
  applyTranslations: AmsServiceHelper.applyTranslations,
};

export default withFirebase(
  withUrlProperties(connect(mapStateToProps, mapDispatchToProps)(AMSTranslationScreen), { ams: 'ams' }),
);
