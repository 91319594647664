/**
 * @format
 * @flow
 */
import * as React from 'react';
import { connect } from 'react-redux';
import * as ROUTES from 'src/constants/routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { Scenario, City, missionTypes, type MissionType } from 'src/data';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { Loader } from 'src/pages/components';
import ScenarioButton from './ScenarioButton';
import { withCities } from '../../cities/WithCities';

import './scenarioList.css';

export interface Props {
  helpStrings: { title: string, content: string }[];
  title: string;
  id: string;
  className: string;
  display: string;
  label: string;
  firebase: Firebase;
  locale: string;
  selectedId?: string;
  validClaims: string[];
  userId: string;
  onSelect: (string) => any;
  cities: City[];
  cityIds: string[];
  isConfirmedEditor: boolean;
  history: any;
}

type State = {
  scenarios: Scenario[],
  matchingScenarios: Scenario[],
  loading: boolean,
  getScenarios: 'getAll' | MissionType,
  hasLoadedAllOnce: boolean,
  cityId?: string,
  searchString?: string,
};

class ScenarioList extends React.PureComponent<Props, State> {
  static defaultProps = {
    label: 'general.help',
  };

  state = {
    scenarios: [],
    matchingScenarios: [],
    getScenarios: missionTypes.isOfficial,
    hasLoadedAllOnce: false,
    cityId: undefined,
    searchString: '',
    loading: false,
  };

  componentDidUpdate = (oldProps: Props, oldState: State) => {
    if (oldProps.userId !== this.props.userId || this.props.validClaims.length !== oldProps.validClaims.length) {
      this.refreshScenarios(this.props);
    }
    if (oldState.getScenarios !== this.state.getScenarios && !this.state.hasLoadedAllOnce) {
      this.refreshScenarios(this.props);
    }
  };

  componentWillUnmount() {
    if (this.props.validClaims.includes(Claims.Moderator)) {
      this.props.firebase.scenarios().off();
    }
  }

  refreshScenarios = (props) => {
    this.setState({ loading: true });
    if (props.validClaims.includes(Claims.Moderator)) {
      const saveScenarios = (snapshot) => {
        const scenariosObj = snapshot.val();
        let scenarios = [];
        if (scenariosObj && Object.values(scenariosObj).length) {
          scenarios = Object.values(scenariosObj).map((scenar) => new Scenario((scenar && scenar.header) || {}));
        }
        this.setState(
          {
            scenarios,
            loading: false,
          },
          this.updateScenarios,
        );
      };
      if (this.state.getScenarios === missionTypes.isOfficial) {
        props.firebase.scenarios('header/isOfficial', true).on('value', saveScenarios);
      } else {
        props.firebase.scenarios().on('value', saveScenarios);
        this.setState({ hasLoadedAllOnce: true });
      }
    } else {
      this.refreshAuthorizedScenariosOnly(props.userId);
    }
  };

  refreshAuthorizedScenariosOnly = (userId: ?string) => {
    if (userId && userId.length) {
      this.props.firebase.listAuthorizedScenarios(userId).then((scenarios) => {
        this.setState({
          scenarios,
          matchingScenarios: scenarios,
          loading: false,
        });
      });
    } else {
      this.setState({
        scenarios: [],
      });
    }
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    // $FlowFixMe Boolean is only used for bool fields
    this.setState({ [fieldName]: value });
    this.updateScenarios(fieldName, value);
  };

  updateScenarios = (fieldName, value) => {
    let cityId;
    let searchString;
    let getScenarios;
    ({ cityId, searchString, getScenarios } = this.state);
    if (fieldName) {
      if (fieldName === 'cityId') {
        cityId = value;
      }
      if (fieldName === 'searchString') {
        searchString = value;
      }
      if (fieldName === 'getScenarios') {
        getScenarios = value;
      }
    }

    const matchingScenarios = this.state.scenarios.filter((it) => {
      if (cityId && cityId.length && it.cityId !== cityId) {
        return false;
      }
      if (searchString && searchString.length && !it.id.startsWith(searchString)) {
        return false;
      }
      if (getScenarios !== 'getAll' && getScenarios !== it.missionType) {
        return false;
      }
      return true;
    });
    this.setState({ matchingScenarios });
  };

  renderScenarioButton = (element) => {
    const { locale, selectedId, onSelect } = this.props;
    return (
      <ScenarioButton
        scenario={element}
        locale={locale}
        key={element.id}
        isSelected={element.id === selectedId}
        onSelect={onSelect}
      />
    );
  };

  renderSelectField = (fieldName, value, label, values, locale, forceLabel) => {
    return (
      this.props.isConfirmedEditor && (
        <div className="form-group mb-3" key={fieldName} style={{ paddingLeft: 15, paddingRight: 15 }}>
          <label htmlFor={fieldName}>{forceLabel || label}</label>
          <select
            className="form-control"
            id={fieldName}
            onChange={this.handleChange}
            value={value}
            aria-describedby={`${fieldName}Help`}
            placeholder={''}
          >
            <option value={''}>{''}</option>
            {values &&
              values.map((element) => (
                <option key={element.id} value={element.id}>
                  {element.label || (element.name && element.name.valueForLocale(locale))}
                </option>
              ))}
          </select>
        </div>
      )
    );
  };

  createScenario = () => {
    this.props.history.push(ROUTES.SCENARIO_NEW);
  };

  render() {
    const { matchingScenarios, cityId, getScenarios, loading } = this.state;
    const { cities, locale, t } = this.props;
    return (
      <div>
        {this.renderSelectField('cityId', cityId, t('screens.scenarios.cityIdLabel'), cities, locale)}
        {this.renderSelectField(
          'getScenarios',
          getScenarios,
          t('screens.scenarios.filterTitle'),
          [
            { id: 'getAll', label: t('screens.scenarios.filterAll') },
            { id: missionTypes.isOfficial, label: t('screens.scenarios.filterOfficial') },
            { id: missionTypes.isCertifiedCreator, label: t('screens.scenarios.filterCertified') },
            { id: missionTypes.isPrivate, label: t('screens.scenarios.filterPrivate') },
          ],
          locale,
        )}
        <div className="input-group mb-3">
          {matchingScenarios && matchingScenarios.length
            ? matchingScenarios.map((element) => this.renderScenarioButton(element))
            : !loading && (
                <div className="empty-container">
                  <div className="empty-text-title ooredoo">{t('screens.scenarios.emptyListL1')}</div>
                  <div className="empty-content">
                    <div className=" btn btn-light btn-primary empty-button" onClick={this.createScenario}>
                      {t('screens.scenarios.emptyListL2')}
                    </div>
                  </div>
                </div>
              )}
        </div>
        {loading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.preferences.editionLocale,
  cityIds: state.configuration.availableCities.map((city) => city.id),
  cities: state.configuration.availableCities,
  isConfirmedEditor:
    ownProps.validClaims.includes(Claims.ConfirmedEditor) ||
    ownProps.validClaims.includes(Claims.Admin) ||
    ownProps.validClaims.includes(Claims.Moderator),
});

export default compose(
  withCities,
  withRouter,
  withAuthorization(AuthenticatedCondition, Object.values(Claims)),
  withFirebase,
  connect(mapStateToProps, undefined),
  withTranslation('default'),
)(ScenarioList);
