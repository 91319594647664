/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import HelpButton from '../HelpButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

export interface InputStringProps {
  style?: any;
  fieldName: string;
  value?: ?string;
  label?: string;
  help?: ?string;
  disabled?: boolean;
  hidden?: boolean;
  multiline?: boolean;
  separatorBefore?: boolean;
  handleChange?: (any) => any;
  helpInfos: any;
  labelSmall: boolean;
  prefix?: ?string;
  suffix?: ?string;
  inlineTitle?: boolean;
  spellCheck?: boolean;
  className?: string;
  onFocus?: () => any;
  onClick?: () => any;
  prependStyle?: ?any;
  inputStyle?: ?any;
  suffixLabel?: ?string;
}

export default class InputString extends React.Component<InputStringProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    multiline: false,
    helpInfos: undefined,
    labelSmall: false,
    separatorBefore: false,
    spellCheck: true,
    prependStyle: {},
    inputStyle: {},
  };

  render() {
    const {
      style,
      fieldName,
      value,
      label,
      help,
      disabled,
      multiline,
      handleChange,
      hidden,
      helpInfos,
      separatorBefore,
      labelSmall,
      prefix,
      suffix,
      spellCheck,
      inlineTitle,
      onFocus,
      className,
      onClick,
      prependStyle,
      inputStyle,
      suffixLabel,
    } = this.props;
    const divClass = inlineTitle ? 'input-group' : 'form-group';
    const labelClass = inlineTitle ? 'input-group-prepend mr-2 text-capitalize' : 'strong text-capitalize';
    const prefixStyle = { ...prependStyle, borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' };
    const suffixStyle = { ...prependStyle, borderTopRightRadius: '5px', borderBottomRightRadius: '5px' };

    if (multiline) {
      return (
        <div
          className={`${divClass} ${className || ''}`}
          key={fieldName}
          hidden={hidden}
          style={style}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          {separatorBefore && <hr />}
          {(label || helpInfos) && (
            <label className={`${labelClass} ooredoo`} htmlFor={fieldName}>
              {labelSmall ? <small>{label}</small> : label}{' '}
              {helpInfos &&
                Array.isArray(helpInfos) &&
                helpInfos.map((helpInfo, index) => (
                  <HelpButton
                    key={fieldName + index}
                    helpStrings={helpInfo.content}
                    id={fieldName}
                    title={helpInfo.title}
                    label={helpInfo.btnLabel}
                    display={'inline'}
                    className={'ml-auto btn btn-outline-warning ml-2'}
                  />
                ))}
            </label>
          )}
          <textarea
            type="text"
            className="form-control"
            id={fieldName}
            onChange={handleChange}
            value={value}
            disabled={disabled}
            aria-describedby={`${fieldName}Help`}
            placeholder={''}
            rows="3"
            spellCheck={spellCheck}
            onFocus={onFocus}
            style={{ ...inputStyle }}
          />
          {help && (
            <small id={`${fieldName}Help`} className="form-text text-muted">
              {help}
            </small>
          )}
        </div>
      );
    }
    return (
      <div
        className={`w-100 ${divClass} ${className || ''}`}
        key={fieldName}
        hidden={hidden}
        style={style}
        onClick={onClick}
      >
        {(label || helpInfos) && (
          <label className={labelClass} htmlFor={fieldName}>
            {`${label} `}
            {helpInfos &&
              Array.isArray(helpInfos) &&
              helpInfos.map((helpInfo, index) => (
                <HelpButton
                  key={fieldName + index}
                  helpStrings={helpInfo.content}
                  id={fieldName}
                  title={helpInfo.title}
                  label={helpInfo.btnLabel}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning ml-2'}
                />
              ))}
          </label>
        )}
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted mb-2">
            {help}
          </small>
        )}
        <div className="input-group" style={{ height: 'auto' }}>
          {prefix && prefix.length && (
            <div className="input-group-prepend h-100">
              <span className="input-group-text h-100" id="basic-addon1" style={prefixStyle}>
                {prefix}
              </span>
            </div>
          )}
          <input
            type="text"
            className="form-control"
            id={fieldName}
            onChange={handleChange}
            value={value}
            disabled={disabled}
            aria-describedby={`${fieldName}Help`}
            placeholder={''}
            spellCheck={spellCheck}
            onFocus={onFocus}
            style={{ ...inputStyle, width: 'auto', height: 'auto' }}
          />
          {suffix && (
            <div className="input-group-prepend h-100">
              <span className="input-group-text h-100" id="basic-addon1" style={suffixStyle}>
                {suffixLabel ?? suffix}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
