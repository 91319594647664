/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { compose } from 'redux';
import Firebase, { withFirebase } from 'src/services/Firebase';

type Props = {
  firebase: Firebase,
  value: boolean,
  idCheck: string,
  valueTitle: string,
  labelDisabled: string,
  labelEnabled: string,
  setValue: (value: boolean) => void,
  setFirebaseValue: (firebase: Firebase, newStatus: boolean) => boolean,
  setIsLoading: (isLoading: boolean) => void,
};

const AppConfigTab = ({
  firebase,
  value,
  idCheck,
  valueTitle,
  labelDisabled,
  labelEnabled,
  setValue,
  setFirebaseValue,
  setIsLoading,
}: Props) => {
  // Change the status according to what we check
  const handleChange = async (event) => {
    const { id } = event.target;
    const newStatus = id === `${idCheck}Enabled`;
    setIsLoading(true);
    const status = await setFirebaseValue(firebase, newStatus);
    if (status) {
      setValue(newStatus);
    }
    setIsLoading(false);
  };

  // Displays the right colors for the text, highlighting the checked option
  const textClassName = (access: boolean) => {
    if (access) {
      return value ? 'font-weight-bold text-success' : 'text-muted';
    }
    return value ? 'text-muted' : 'font-weight-bold text-danger';
  };

  return (
    <div className="mb-3">
      <h5 className="ooredoo">{valueTitle}</h5>
      <div className="form-check pl-5 pr-5 pt-2 pb-2">
        <input
          className="form-check-input"
          type="radio"
          name={`${idCheck}Disabled`}
          id={`${idCheck}Disabled`}
          value={`${idCheck}Disabled`}
          checked={!value}
          onChange={handleChange}
        />
        <label className={`form-check-label ${textClassName(false)}`} htmlFor={`${idCheck}Disabled`}>
          {labelDisabled}
        </label>
      </div>
      <div className="form-check pl-5 pr-5 pt-2 pb-2">
        <input
          className="form-check-input"
          type="radio"
          name={`${idCheck}Enabled`}
          id={`${idCheck}Enabled`}
          value={`${idCheck}Enabled`}
          checked={value}
          onChange={handleChange}
        />
        <label className={`form-check-label ${textClassName(true)}`} htmlFor={`${idCheck}Enabled`}>
          {labelEnabled}
        </label>
      </div>
    </div>
  );
};

export default compose(withFirebase)(AppConfigTab);
