/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import * as ROUTES from 'src/constants/routes';
import { Claims, type ClaimType } from 'src/constants/roles';

import AuthUserContext from './context';
import Firebase, { withFirebase } from '../Firebase';

type AuthProps = {
  authUser: any,
  firebase: Firebase,
  history: any,
  notify: EventsServiceHelper.addNotifType,
  roomId: ?string,
};

type UserType = any;
type State = {
  validClaims: string[],
  userId?: UserType,
};

export const AuthenticatedCondition = (user: UserType) => !!user;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notify: EventsServiceHelper.addNotif,
};

const withAuthorization = (condition: any, claims?: ClaimType[], redirectIfNoClaims?: boolean = true) => (
  Component: React.AbstractComponent<any>,
) => {
  class WithAuthorization extends React.Component<AuthProps, State> {
    state = {
      validClaims: [],
      userId: undefined,
    };

    unsubscribe: (authUser: any) => void;

    componentDidMount() {
      this.unsubscribe = this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (condition && !condition(authUser)) {
          this.props.history.push(ROUTES.SIGN_IN);
        }
        if (authUser && claims && claims.length) {
          this.props.firebase
            .hasClaims(authUser, [...claims, Claims.Admin, Claims.ConfirmedEditor])
            .then((validClaims) => {
              if (
                !validClaims.length ||
                !(validClaims.includes(Claims.Admin) || validClaims.includes(Claims.ConfirmedEditor))
              ) {
                this.props.firebase.doSignOut();
                this.props.history.push(ROUTES.SIGN_IN);
                // if (redirectIfNoClaims) {
                //   this.props.history.push(ROUTES.SCENARIO_DASHBOARD);
                // }
                this.props.notify(NotificationTypes.WARN, 'W_INSUFICIENT_CLAIMS');
              }
              this.setState({ validClaims, userId: authUser.uid });
            });
        } else {
          this.setState({ validClaims: [], userId: authUser && authUser.uid });
        }
      });
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            !condition || condition(authUser) ? (
              <Component {...this.props} validClaims={this.state.validClaims} userId={this.state.userId} />
            ) : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(withRouter, withFirebase)(connect(mapStateToProps, mapDispatchToProps)(WithAuthorization));
};

export default withAuthorization;
