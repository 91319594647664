/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AmsServiceHelper } from 'src/store/ams';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase } from 'src/services/Firebase';
import type { AMSReducerState } from 'src/store/ams';
import { Loader } from 'src/pages/components';

export type AmsExportScreenProps = {
  bulkGenerateRelease: AmsServiceHelper.bulkGenerateReleaseType,
  firebase: Firebase,
  reduxState: AMSReducerState,
  allAmsIds: string[],
  engineVersion: number,
  t: (key: string) => string,
};

type State = {
  isLoading: boolean,
  amsIds: string[],
};

class AmsExportScreen extends React.PureComponent<AmsExportScreenProps, State> {
  static defaultProps = {};

  reader = undefined;

  state = {
    isLoading: false,
    amsIds: [],
  };

  handleChange = (event) => {
    const { options } = event.target;
    const values = [];
    const fieldName = event.target.id;
    for (let i = 0, l = options.length; i < l; i += 1) {
      const option = options[i];
      if (option.selected) {
        values.push(option.value);
      }
    }
    this.setState({ [fieldName]: values });
    const newVal = { ...this.state };
    newVal[fieldName] = values;
  };

  renderSelectField = (fieldName, value, label, values, help = undefined, disabled = false, multiple = false) => (
    <div className="form-group" key={fieldName}>
      <label htmlFor={fieldName}>{label}</label>
      <select
        className="form-control"
        id={fieldName}
        onChange={this.handleChange}
        value={value}
        disabled={disabled}
        aria-describedby={`${fieldName}Help`}
        placeholder={''}
        multiple={multiple}
      >
        {multiple || <option value={''}>{''}</option>}
        {values &&
          values.map((element) => (
            <option key={element} value={element}>
              {element}
            </option>
          ))}
      </select>
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );

  bulkGenerateDryRelease = () => {
    this._bulkGenerateRelease(true);
  };

  bulkGenerateRelease = () => {
    this._bulkGenerateRelease(false);
  };

  _bulkGenerateRelease = (dryRun: boolean) => {
    const { reduxState, bulkGenerateRelease, firebase, engineVersion } = this.props;
    const { amsIds } = this.state;
    this.setState({ isLoading: true });
    const amsToUpload = [];
    amsIds.forEach((amsId) => {
      if (amsIds.includes(amsId)) {
        amsToUpload.push(reduxState[amsId]);
      }
    });
    if (bulkGenerateRelease && reduxState) {
      bulkGenerateRelease(amsToUpload, engineVersion, firebase, dryRun)
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  render() {
    const { isLoading, amsIds } = this.state;
    const { allAmsIds, t } = this.props;
    return (
      <div className="pageContainer">
        <div className="container-fluid fill component-controller" style={{ overflow: 'scroll' }}>
          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3>{t('screens.export.beta.sectionTitle')}</h3>
            </div>
            <div className="card-body">
              <small className="form-text text-muted  mb-3">{t('screens.export.beta.desc')}</small>
              {this.renderSelectField(
                'amsIds',
                amsIds,
                t('screens.export.beta.amsIdLabel'),
                allAmsIds,
                t('screens.export.beta.amsIdHelp'),
                false,
                true,
              )}
              <br />
              <button
                className="btn btn-outline-secondary mb-3"
                type="button"
                onClick={this.bulkGenerateRelease}
                disabled={!amsIds.length}
              >
                {t('general.export')}
              </button>
              <button
                className="btn btn-outline-secondary mb-3 ml-2"
                type="button"
                onClick={this.bulkGenerateDryRelease}
                disabled={!amsIds.length}
              >
                {t('general.dryRun')}
              </button>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allAmsIds: Object.keys(state.ams).filter((id) => id !== '_persist'),
  reduxState: state.ams,
  engineVersion: state.configuration.amsEngineVersion,
});

const mapDispatchToProps = {
  bulkGenerateRelease: AmsServiceHelper.bulkGenerateRelease,
};

export default withUrlProperties(
  compose(
    withFirebase,
    withAuthorization(AuthenticatedCondition, [Claims.Moderator]),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('default'),
  )(AmsExportScreen),
  { ams: 'ams' },
);
