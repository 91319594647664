/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as ROUTES from 'src/constants/routes';
import { withAuthorization } from 'src/services/Session';
import logo from 'src/assets/images/atl-studio.png';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Claims } from 'src/constants/roles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'font-awesome/css/font-awesome.min.css';

import SidebarButton from './SidebarButton';
import './style/style-default.css';

type Props = {
  scenarioId?: string,
  validClaims: string[],
  ams?: string,
  cities?: string,
  history: any,
  version: string,
  updateOnFirebase: () => Promise<void>,
  showSaveBtn: boolean,
  closeContent: (forceClose: boolean) => void,
  t: (key: string) => string,
};
type State = {
  location: string,
};
class Sidebar extends React.Component<Props, State> {
  unlisten = undefined;

  state = {
    location: window.location.pathname,
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.askRefresh);
  }

  askRefresh = (location) => {
    this.setState({ location: location.pathname });
    this.forceUpdate();
  };

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    const { scenarioId, ams, cities, closeContent, t } = this.props;

    return (
      <div id="left-part">
        <Link to={ROUTES.SCENARIO_DASHBOARD} id="logo">
          <img src={logo} />
          <span className="version-number hidden">{this.props.version}</span>
        </Link>
        {
          // Constants.editorName
        }
        <ul className="navbar-nav" id="home-nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle icon-only"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={['far', 'ellipsis-v']} />
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <SidebarButton
                id="settings"
                imgClass="settings-icon"
                icon={['far', 'cog']}
                title={t('sidebar.settings')}
                pathname={this.state.location}
                url={ROUTES.SETTINGS}
              />
              <SidebarButton
                id="releases"
                imgClass="releases-icon"
                icon={['far', 'store']}
                title={t('sidebar.myReleases')}
                pathname={this.state.location}
                url={ROUTES.RELEASES}
              />
              {!!this.props.validClaims.includes(Claims.Admin) && (
                <SidebarButton
                  id="admin"
                  imgClass="admin-icon"
                  icon={['far', 'boxes']}
                  title={t('sidebar.sections')}
                  pathname={this.state.location}
                  url={ROUTES.EDITOR_DASHBOARD}
                />
              )}
              {!!this.props.validClaims.includes(Claims.Admin) && (
                <SidebarButton
                  id="admin"
                  imgClass="admin-icon"
                  icon={['far', 'crown']}
                  title={t('sidebar.admin')}
                  pathname={this.state.location}
                  url={ROUTES.ADMIN}
                />
              )}
              {!!this.props.validClaims.includes(Claims.Admin) && (
                <SidebarButton
                  id="review"
                  imgClass="review-icon"
                  icon={['far', 'stamp']}
                  title={t('sidebar.review')}
                  pathname={this.state.location}
                  url={ROUTES.REVIEW}
                />
              )}
              {/* {(!!this.props.validClaims.includes(Claims.Admin) ||
                !!this.props.validClaims.includes(Claims.Editor)) && (
                <SidebarButton
                  id="stats"
                  imgClass="stats-icon"
                  icon={['far', 'chart-line']}
                  title={t('sidebar.stats')}
                  pathname={this.state.location}
                  url={ROUTES.STATS}
                />
              )} */}
            </ul>
          </li>

          <SidebarButton
            id="home"
            imgClass="home-icon"
            icon={['fad', 'home-lg']}
            title={t('sidebar.home')}
            mode="bar-button"
            pathname={this.state.location}
            url={ROUTES.SCENARIO_DASHBOARD}
          />
        </ul>

        <div className="d-flex flex-row h-100 w-50 justify-content-center align-items-center">
          <div className="col-1 h-50" style={{ borderRight: '1px solid black', opacity: 0.3 }}></div>
          <div className="col-1 h-50"></div>
        </div>

        {(true || window.location.pathname === ROUTES.SCENARIO_DASHBOARD) &&
          !scenarioId &&
          !!this.props.validClaims.includes(Claims.Editor) && (
            <ul className="navbar-nav" id="content-nav">
              <SidebarButton
                id="switch"
                imgClass="new-icon"
                icon={['fad', 'plus-circle']}
                title={t('sidebar.newScenario')}
                mode="bar-button"
                pathname={this.state.location}
                url={ROUTES.SCENARIO_NEW}
              />
            </ul>
          )}

        {scenarioId && (
          <ul className="navbar-nav" id="content-nav">
            <span className="sidebarSection hidden">{t('sidebar.scenario')}</span>
            {!!this.props.validClaims.includes(Claims.Editor) && (
              <SidebarButton
                id="editor"
                imgClass="editor-icon"
                title={t('sidebar.edition')}
                icon={['fad', 'pen']}
                mode="bar-button"
                pathname={this.state.location}
                url={ROUTES.SCENARIO_EDITION.replace(':scenarioId', scenarioId)}
                pattern={`${ROUTES.SCENARIO_EDITION.replace(':scenarioId', scenarioId).slice(0, -5)}/discussion/`}
              />
            )}
            {!!this.props.validClaims.includes(Claims.Editor) && (
              <SidebarButton
                id="scenario"
                imgClass="header-icon"
                title={t('sidebar.headerEdition')}
                icon={['fad', 'file-alt']}
                mode="bar-button"
                pathname={this.state.location}
                url={ROUTES.SCENARIO_HEADER.replace(':scenarioId', scenarioId)}
              />
            )}
            {!!this.props.validClaims.includes(Claims.Editor) && (
              <SidebarButton
                id="npcs"
                imgClass="npcs-icon"
                title={t('sidebar.NPCSEdition')}
                icon={['fad', 'user']}
                mode="bar-button"
                pathname={this.state.location}
                url={ROUTES.SCENARIO_NPCS.replace(':scenarioId', scenarioId)}
              />
            )}
            {(!!this.props.validClaims.includes(Claims.Translator) ||
              !!this.props.validClaims.includes(Claims.Editor)) && (
              <SidebarButton
                id="trads"
                imgClass="trads-icon"
                title={t('sidebar.translations')}
                icon={['fad', 'language']}
                mode="bar-button"
                pathname={this.state.location}
                url={ROUTES.SCENARIO_TRADS.replace(':scenarioId', scenarioId)}
              />
            )}
            {!!(
              this.props.validClaims.includes(Claims.Editor) || this.props.validClaims.includes(Claims.Moderator)
            ) && (
              <SidebarButton
                id="export"
                imgClass="files-icon"
                title={t('sidebar.exportModes')}
                icon={['fad', 'file-export']}
                mode="bar-button"
                pathname={this.state.location}
                url={ROUTES.SCENARIO_EXPORTS.replace(':scenarioId', scenarioId)}
              />
            )}

            {(!!scenarioId || !!ams || !!cities) && (
              <li className="nav-item" onClick={closeContent}>
                <a>
                  <div>
                    <FontAwesomeIcon icon={['fad', 'times']} />
                    {t('general.close')}
                  </div>
                </a>
              </li>
            )}
          </ul>
        )}

        {!!ams && !!this.props.validClaims.includes(Claims.Moderator) && (
          <ul className="navbar-nav" id="content-nav">
            <span className="sidebarSection hidden">{t('sidebar.ams')}</span>
            <SidebarButton
              id="editor"
              imgClass="editor-icon"
              title={t('sidebar.edition')}
              icon={['fad', 'pen']}
              mode="bar-button"
              pathname={this.state.location}
              url={ROUTES.AMS_EDITION}
            />
            <SidebarButton
              id="trads"
              imgClass="trads-icon"
              title={t('sidebar.translations')}
              icon={['fad', 'language']}
              mode="bar-button"
              pathname={this.state.location}
              url={ROUTES.AMS_TRADS}
            />
            <SidebarButton
              id="export"
              imgClass="files-icon"
              title={t('sidebar.exportModes')}
              icon={['fad', 'file-export']}
              mode="bar-button"
              pathname={this.state.location}
              url={ROUTES.AMS_EXPORTS}
            />
          </ul>
        )}
        {!!cities && !!this.props.validClaims.includes(Claims.Moderator) && (
          <ul className="navbar-nav" id="content-nav">
            <span className="sidebarSection hidden">{t('sidebar.cities')}</span>
            <SidebarButton
              id="editor"
              imgClass="editor-icon"
              title={t('sidebar.edition')}
              icon={['fad', 'pen']}
              mode="bar-button"
              pathname={this.state.location}
              url={ROUTES.CITIES_EDITION}
            />
            <SidebarButton
              id="export"
              imgClass="files-icon"
              title={t('sidebar.exportModes')}
              icon={['fad', 'file-export']}
              mode="bar-button"
              pathname={this.state.location}
              url={ROUTES.CITIES_EXPORTS}
            />
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  scenarioId: state.preferences.urlProperties.scenarioId,
  ams: state.preferences.urlProperties.ams,
  cities: state.preferences.urlProperties.cities,
});

export default compose(
  withAuthorization(undefined, Object.values(Claims), false),
  connect(mapStateToProps),
  withTranslation('default'),
  withRouter,
)(Sidebar);
