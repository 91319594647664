/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import HelpButton from '../HelpButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

export interface InputSelectProps {
  className?: string;
  style?: any;
  fieldName: string;
  value: string;
  values: any[];
  label: string;
  help?: ?string;
  disabled?: boolean;
  hidden?: boolean;
  separatorBefore?: boolean;
  handleChange: (any) => any;
  helpInfos: any;
  labelSmall: boolean;
  itemToId?: (any) => string;
  itemToTitle?: (any) => string;
  onFocus?: () => any;
  disableEmptyOption?: boolean;
  textClassName?: string;
  inputStyle?: ?any;
}

export default class InputSelect extends React.Component<InputSelectProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    helpInfos: undefined,
    labelSmall: false,
    separatorBefore: false,
    disableEmptyOption: false,
    inputStyle: {},
  };

  _defaultItToAny = (it) => it;

  render() {
    const {
      className,
      style,
      fieldName,
      value,
      values,
      label,
      help,
      disabled,
      hidden,
      helpInfos,
      handleChange,
      separatorBefore,
      labelSmall,
      itemToId = this._defaultItToAny,
      itemToTitle = this._defaultItToAny,
      onFocus,
      disableEmptyOption,
      textClassName,
      inputStyle,
    } = this.props;
    return (
      <div className={`form-group ${className || ''}`} key={fieldName} hidden={hidden} style={style}>
        {separatorBefore && <hr />}
        {(label || helpInfos) && (
          <label htmlFor={fieldName} className="strong text-capitalize ooredoo">
            {labelSmall ? <small>{label}</small> : label}
            {helpInfos &&
              helpInfos.map((helpInfo, index) => (
                <HelpButton
                  key={fieldName + index}
                  helpStrings={helpInfo.content}
                  id={fieldName}
                  title={helpInfo.title}
                  label={helpInfo.btnLabel}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning ml-2'}
                />
              ))}
          </label>
        )}
        <select
          className={`form-control custom-select ${textClassName || ''}`}
          id={fieldName}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          aria-describedby={`${fieldName}Help`}
          placeholder={''}
          onFocus={onFocus}
          style={inputStyle}
        >
          {!disableEmptyOption && <option value={''}>{''}</option>}
          {values &&
            values.map((element) => (
              <option key={itemToId(element)} value={itemToId(element)}>
                {itemToTitle(element)}
              </option>
            ))}
        </select>
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
