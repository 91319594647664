/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';

import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { FirebaseSingleton as firebase } from 'src/services/Firebase';
import { InputJSONArray, InputString, Loader } from 'src/pages/components';
import { i18n } from 'src/assets/locales';
import { availableLanguagesEditor } from 'src/assets/locales/languages';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import LanguagesDropdown from '../../settings/components/LanguagesDropdown';

type Props = {
  locale: string,
  notify: EventsServiceHelper.addNotifType,
  t: (key: string) => string,
};

type State = {
  emails?: string,
  emailsLine?: string,
  isLoading: boolean,
};

class UserCreateWidget extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    emails: '',
    emailsLine: '',
    isLoading: false,
    emailLanguage: i18n.language,
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    // $FlowFixMe: Boolean can only be set for boolean fields
    const newState: $Shape<State> = { [fieldName]: value };
    this.setState(newState);
  };

  createUsers = async (useLine: boolean = false) => {
    const { emails, emailsLine, emailLanguage } = this.state;
    let emailsToUse = useLine ? emailsLine : emails;
    if (emailsToUse.length) {
      emailsToUse = emailsToUse.replaceAll(`"`, '');
      emailsToUse = emailsToUse.replaceAll(' ', '');
      try {
        this.setState({ isLoading: true });
        const res = await firebase.createUsersAsync(emailsToUse, undefined, emailLanguage);

        if (res.success) {
          this.setState({
            [useLine ? 'emailsToUse' : 'emails']: undefined,
          });
          this.props.notify(NotificationTypes.SUCCESS, 'S_USERS_CREATED', res);
        } else if (res.failedUsers) {
          this.props.notify(NotificationTypes.ERROR, 'E_USERS_NOT_CREATED', res);
        }
        this.setState({ isLoading: false });
      } catch (error) {
        console.error('User does not exists', error);
        this.props.notify(NotificationTypes.ERROR, 'E_USERS_NOT_CREATED', error.message);
        this.setState({ isLoading: false });
      }
    }
  };

  render() {
    const { emails, emailsLine, isLoading, emailLanguage } = this.state;
    const { t } = this.props;
    return (
      <div className="card bg-light screenBlock">
        <div className="card-header">
          <h3>{t('screens.admin.users.sectionTitle')}</h3>
        </div>
        <div className="card-body p-2 pl-4">
          <div className="list-group pb-10">
            <div className="d-flex flex-row justify-content-center">
              <div className="col-8 mb-4">
                <InputJSONArray
                  withoutBrackets
                  fieldName="emails"
                  value={emails}
                  label={t('screens.admin.users.createEmailLabel')}
                  handleChange={this.handleChange}
                  prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
                />
              </div>
              <div className="col-4 form-group">
                <label className="strong ooredoo text-capitalize" htmlFor="welcomeEmailLanguagesDropdown">
                  {t('screens.admin.users.createEmailLanguage')}
                </label>
                <LanguagesDropdown
                  availableLanguages={availableLanguagesEditor}
                  currentLanguage={emailLanguage}
                  idDropdown="welcomeEmailLanguagesDropdown"
                  changeLanguage={(emailLanguage) => this.setState({ emailLanguage })}
                />
              </div>
            </div>
            <button
              className="btn btn-outline-secondary mb-3"
              type="button"
              id="button-addon2"
              onClick={() => this.createUsers(false)}
            >
              {t('general.create')}
            </button>
            <InputString
              className="mb-0 mt-4"
              fieldName="emailsLine"
              value={emailsLine}
              label={t('screens.admin.users.createEmailLabel')}
              help={t('screens.admin.users.createEmailHelp')}
              handleChange={this.handleChange}
            />
            <button
              className="btn btn-outline-secondary mb-3"
              type="button"
              id="button-addon2"
              onClick={() => this.createUsers(true)}
            >
              {t('general.create')}
            </button>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  notify: EventsServiceHelper.addNotif,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(UserCreateWidget);
