/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import DatePicker from 'react-datepicker';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

import 'react-datepicker/dist/react-datepicker.css';

export interface InputDateProps {
  style?: any;
  fieldName: string;
  value?: ?Date;
  label: string;
  help?: ?string;
  disabled: boolean;
  displayBr?: ?boolean;
  handleChange?: (any) => any;
  handleChangeTime?: (any) => any;
  hidden: boolean;
  separatorBefore?: boolean;
  onFocus?: () => any;
}

export default class InputDate extends React.Component<InputDateProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    separatorBefore: false,
  };

  handleChange = (date: Date) => {
    const { handleChange, handleChangeTime } = this.props;
    if (handleChange) {
      handleChange({ target: { id: this.props.fieldName, value: date } });
    }
    if (handleChangeTime) {
      handleChangeTime({ target: { id: this.props.fieldName, value: date.getTime() } });
    }
  };

  render() {
    const { fieldName, value, label, help, disabled, hidden, separatorBefore, style, onFocus, displayBr } = this.props;

    return (
      <div className="form-group" key={fieldName} hidden={hidden} style={style}>
        {separatorBefore && <hr />}
        <label htmlFor={fieldName} className="ooredoo text-capitalize">
          {label}
        </label>
        {displayBr && <br />}
        <DatePicker
          className="form-control"
          selected={value}
          onChange={this.handleChange}
          disabled={disabled}
          dateFormat="dd/MM/yyyy"
          onFocus={onFocus}
        />
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
