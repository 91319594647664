/**
 * @flow
 * @format
 */

import * as React from 'react';
import { InputString, InputBoolean } from 'src/pages/components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as OnboardingServiceHelper from 'src/store/scenario/onboarding/OnboardingServiceHelper';

type Props = {
  pitchScenario: string,
  pitchCheckbox: boolean,
  updateNamePitchMissionScenario: OnboardingServiceHelper.updateNamePitchMissionScenarioType,
  t: (key: string) => string,
};

const OnboardingPitch = ({ pitchScenario, pitchCheckbox, updateNamePitchMissionScenario, t }: Props) => {
  const handleChange = (event: Event) => {
    const { value } = event.target;
    const { id } = event.target;
    const fieldName = 'pitch';
    let newValueString = pitchScenario;
    let newValueCheckbox = pitchCheckbox;
    // Retrieve current values of the changed field
    // Update the correct value
    if (id.toLowerCase().includes('checkbox')) {
      newValueCheckbox = value;
      if (value) {
        newValueString = '';
      }
    } else {
      newValueString = value;
    }
    updateNamePitchMissionScenario(newValueString, newValueCheckbox, fieldName);
  };
  return (
    <div className="p-4">
      <p className="font-italic mb-4">{t('screens.newScenario.onboarding.positionScreen.allCanBeUpdated')}</p>
      <div className="d-flex flex-column">
        <p>{t('screens.newScenario.onboarding.pitchScreen.endOnboarding')}</p>
        <p>{t('screens.newScenario.onboarding.pitchScreen.explanationPitch')}</p>
        <InputString
          className="pt-4 pb-2"
          fieldName="pitch"
          value={pitchScenario}
          help={t('screens.headerEdition.identification.pitchHelp')}
          multiline={true}
          disabled={pitchCheckbox}
          handleChange={handleChange}
        />
        <div className="mt-2 mb-2 font-weight-bold">
          <InputBoolean
            fieldName="checkboxPitchScenario"
            value={pitchCheckbox}
            handleChange={handleChange}
            help={t('screens.newScenario.onboarding.pitchScreen.helpPitch')}
            label={t('screens.newScenario.onboarding.nameScreen.labelName')}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pitchScenario: state.scenario.onboarding.pitchScenario,
  pitchCheckbox: state.scenario.onboarding.pitchCheckbox,
});

const mapDispatchToProps = {
  updateNamePitchMissionScenario: OnboardingServiceHelper.updateNamePitchMissionScenario,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(OnboardingPitch);
