/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Loader } from 'src/pages/components';
import { TabButton } from './components';
import ToolsTab from './tools/ToolsTab';
import UsersTab from './users/UsersTab';
import ReleasesTab from './releases/ReleasesTab';
import CodesTab from './codes/CodesTab';
// import AmsTab from './ams/AmsTab';
import CeTab from './ce/CeTab';
import TeamsTab from './teams/TeamsTab';
import ApplicationsTab from './applications/ApplicationsTab';
import AppConfigTab from './appConfig/AppConfigTab';

export type AdminScreenProps = {
  editionLocale: string,
  t: (key: string) => string,
};

type State = {
  isLoading: boolean,
};

class AdminScreen extends React.PureComponent<AdminScreenProps, State> {
  static defaultProps = {};

  reader: ?FileReader = undefined;

  state = {
    isLoading: false,
  };

  render() {
    const { t } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="pageContainer">
        <div className="container-fluid component-controller align-content-stretch">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <TabButton name="users" text={t('screens.admin.users.link')} active />
            <TabButton name="tools" text={t('screens.admin.tools.link')} />
            <TabButton name="codes" text={t('screens.admin.codes.link')} />
            <TabButton name="ce" text={t('screens.admin.ce.link')} />
            <TabButton name="releaseInfo" text={t('screens.admin.releaseInfo.link')} />
            {/* <TabButton name="ams" text={t('screens.admin.ams.link')} /> */}
            <TabButton name="applications" text={t('screens.admin.applications.link')} />
            <TabButton name="teams" text={t('screens.admin.teams.link')} />
            <TabButton name="appConfig" text={t('screens.admin.appConfig.link')} />
          </ul>
          <div className="tab-content d-flex" id="myTabContent">
            <UsersTab />
            <ToolsTab />
            <CodesTab />
            <CeTab />
            {/* <AmsTab /> */}
            <ReleasesTab />
            <ApplicationsTab />
            <TeamsTab />
            <AppConfigTab />
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  editionLocale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(
  withAuthorization(AuthenticatedCondition, [Claims.Admin]),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(AdminScreen);
