/**
 * @flow
 * @format
 */
import * as React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import HelpButton from '../HelpButton';

type InputJSONArrayProps = {
  className: string,
  style?: any,
  fieldName: string,
  value?: ?string,
  label?: string,
  help?: ?string,
  separatorBefore?: boolean,
  disabled?: boolean,
  hidden?: boolean,
  handleChange?: (any) => any,
  helpInfos: any,
  labelSmall: boolean,
  suffix?: ?string,
  inlineTitle?: boolean,
  spellCheck?: boolean,
  className?: string,
  onFocus?: () => any,
  onClick?: () => any,
  withoutBrackets: boolean,
  isCoordinates: boolean,
  isNumber: boolean,
  t: (key: string) => string,
  inputStyle?: ?any,
  prependStyle?: ?any,
};

const InputJSONArray = ({
  className,
  style,
  fieldName,
  value,
  label,
  help,
  separatorBefore = false,
  disabled = false,
  hidden = false,
  handleChange,
  helpInfos = undefined,
  inlineTitle,
  spellCheck = true,
  onFocus,
  onClick,
  withoutBrackets = false,
  isCoordinates = false,
  isNumber = false,
  t,
  inputStyle = {},
  prependStyle = {},
}: InputJSONArrayProps) => {
  const [formattedValue, setFormattedValue] = React.useState<Array<string>>(['']);

  /**
   * Format the event for the parent to handle
   */
  const eventForParent = (id: string, value: string) => {
    return {
      target: {
        id,
        value,
      },
    };
  };

  const coordLabel = (index) => {
    switch (index) {
      case 0:
        return 'lat';
      case 1:
        return 'lng';
      case 2:
        return 'alt';
      default:
        return index + 1;
    }
  };

  /**
   * Converts the values to JSON format
   */
  const valueToJSON = (values: Array<string>) => {
    let valuesToChange = [...values];
    if (isCoordinates || isNumber) {
      valuesToChange = valuesToChange.map((val) => {
        const newVal = parseFloat(val);
        // Not a number, we ignore and return a string
        return Number.isNaN(newVal) ? val : newVal;
      });
    }
    let jsonValues = JSON.stringify(valuesToChange);
    if (withoutBrackets) {
      jsonValues = jsonValues.replace('[', '').replace(']', '');
    }
    return jsonValues;
  };

  const handleChangeFormat = (event, index) => {
    const { value } = event.target;
    const newFormattedValue = [...formattedValue];
    newFormattedValue[index] = value;
    setFormattedValue(newFormattedValue);
    handleChange(eventForParent(fieldName, valueToJSON(newFormattedValue)));
  };

  const deleteValue = (index) => {
    const newFormattedValue = [...formattedValue];
    if (newFormattedValue.length === 1) {
      newFormattedValue[0] = '';
    } else {
      newFormattedValue.splice(index, 1);
    }
    setFormattedValue(newFormattedValue);
    handleChange(eventForParent(fieldName, valueToJSON(newFormattedValue)));
  };

  const addNewValue = () => {
    const newFormattedValue = [...formattedValue];
    newFormattedValue.push('');
    setFormattedValue(newFormattedValue);
    handleChange(eventForParent(fieldName, valueToJSON(newFormattedValue)));
  };

  const formatValueToStates = (jsonString: string) => {
    try {
      const arrayedValue = JSON.parse(`${withoutBrackets ? '[' : ''}${jsonString}${withoutBrackets ? ']' : ''}`);
      const { length } = arrayedValue;
      if (length === 0) {
        arrayedValue.push('');
      }
      if (isCoordinates) {
        for (let i = length; i < 2; i += 1) {
          arrayedValue.push('');
        }
      }
      setFormattedValue(arrayedValue);
    } catch (err) {
      setFormattedValue(['']);
    }
  };

  React.useEffect(() => {
    formatValueToStates(value);
  }, [value]);

  const prefixStyle = { ...prependStyle, borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' };

  return (
    <div className={`w-100 ${className || ''}`} key={fieldName} hidden={hidden} style={style} onClick={onClick}>
      {separatorBefore && <hr />}
      {(label || helpInfos) && (
        <div className="form-group">
          <label
            className={inlineTitle ? 'input-group-prepend mr-2 ooredoo' : 'strong text-capitalize ooredoo'}
            htmlFor={fieldName}
          >
            {`${label} `}
            {helpInfos &&
              Array.isArray(helpInfos) &&
              helpInfos.map((helpInfo, index) => (
                <HelpButton
                  key={fieldName + index}
                  helpStrings={helpInfo.content}
                  id={fieldName}
                  title={helpInfo.title}
                  label={helpInfo.btnLabel}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning ml-2'}
                />
              ))}
          </label>
        </div>
      )}
      {formattedValue.map((valueFormatted, index) => (
        <div className="mb-2" key={`${fieldName}json${index}`}>
          <div className="input-group w-100 h-100">
            <div className="input-group-prepend" style={{ width: '3rem' }}>
              <span className="input-group-text w-100" id="basic-addon1" style={prefixStyle}>
                {isCoordinates ? coordLabel(index) : index + 1}
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              id={`${fieldName}json${index}`}
              onChange={(event) => handleChangeFormat(event, index)}
              value={valueFormatted}
              disabled={disabled}
              aria-describedby={`${fieldName}Help`}
              placeholder={''}
              spellCheck={spellCheck}
              onFocus={onFocus}
              style={inputStyle}
            />
            {!isCoordinates && (
              <div className="input-group-prepend border border-left-0">
                <button className="btn delete btn-sm mb-0" type="button" onClick={() => deleteValue(index)}>
                  {t('general.delete')}
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
      {!isCoordinates && (
        <button className="btn btn-outline-secondary btn-block" type="button" onClick={() => addNewValue()}>
          {t('general.add')}
        </button>
      )}
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );
};

export default compose(withTranslation('default'))(InputJSONArray);
